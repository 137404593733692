import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Home.vue";
import List from "./components/List.vue";
import Logout from "./components/Logout.vue";
import LoginVerified from "./components/LoginVerified.vue";
import AdminFormView from "./components/admin/FormView.vue";
import AdminOrganisations from "./components/admin/AdminOrganisations.vue";
import AdminUsers from "./components/admin/AdminUsers.vue";
import AdminPatients from "./components/admin/AdminPatients.vue";
import AdminGraphtest from "./components/admin/AdminGraphtest.vue";
import AdminPackages from "./components/admin/AdminPackages.vue";
import AdminUsagestats from "./components/admin/AdminUsagestats.vue";
import DevTemp from "./components/admin/DevTemp.vue";
import Form from "./components/forms/Form.vue";
import AdminForms from "./components/admin/AdminForms.vue";
import AdminExportFormScores from "./components/admin/AdminExportFormScores.vue";
import PatientMove from "./components/PatientMove.vue";

// const Home = () => import(/* webpackChunkName: "group-base" */ './components/Home.vue')
// const List = () => import(/* webpackChunkName: "group-base" */ './components/List.vue')
// const LoginVerified = () => import(/* webpackChunkName: "group-base" */ './components/LoginVerified.vue')
// const AdminFormView = () => import(/* webpackChunkName: "group-admin" */ './components/admin/FormView.vue')
// const AdminOrganisations = () => import(/* webpackChunkName: "group-admin" */ './components/admin/AdminOrganisations.vue')
// const AdminUsers = () => import(/* webpackChunkName: "group-admin" */ './components/admin/AdminUsers.vue')
// const AdminPatients = () => import(/* webpackChunkName: "group-admin" */ './components/admin/AdminPatients.vue')
// const AdminGraphtest = () => import(/* webpackChunkName: "group-admin" */ './components/admin/AdminGraphtest.vue')
// const AdminUsagestats  = () => import(/* webpackChunkName: "group-admin" */ './components/admin/AdminUsagestats.vue')
// const DevTemp = () => import(/* webpackChunkName: "group-dev" */ './components/admin/DevTemp.vue')
// import userStore from './store/user.js'

// const ifAuthenticated = (to, from, next) => {
//   if (userStore.getters.userAuthenticated === true) {
//     next()
//     return
//   }
//   next('/login')
// }

Vue.use(Router);
//add patient needs route to createPatient....
//add route to update patient with email, phone etc.
export default new Router({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: [
    { path: "*", redirect: "/" },
    {
      path: "/",
      name: "home",
      component: Home,
      props: { errorMsg: null },
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
    },
    {
      path: "/loginVerified",
      name: "loginVerified",
      component: LoginVerified,
    },
    {
      path: "/admin/form",
      name: "aminForm",
      component: AdminFormView,
      props: {
        code: "SELFCARE",
      },
      meta: { requireAuth: true },
    },
    {
      path: "/admin/organisations",
      name: "admin_organisations",
      component: AdminOrganisations,
      meta: { requireAuth: true },
    },
    {
      path: "/admin/users",
      name: "admin_users",
      component: AdminUsers,
      meta: { requireAuth: true },
    },
    {
      path: "/admin/patients",
      name: "admin_patients",
      component: AdminPatients,
      meta: { requireAuth: true },
    },
    {
      path: "/admin/usagestats",
      name: "admin_usagestats",
      component: AdminUsagestats,
      meta: { requireAuth: true },
    },
    {
      path: "/admin/graphtest",
      name: "admin_graphtest",
      component: AdminGraphtest,
      meta: { requireAuth: true },
    },
    {
      path: "/admin/packages",
      name: "admin_packages",
      component: AdminPackages,
      meta: { requireAuth: true },
    },
    {
      path: "/admin/forms",
      name: "admin_forms",
      component: AdminForms,
      meta: { requireAuth: true },
    },
    {
      path: "/admin/export-form-scores",
      name: "admin_export_form_scores",
      component: AdminExportFormScores
    },
    {
      path: "/form",
      name: "user_form",
      component: Form,
      meta: { requireAuth: true },
    },
    {
      path: "/list/referrals",
      name: "list_referrals",
      meta: { requireAuth: true },
      // beforeEnter: ifAuthenticated,
      component: List,
      props: {
        type: "referrals",
        dispatch: "loadReferralList",
        headers: [
          // { label: '', key: 'patientId' },
          { label: "", key: "patient", patient: true },
          // { label: 'days', key: 'own_referral_date', patient: true },
          { label: "Prio", key: "priority", patient: true },
          { label: "WHODAS 2.0", key: "whodas36" },
          { label: "MADRS", key: "madrs" },
          { label: "AUDIT", key: "audit" },
          { label: "DUDIT", key: "dudit" },
          { label: "DSM5", key: "dsm5" },
          { label: "LPFS-BF", key: "lpfsbf" },
          { label: "ASRS", key: "asrs" },
        ],
      },
    },
    {
      path: "/list/patients",
      name: "list_patients",
      meta: { requireAuth: true },
      // beforeEnter: ifAuthenticated,
      component: List,
      props: {
        type: "patients",
        dispatch: "loadOrganisationPatients",
        headers: [
          // { label: '', key: 'patientId' },
          { label: "", key: "patient", patient: true },
          // { label: 'days', key: 'own_referral_date', patient: true },
          { label: "WHODAS 2.0", key: "whodas36" },
          { label: "MADRS", key: "madrs" },
          { label: "AUDIT", key: "audit" },
          { label: "DUDIT", key: "dudit" },
          { label: "DSM5", key: "dsm5" },
          { label: "LPFS-BF", key: "lpfsbf" },
          { label: "ASRS", key: "asrs" },
          { label: "PID-5", key: "pid5" },
          { label: "RAADS-14", key: "raads14" },
          { label: "CORE-10", key: "core10" },
          { label: "CORE-OM", key: "coreom" },
          { label: "RAADS-80", key: "raads80" },
        ],
      },
    },
    {
      path: "/dev/faketoken",
      name: "dev_faketoken",
      component: DevTemp,
      meta: { requireAuth: true },
    },
    {
      path: "/patient/move",
      name: "patient_move",
      component: PatientMove,
      meta: { requireAuth: true }
    },
  ],
  scrollBehavior(to, from, savedPosition) {//eslint-disable-line
    return { x: 0, y: 0 };
  },
  beforeEach(to, from, next) {
    const authRequired = to.meta.requireAuth && to.meta.requireAuth == true;
    const loggedIn = localStorage.getItem("user-token");
  
    if (authRequired && !loggedIn) {
      return next("/login");
    }
  
    next();
  }
});
