<template>
  <!-- START INFO -->
  <div class="container mt-5 mx-auto">
    <form>
      <div class="text-2xl bg-red p-5 rounded font-bold text-white">
        Bedömning
      </div>
      <!-- Criteria A -->
      <div class="flex">
        <div class="flex">
          <div
            class="flex items-center justify-center bg-blue border p-5 rounded mt-2 mr-2 w-48"
          >
            <p class="font-bold">DSM-5 kriterium A</p>
          </div>
        </div>
        <div class="flex flex-col flex-1">
          <div class="flex">
            <div
              class="flex flex-col flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
            >
              <p class="font-bold">Barndomen</p>
              <p class="">
                Föreligger ett flertal (3 eller fler) för A och / eller HI?
              </p>
            </div>
            <div
              class="flex items-center justify-center flex-1 bg-white border p-5 rounded text-lg mt-2"
            >
              <p v-if="this.BarnSy == true">Ja</p>
              <p v-else>Nej</p>
            </div>
          </div>
          <div class="flex">
            <div
              class="flex flex-col flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
            >
              <p class="font-bold">Vuxen ålder</p>
              <p>Är antalet kritierier på ouppmärksamhet (A) > 5?</p>
            </div>
            <div
              class="flex items-center justify-center flex-1 bg-white border p-5 rounded text-lg mt-2"
            >
              <p v-if="this.VuxenOupp == true">Ja</p>
              <p v-else>Nej</p>
            </div>
          </div>
          <div class="flex">
            <div
              class="flex flex-col flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
            >
              <p class="font-bold">Vuxen ålder</p>
              <p>
                Är antalet kriterier på hyperaktivivet/implusivitet (H/I) > 5?
              </p>
            </div>
            <div
              class="flex items-center justify-center flex-1 bg-white border p-5 rounded text-lg mt-2"
            >
              <p v-if="this.VuxenHyper == true">Ja</p>
              <p v-else>Nej</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Criteria B -->
      <div class="flex">
        <div
          class="flex items-center justify-center bg-blue border p-5 rounded mt-2 mr-2 w-48"
        >
          <p class="font-bold">DSM-5 kriterium B</p>
        </div>
        <div
          class="flex flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
        >
          <p>
            Finns det tecken på ett livslångt mönster av symptom med debut före
            12 års ålder?
          </p>
        </div>
        <div
          class="flex items-center justify-center flex-1 bg-white border p-5 rounded text-lg mt-2"
        >
          <p v-if="this.LivsLangtSymp == true">Ja</p>
          <p v-else>Nej</p>
        </div>
      </div>
      <!-- Criteria C, D -->
      <div class="flex">
        <div
          class="flex items-center justify-center bg-blue border p-5 rounded mt-2 mr-2 w-48"
        >
          <p class="font-bold">DSM-5 kriterium C och D</p>
        </div>
        <div class="flex flex-1 flex-col">
          <div class="flex">
            <div
              class="flex flex-col flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
            >
              <p class="font-bold">Vuxen ålder</p>
              <p>
                Symptom och funktionsnedsättning föreligger inom minst två
                funktionsdomäner
              </p>
            </div>
            <div
              class="flex items-center justify-center flex-1 bg-white border p-5 rounded text-lg mt-2"
            >
              <p v-if="this.PsykStoV == true">Ja</p>
              <p v-else>Nej</p>
            </div>
          </div>
          <div class="flex">
            <div
              class="flex flex-1 flex-col items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
            >
              <p class="font-bold">Barndomen</p>
              <p>
                Symptom och funktionsnedsättning föreligger inom minst två
                funktionsdomäner
              </p>
            </div>
            <div
              class="flex items-center justify-center flex-1 bg-white border p-5 rounded text-lg mt-2"
            >
              <p v-if="this.PsykStoB == true">Ja</p>
              <p v-else>Nej</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Criteria E -->
      <div class="flex">
        <div
          class="flex items-center justify-center bg-blue border p-5 rounded mt-2 mr-2 w-48"
        >
          <p class="font-bold">DSM-5 kriterium E</p>
        </div>
        <div
          class="flex flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
        >
          <p>
            Symptomen kan inte förklaras (bättre) med någon annan föreliggande
            psykiatrisk störning
          </p>
        </div>
        <div
          class="flex flex-col flex-1 items-center justify-center bg-white border p-5 rounded text-lg mt-2"
        >
          <div>
            <input
              type="radio"
              class="form-checkbox ml-1"
              name="PsykSto"
              :value="false"
              id="Psyksto1"
              v-model="Svar.PsykAnnat"
              @click="Svar.PsykAnnatMessage = ''"
            />
            <label for="Psyksto1" class="ml-1">Nej /</label>
            <input
              type="radio"
              class="form-checkbox"
              style="margin-left: 0.3em"
              name="PsykSto"
              :value="true"
              id="Psyksto2"
              v-model="Svar.PsykAnnat"
            />
            <label for="Psyksto2" class="ml-1">Ja, genom</label>
          </div>
          <div v-show="Svar.PsykAnnat" class="mt-1">
            <textarea
              rows="2"
              cols="20"
              name="Svar.PsykAnnat"
              v-model="Svar.PsykAnnatMessage"
              class="rounded border w-full"
            >
            </textarea>
          </div>
        </div>
      </div>
      <!-- Criteria -->
      <div class="flex items-center justify-center bg-blue border rounded mt-2">
        <p class="font-bold p-5">0 = inget/lite stöd</p>
        <p class="font-bold p-5">1 = ett visst stöd</p>
        <p class="font-bold p-5">2 = ett tydligt stöd</p>
      </div>
      <div class="flex">
        <div
          class="flex items-center justify-center bg-blue border p-5 rounded mt-2 mr-2 w-48"
        ></div>
        <div class="flex flex-col flex-1">
          <div class="flex">
            <div
              class="flex flex-1 flex-col items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
            >
              <p class="font-bold">
                Stöds diagnosen av andra informationskällor?
              </p>
              <p>Förälder(föräldrar)/bror/syster/övrig, nämligen</p>
            </div>
            <div
              class="flex flex-1 flex-col items-center justify-center text-center bg-white border p-5 rounded mt-2"
            >
              <div class="p-2">
                <input
                  name="Svar.Stodfamiljannat"
                  v-model="Svar.Stodfamiljannat"
                  class="rounded ml-4 border"
                  placeholder="Vem?"
                />*
              </div>
              <div>
                <input
                  type="radio"
                  class="form-checkbox ml-4"
                  name="StodFamilj"
                  value="Ej aktuellt"
                  id="Stodfamilj1"
                  v-model="Svar.Stodfamilj"
                />
                <label for="Stodfamilj1" class="ml-1">Ej aktuellt</label>
                <input
                  type="radio"
                  class="form-checkbox ml-4"
                  name="StodFamilj"
                  value="Inget / lite stöd"
                  id="Stodfamilj2"
                  v-model="Svar.Stodfamilj"
                />
                <label for="Stodfamilj2" class="ml-1">0</label>
                <input
                  type="radio"
                  class="form-checkbox ml-4"
                  name="StodFamilj"
                  value="Ett visst stöd"
                  id="Stodfamilj3"
                  v-model="Svar.Stodfamilj"
                />
                <label for="Stodfamilj3" class="ml-1">1</label>
                <input
                  type="radio"
                  class="form-checkbox ml-4"
                  name="StodFamilj"
                  value="Ett tydligt stöd"
                  id="Stodfamilj4"
                  v-model="Svar.Stodfamilj"
                />
                <label for="Stodfamilj4" class="ml-1">2</label>
              </div>
            </div>
          </div>
          <div class="flex">
            <div
              class="flex flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
            >
              <p>Partner / god vän(inna) / övrig, nämligen</p>
            </div>
            <div
              class="flex flex-1 flex-col items-center justify-center text-center bg-white border p-5 rounded mt-2"
            >
              <div class="p-2">
                <input
                  type="text"
                  name="StodPartnerAnnat"
                  v-model="Svar.StodPartnerAnnat"
                  class="rounded ml-4 border"
                  placeholder="Vem?"
                />*
              </div>
              <div>
                <input
                  type="radio"
                  class="form-checkbox ml-4"
                  name="StodPartner"
                  value="Ej aktuellt"
                  id="Stodpartner1"
                  v-model="Svar.Stodpartner"
                />
                <label for="Stodpartner1" class="ml-1">Ej aktuellt</label>
                <input
                  type="radio"
                  class="form-checkbox ml-4"
                  name="StodPartner"
                  value="Inget / lite stöd"
                  id="Stodpartner2"
                  v-model="Svar.Stodpartner"
                />
                <label for="Stodpartner2" class="ml-1">0</label>
                <input
                  type="radio"
                  class="form-checkbox ml-4"
                  name="StodPartner"
                  value="Ett visst stöd"
                  id="Stodpartner3"
                  v-model="Svar.Stodpartner"
                />
                <label for="Stodpartner3" class="ml-1">1</label>
                <input
                  type="radio"
                  class="form-checkbox ml-4"
                  name="StodPartner"
                  value="Ett tydligt stöd"
                  id="Stodpartner4"
                  v-model="Svar.Stodpartner"
                />
                <label for="Stodpartner4" class="ml-1">2</label>
              </div>
            </div>
          </div>
          <div class="flex">
            <div
              class="flex flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
            >
              <p>Betyg / omdömen / skolrapporter</p>
            </div>
            <div
              class="flex flex-1 items-center justify-center text-center bg-white border p-5 rounded mt-2"
            >
              <input
                type="radio"
                class="form-checkbox ml-4"
                name="Betyg"
                value="Ej aktuellt"
                id="Betyg1"
                v-model="Svar.Betyg"
              />
              <label for="Betyg1" class="ml-1">Ej aktuellt</label>
              <input
                type="radio"
                class="form-checkbox ml-4"
                name="Betyg"
                value="Inget / lite stöd"
                id="Betyg2"
                v-model="Svar.Betyg"
              />
              <label for="Betyg2" class="ml-1">0</label>
              <input
                type="radio"
                class="form-checkbox ml-4"
                name="Betyg"
                value="Ett visst stöd"
                id="Betyg3"
                v-model="Svar.Betyg"
              />
              <label for="Betyg3" class="ml-1">1</label>
              <input
                type="radio"
                class="form-checkbox ml-4"
                name="Betyg"
                value="Ett tydligt stöd"
                id="Betyg4"
                v-model="Svar.Betyg"
              />
              <label for="Betyg4" class="ml-1">2</label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="bg-blue w-48 rounded mt-2 mr-2 p-5"></div>
        <div
          class="flex flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
        >
          <p>Diagnos ADHD**</p>
        </div>
        <div
          class="flex flex-1 items-center justify-center text-left bg-white border p-5 rounded mt-2"
        >
          <div class="flex flex-col mt-2">
            <div class="p-2 border-b">
              <div class="flex">
                <input
                  type="radio"
                  class="radio"
                  name="Diagnos"
                  id="DiagnosNej"
                  :value="false"
                  @click="checkManual"
                  v-model="Svar.Diagnos"
                />
                <label for="DiagnosNej" class="ml-2 font-bold">Nej</label>
              </div>
            </div>
            <div class="p-2 border-b">
              <div class="flex">
                <input
                  type="radio"
                  class="radio"
                  name="Diagnos"
                  id="DiagnosJ1"
                  :value="1"
                  @click="checkManual"
                  v-model="Svar.Diagnos"
                />
                <label for="DiagnosJ1" class="ml-2"
                  >314.01 -
                  <span class="font-bold"
                    >F900B ADHD med såväl ouppmärksamhet och
                    hyperaktivitet/impulsivitet</span
                  ></label
                >
              </div>
            </div>
            <div class="p-2 border-b">
              <div class="flex">
                <input
                  type="radio"
                  class="radio"
                  name="Diagnos"
                  id="DiagnosJ2"
                  :value="2"
                  @click="checkManual"
                  v-model="Svar.Diagnos"
                />
                <label for="DiagnosJ2" class="ml-2"
                  >314.00 -
                  <span class="font-bold"
                    >F900C ADD huvudsakligen med ouppmärksamhet</span
                  ></label
                >
              </div>
            </div>
            <div class="p-2 border-b">
              <div class="flex">
                <input
                  type="radio"
                  class="radio"
                  name="Diagnos"
                  id="DiagnosJ3"
                  :value="3"
                  @click="checkManual"
                  v-model="Svar.Diagnos"
                />
                <label for="DiagnosJ3" class="ml-2"
                  >314.01 -
                  <span class="font-bold"
                    >F900B ADHD huvudsakligen med
                    hyperaktivitet/impulsivitet</span
                  ></label
                >
              </div>
            </div>
            <div class="p-2 border-b">
              <div class="flex">
                <input
                  type="radio"
                  class="radio"
                  name="Diagnos"
                  id="DiagnosJ4"
                  :value="4"
                  @click="checkManual"
                  v-model="Svar.Diagnos"
                />
                <label for="DiagnosJ4" class="ml-2"
                  >314.01 -
                  <span class="font-bold"
                    >F908 Annan specifierad ADHD</span
                  ></label
                >
              </div>
            </div>
            <div class="p-2 border-b">
              <div class="flex">
                <input
                  type="radio"
                  class="radio"
                  name="Diagnos"
                  id="DiagnosJ5"
                  :value="5"
                  @click="checkManual"
                  v-model="Svar.Diagnos"
                />
                <label for="DiagnosJ5" class="ml-2"
                  >314.01 -
                  <span class="font-bold"
                    >F900X Annan Ospecifierad ADHD</span
                  ></label
                >
              </div>
            </div>
            <div class="p-2">
              <div class="flex">
                <input
                  type="radio"
                  class="radio"
                  name="Diagnos"
                  id="DiagnosJ6"
                  :value="6"
                  @click="checkManual"
                  v-model="Svar.Diagnos"
                />
                <label for="DiagnosJ6" class="ml-2 font-bold"
                  >Partiell remission</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex" v-show="Svar.Diagnos != false">
        <div class="bg-blue w-48 rounded mt-2 mr-2 p-5"></div>
        <div
          class="flex flex-1 items-center justify-center bg-blue-light border p-5 rounded mt-2 mr-2"
        >
          <p>Allvarlighetsgrad</p>
        </div>
        <div
          class="flex flex-1 justify-center bg-white border p-5 rounded mt-2"
        >
          <div class="flex flex-col">
            <div class="flex">
              <input
                type="radio"
                class="radio"
                name="Allvarlighetsgrad"
                id="Allvarlighetsgrad1"
                value="Lindrig"
                v-model="Svar.Allvarlighetsgrad"
              />
              <label for="Allvarlighetsgrad1" class="ml-2">Lindrig</label>
            </div>
            <div class="flex">
              <input
                type="radio"
                class="radio"
                name="Allvarlighetsgrad"
                id="Allvarlighetsgrad2"
                value="Medelsvår"
                v-model="Svar.Allvarlighetsgrad"
              />
              <label for="Allvarlighetsgrad2" class="ml-2">Medelsvår</label>
            </div>
            <div class="flex">
              <input
                type="radio"
                class="radio"
                name="Allvarlighetsgrad"
                id="Allvarlighetsgrad3"
                value="Svår"
                v-model="Svar.Allvarlighetsgrad"
              />
              <label for="Allvarlighetsgrad3" class="ml-2">Svår</label>
            </div>
          </div>
        </div>
      </div>
      <div id="info" class="text-left mt-2">
        <p class="pt-1">* Ange från vem informationen inhämtades.</p>
        <p class="pt-1">
          ** Om de fastställda kliniska formerna är olika under barndomen och i
          vuxen ålder, skall den aktuella kliniska formen i vuxen ålder vara
          avgörande för diagnosen.
        </p>
      </div>
    </form>
    <div class="my-5">
      <button
        class="p-5 rounded bg-green text-white font-bold w-full"
        @click="saveForm()"
      >
        Spara DIVA-5
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Svar: {
        PsykAnnatMessage: null,
        BarnSy: null,
        VuxenOupp: null,
        VuxenHyper: null,
        Diagnos: false,
        manuellDiagnos: false,
      },
      Annat: false,
    };
  },
  props: {
    patientId: Number,
    sBedomning: Object,
    id: Number,
  },
  watch: {
    Svar: {
      handler() {
        this.Diagnos();
        this.$store.dispatch("setBedomning", this.Svar);
      },
      deep: true,
    },
    BarnSy() {
      this.Svar.BarnSy = this.BarnSy;
    },
    VuxenOupp() {
      this.Svar.VuxenOupp = this.VuxenOupp;
    },
    VuxenHyper() {
      this.Svar.VuxenHyper = this.VuxenHyper;
    },
    LivsLangtSymp() {
      this.Svar.LivsLangtSymp = this.LivsLangtSymp;
    },
    PsykStoV() {
      this.Svar.PsykStoV = this.PsykStoV;
    },
    PsykStoB() {
      this.Svar.PsykStoB = this.PsykStoB;
    },
  },
  methods: {
    Diagnos() {
      let diagnos = false;

      if (
        this.BarnSy == true &&
        this.VuxenOupp == true &&
        this.VuxenHyper == true &&
        this.LivsLangtSymp == true &&
        this.PsykStoV == true &&
        this.PsykStoB == true &&
        this.Svar.PsykAnnat == false
      ) {
        diagnos = 1;
      }

      if (
        this.BarnSy == true &&
        this.VuxenOupp == true &&
        this.VuxenHyper == false &&
        this.LivsLangtSymp == true &&
        this.PsykStoV == true &&
        this.PsykStoB == true &&
        this.Svar.PsykAnnat == false
      ) {
        diagnos = 2;
      }

      if (
        this.BarnSy == true &&
        this.VuxenOupp == false &&
        this.VuxenHyper == true &&
        this.LivsLangtSymp == true &&
        this.PsykStoV == true &&
        this.PsykStoB == true &&
        this.Svar.PsykAnnat == false
      ) {
        diagnos = 3;
      }

      if (this.Svar.manuellDiagnos) {
        if (diagnos == this.Svar.Diagnos) {
          this.Svar.manuellDiagnos = false;
        }

        if (this.Svar.PsykAnnat == true) {
          diagnos = false;
        } else {
          diagnos = this.Svar.Diagnos;
        }
      }

      if (diagnos === false) {
        this.Svar.Allvarlighetsgrad = null;
      }
      this.Svar.Diagnos = diagnos;
    },
    saveForm() {
      let form = {
        id: this.id ? this.id : null,
        patient: this.patientId,
        code: "DIVA5",
        form: this.Form,
      };
      let errors = this.validate();
      if (errors.length == 0) {
        this.$store.dispatch("saveForm", form).then(() => {
          //eslint-disable-line
          this.$store
            .dispatch("setSelectedPatient", this.patientId)
            .then(() => {
              this.$router.push({ name: "list_patients" });
            });
        });
      } else {
        alert(errors.join("\r\n"));
      }
    },
    validate() {
      let errors = [];

      if (this.Form.Info.date == null || this.Form.Info.date == "") {
        errors.push("Ange datum");
      }

      if (this.Bedomning.Diagnos == null) {
        errors.push("Ange diagnos");
      }

      if (this.Bedomning.Stodfamilj == null) {
        errors.push("Ange stöd från förälder/bror/syster");
      }

      if (this.Bedomning.Stodpartner == null) {
        errors.push("Ange stöd partner/vän");
      }

      if (this.Bedomning.Betyg == null) {
        errors.push("Ange stöd betyg/omdömen/skolrapporter");
      }

      if (
        this.Bedomning.Diagnos !== false &&
        this.Bedomning.Allvarlighetsgrad == null
      ) {
        errors.push("Ange allvarlighetsgrad");
      }

      return errors;
    },
    checkManual(e) {
      if (this.Svar.Diagnos != e.target.value) {
        this.Svar.manuellDiagnos = true;
      }
    },
  },
  computed: {
    BarnSy() {
      let BarnSyValue = false;
      if (this.Aform.Summa.Child.Summa + this.HIForm.Summa.Child.Summa >= 3) {
        BarnSyValue = true;
      }

      if (this.Aform.Summa.Child.Summa >= 3) {
        BarnSyValue = true;
      }

      if (this.HIForm.Summa.Child.Summa >= 3) {
        BarnSyValue = true;
      }

      return BarnSyValue;
    },
    VuxenOupp() {
      let VuxenOuppValue = false;
      if (this.Aform.Summa.Adult.Summa > 5) {
        VuxenOuppValue = true;
      }
      return VuxenOuppValue;
    },
    VuxenHyper() {
      let VuxenHyperValue = false;
      if (this.HIForm.Summa.Adult.Summa > 5) {
        VuxenHyperValue = true;
      }
      return VuxenHyperValue;
    },
    LivsLangtSymp() {
      let LivsLangtSymp = false;
      if (
        this.BarnSy == true &&
        (this.VuxenOupp == true || this.VuxenHyper == true)
      ) {
        LivsLangtSymp = true;
      }
      return LivsLangtSymp;
    },
    PsykStoV() {
      return this.DForm.D2.Adult.Summa >= 2 ? true : false;
    },
    PsykStoB() {
      return this.DForm.D2.Child.Summa >= 2 ? true : false;
    },
    Form() {
      return this.$store.getters.getDiva5;
    },
    Aform() {
      return this.Form.AForm;
    },
    HIForm() {
      return this.Form.HIForm;
    },
    DForm() {
      return this.Form.DForm;
    },
    Bedomning() {
      return this.Form.Bedomning;
    },
  },
  created() {
    if (this.sBedomning) this.Svar = this.sBedomning;
    this.$store.dispatch("setBedomning", this.Svar);
  },
};
</script>
