<template>
  <div class="container mt-3 mx-auto">
    <form>
      <!-- D1 -->
      <div class="text-2xl bg-red p-5 rounded font-bold text-white">
        Del 3: Funktionsnedsättning till följd av symptomen (DSM-5 kriterierna
        B, C och D)
      </div>
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl p-5 bg-blue-darker text-white border rounded  mt-2 mr-2"
        >
          <h3 class="font-bold">Kriterie B</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light border p-5 rounded text-lg  mt-2"
        >
          <p>
            Har du alltid haft dessa symptom på ouppmärksamhet och / eller
            hyperaktivitet / impulsivitet?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white border p-5 rounded mt-2">
          <div class="lg:flex text-left">
            <div class="mt-2 lg:mt-0 lg:ml-6">
              <div class="block">
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="radio"
                        class="form-checkbox"
                        name="DForm.D1.1"
                        :value="true"
                        v-model="DForm.D1.Answer.Svar"
                        @click="DForm.D1.Answer.Message = null"
                      />
                      <span class="ml-2"
                        >Ja, ett flertal symptom före tolv års ålder</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="radio"
                        class="form-checkbox"
                        name="DForm.D1.1"
                        :value="false"
                        v-model="DForm.D1.Answer.Svar"
                      />
                      <span class="ml-2">Nej</span>
                    </label>
                  </div>
                  <div v-show="DForm.D1.Answer.Svar === false">
                    <span>Från vilken ålder uppträdde symptomen?</span>
                    <input
                      class="mt-2 flex items-center justify-center rounded border"
                      name="D1V.SymptomAlder"
                      type="number"
                      v-model="DForm.D1.Answer.Message"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- D2 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl p-5 bg-blue-darker text-white border rounded  mt-2 mr-2"
        >
          <h3 class="font-bold">Kriterie C</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light border p-5 rounded text-lg  mt-2"
        >
          <p>Inom vilka områden har du / hade du problem med dessa symptom?</p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white border p-5 rounded mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Arbete / Utbildning</h2>
                <h4>Exempel från vuxen ålder:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2U.1"
                        v-model="DForm.D2.Adult.Utbildning[1]"
                      />
                      <span class="ml-2"
                        >Har inte avslutat utbildning / praktik som krävs för
                        arbetet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2U.2"
                        v-model="DForm.D2.Adult.Utbildning[2]"
                      />
                      <span class="ml-2"
                        >Arbetar under din utbildningsnivå</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VU.3"
                        v-model="DForm.D2.Adult.Utbildning[3]"
                      />
                      <span class="ml-2"
                        >Tröttnar snabbt på en arbetsplats</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VU.4"
                        v-model="DForm.D2.Adult.Utbildning[4]"
                      />
                      <span class="ml-2">Har haft många kortvariga jobb</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VU.5"
                        v-model="DForm.D2.Adult.Utbildning[5]"
                      />
                      <span class="ml-2"
                        >Har svårt med administrativa uppgifter /
                        planering</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VU.6"
                        v-model="DForm.D2.Adult.Utbildning[6]"
                      />
                      <span class="ml-2">Får ingen befordran</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VU.7"
                        v-model="DForm.D2.Adult.Utbildning[7]"
                      />
                      <span class="ml-2"
                        >Presterar under din nivå på jobbet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VU.8"
                        v-model="DForm.D2.Adult.Utbildning[8]"
                      />
                      <span class="ml-2"
                        >Har lämnat arbeten p.g.a gräl eller avsked</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VU.9"
                        v-model="DForm.D2.Adult.Utbildning[9]"
                      />
                      <span class="ml-2"
                        >Sjukskriven / förtidspensionär p.g.a symptomen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VU.10"
                        v-model="DForm.D2.Adult.Utbildning[10]"
                      />
                      <span class="ml-2"
                        >Begränsad nedsättning tack vare kompensation genom hög
                        begåvningsnivå (IQ)</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VU.11"
                        v-model="DForm.D2.Adult.Utbildning[11]"
                      />
                      <span class="ml-2"
                        >Begränsad nedsättning tack vare kompensation genom
                        yttre struktur</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Adult.Utbildning.Annat"
                        @click="DForm.D2.Adult.Utbildning.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Adult.Utbildning.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2VU.Message"
                      v-model="DForm.D2.Adult.Utbildning.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2VURadio"
                        id="D2VURadio1"
                        :value="true"
                        v-model="DForm.D2.Adult.Utbildning.Symptom"
                      />
                      <label for="D2VURadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2VURadio"
                        id="D2VURadio2"
                        :value="false"
                        v-model="DForm.D2.Adult.Utbildning.Symptom"
                      />
                      <label for="D2VURadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white border p-5 rounded ml-2 mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Utbildning</h2>
                <h4>Exempel från barn och ungdomen:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.1"
                        v-model="DForm.D2.Child.Utbildning[1]"
                      />
                      <span class="ml-2"
                        >Lägre utbildningsnivå än förväntat utifrån
                        behåvningsnivå (IQ)</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.2"
                        v-model="DForm.D2.Child.Utbildning[2]"
                      />
                      <span class="ml-2"
                        >Gått om någon / några klasser p.g.a
                        koncentrationssvårigheter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.3"
                        v-model="DForm.D2.Child.Utbildning[3]"
                      />
                      <span class="ml-2"
                        >Oavslutad utbildning / avstängd från skolan</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.4"
                        v-model="DForm.D2.Child.Utbildning[4]"
                      />
                      <span class="ml-2"
                        >Behöver längre tid för att slutföra utbildning som
                        motsvarar din begåvningsnivå (IQ)</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.5"
                        v-model="DForm.D2.Child.Utbildning[5]"
                      />
                      <span class="ml-2"
                        >Har med stor ansträngning klarat en utbildning som
                        motsvarar din begåvningsnivå (IQ)</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.6"
                        v-model="DForm.D2.Child.Utbildning[6]"
                      />
                      <span class="ml-2">Svårt att göra läxor</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.7"
                        v-model="DForm.D2.Child.Utbildning[7]"
                      />
                      <span class="ml-2"
                        >Fått specialundervisning p.g.a symptomen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.8"
                        v-model="DForm.D2.Child.Utbildning[8]"
                      />
                      <span class="ml-2"
                        >Fått kommentarer från lärare om beteende eller
                        koncentration</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.9"
                        v-model="DForm.D2.Child.Utbildning[9]"
                      />
                      <span class="ml-2"
                        >Begränsad nedsättning tack vare kompensation genom hög
                        begåvningsnivå (IQ)</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BU.10"
                        v-model="DForm.D2.Child.Utbildning[10]"
                      />
                      <span class="ml-2"
                        >Begränsad nedsättning tack vare kompensation genom
                        yttre struktur</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Child.Utbildning.Annat"
                        @click="DForm.D2.Child.Utbildning.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Child.Utbildning.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2BU.Message"
                      v-model="DForm.D2.Child.Utbildning.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2BURadio"
                        id="D2BURadio1"
                        :value="true"
                        v-model="DForm.D2.Child.Utbildning.Symptom"
                      />
                      <label for="D2BURadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2BURadio"
                        id="D2BURadio2"
                        :value="false"
                        v-model="DForm.D2.Child.Utbildning.Symptom"
                      />
                      <label for="D2BURadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white border p-5 rounded mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Relationer och / eller familj</h2>
                <h4>Exempel från vuxen ålder:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2U.1"
                        v-model="DForm.D2.Adult.Familj[1]"
                      />
                      <span class="ml-2">Tröttnar snabbt på förhållanden</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2U.2"
                        v-model="DForm.D2.Adult.Familj[2]"
                      />
                      <span class="ml-2"
                        >Inleder / avbryter förhållanden implusivt</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VF.3"
                        v-model="DForm.D2.Adult.Familj[3]"
                      />
                      <span class="ml-2"
                        >Har ett ojämlikt förhållande p.g.a symptom</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VF.4"
                        v-model="DForm.D2.Adult.Familj[4]"
                      />
                      <span class="ml-2"
                        >Relationsproblem, många gräl för lite intimitet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VF.5"
                        v-model="DForm.D2.Adult.Familj[5]"
                      />
                      <span class="ml-2">Frånskild p.g.a symptomen</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VF.6"
                        v-model="DForm.D2.Adult.Familj[6]"
                      />
                      <span class="ml-2">Sexuella problem p.g.a symptomen</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VF.7"
                        v-model="DForm.D2.Adult.Familj[7]"
                      />
                      <span class="ml-2"
                        >Problem med uppfostra p.g.a symptomen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VF.8"
                        v-model="DForm.D2.Adult.Familj[8]"
                      />
                      <span class="ml-2"
                        >Problem med att sköta hemsysslor och / eller
                        pappersarbete</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VF.9"
                        v-model="DForm.D2.Adult.Familj[9]"
                      />
                      <span class="ml-2"
                        >Ekonomiska problem eller spelande</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VF.10"
                        v-model="DForm.D2.Adult.Familj[10]"
                      />
                      <span class="ml-2"
                        >Vågar inte inleda ett förhållande</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Adult.Familj.Annat"
                        @click="DForm.D2.Adult.Familj.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Adult.Familj.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2VF.Message"
                      v-model="DForm.D2.Adult.Familj.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2VFRadio"
                        id="D2VFRadio1"
                        :value="true"
                        v-model="DForm.D2.Adult.Familj.Symptom"
                      />
                      <label for="D2VFRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2VFRadio"
                        id="D2VFRadio2"
                        :value="false"
                        v-model="DForm.D2.Adult.Familj.Symptom"
                      />
                      <label for="D2VFRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white border p-5 rounded ml-2 mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Familjen</h2>
                <h4>Exempel från barn och ungdomen:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BF.1"
                        v-model="DForm.D2.Child.Familj[1]"
                      />
                      <span class="ml-2"
                        >Hamnar ofta i gräl med bröder eller systrar</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BF.2"
                        v-model="DForm.D2.Child.Familj[2]"
                      />
                      <span class="ml-2"
                        >Blir ofta bestraffad eller slagen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BF.3"
                        v-model="DForm.D2.Child.Familj[3]"
                      />
                      <span class="ml-2"
                        >Lite kontakt med familjen p.g.a konflikter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BF.4"
                        v-model="DForm.D2.Child.Familj[4]"
                      />
                      <span class="ml-2"
                        >Hjälp med struktur genom föräldrar under längre tid an
                        vad som brukar vara normalt</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Child.Familj.Annat"
                        @click="DForm.D2.Child.Familj.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Child.Familj.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2BF.Message"
                      v-model="DForm.D2.Child.Familj.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2BFRadio"
                        id="D2BFRadio1"
                        :value="true"
                        v-model="DForm.D2.Child.Familj.Symptom"
                      />
                      <label for="D2BFRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2BFRadio"
                        id="D2BFRadio2"
                        :value="false"
                        v-model="DForm.D2.Child.Familj.Symptom"
                      />
                      <label for="D2BFRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white border p-5 rounded mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Sociala kontakter</h2>
                <h4>Exempel från vuxen ålder:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSO.1"
                        v-model="DForm.D2.Adult.Social[1]"
                      />
                      <span class="ml-2"
                        >Tröttnar snabbt på sociala kontakter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSO.2"
                        v-model="DForm.D2.Adult.Social[2]"
                      />
                      <span class="ml-2"
                        >Har svårt att upprätthålla sociala kontakter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSO.3"
                        v-model="DForm.D2.Adult.Social[3]"
                      />
                      <span class="ml-2"
                        >Konflikter p.g.a kommunikationssvårigheter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSO.4"
                        v-model="DForm.D2.Adult.Social[4]"
                      />
                      <span class="ml-2"
                        >Svårt att skapa sociala kontakter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSO.5"
                        v-model="DForm.D2.Adult.Social[5]"
                      />
                      <span class="ml-2"
                        >Bristande självkänsla p.g.a negativa erfarenheter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSO.6"
                        v-model="DForm.D2.Adult.Social[6]"
                      />
                      <span class="ml-2"
                        >Uppmärksammar inte andra (glömmer att skicka kort /
                        engagera dig / ringa etc)</span
                      >
                    </label>
                  </div>

                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Adult.Social.Annat"
                        @click="DForm.D2.Adult.Social.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Adult.Social.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2VSO.Message"
                      v-model="DForm.D2.Adult.Social.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2VSORadio"
                        id="D2VSORadio1"
                        :value="true"
                        v-model="DForm.D2.Adult.Social.Symptom"
                      />
                      <label for="D2VSORadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2VSORadio"
                        id="D2VSORadio2"
                        :value="false"
                        v-model="DForm.D2.Adult.Social.Symptom"
                      />
                      <label for="D2VSORadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white border p-5 rounded ml-2 mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Sociala kontakter</h2>
                <h4>Exempel från barn och ungdomen:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSO.1"
                        v-model="DForm.D2.Child.Social[1]"
                      />
                      <span class="ml-2"
                        >Svårt att upprätthålla sociala kontakter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSO.2"
                        v-model="DForm.D2.Child.Social[2]"
                      />
                      <span class="ml-2"
                        >Konflikter p.g.a kommunikationssvårigheter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSO.3"
                        v-model="DForm.D2.Child.Social[3]"
                      />
                      <span class="ml-2"
                        >Svårt att skapa sociala kontakter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSO.4"
                        v-model="DForm.D2.Child.Social[4]"
                      />
                      <span class="ml-2"
                        >Bristande självkänsla p.g.a negativa erfarenheter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSO.5"
                        v-model="DForm.D2.Child.Social[5]"
                      />
                      <span class="ml-2">Få kompisar</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSO.6"
                        v-model="DForm.D2.Child.Social[6]"
                      />
                      <span class="ml-2">Bli retad</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSO.7"
                        v-model="DForm.D2.Child.Social[7]"
                      />
                      <span class="ml-2"
                        >Utesluten ur, eller tillåts inte vara med i
                        gruppen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSO.8"
                        v-model="DForm.D2.Child.Social[8]"
                      />
                      <span class="ml-2">Mobbar andra</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Child.Social.Annat"
                        @click="DForm.D2.Child.Social.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Child.Social.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2BSO.Message"
                      v-model="DForm.D2.Child.Social.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2BSORadio"
                        id="D2BSORadio1"
                        :value="true"
                        v-model="DForm.D2.Child.Social.Symptom"
                      />
                      <label for="D2BSORadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2BSORadio"
                        id="D2BSORadio2"
                        :value="false"
                        v-model="DForm.D2.Child.Social.Symptom"
                      />
                      <label for="D2BSORadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white border p-5 rounded mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Fritid / fritidintressen</h2>
                <h4>Exempel från vuxen ålder:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.1"
                        v-model="DForm.D2.Adult.Fritid[1]"
                      />
                      <span class="ml-2"
                        >Kan inte koppla av ordentligt på fritiden</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.2"
                        v-model="DForm.D2.Adult.Fritid[2]"
                      />
                      <span class="ml-2"
                        >Måste idrotta väldigt mycket för att kunna koppla
                        av</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.3"
                        v-model="DForm.D2.Adult.Fritid[3]"
                      />
                      <span class="ml-2">Skador p.g.a mycket idrott</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.4"
                        v-model="DForm.D2.Adult.Fritid[4]"
                      />
                      <span class="ml-2"
                        >Klarar inte att läsa ut en bok eller se klart en
                        film</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.5"
                        v-model="DForm.D2.Adult.Fritid[5]"
                      />
                      <span class="ml-2"
                        >Håller på med något hela tiden vilket gör dig
                        utmattad</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.6"
                        v-model="DForm.D2.Adult.Fritid[6]"
                      />
                      <span class="ml-2"
                        >Tröttnar snabbt på fritidintressen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.7"
                        v-model="DForm.D2.Adult.Fritid[7]"
                      />
                      <span class="ml-2"
                        >Bilolyckor / blivit av med körkortet p.g.a vårdslös
                        körning</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.8"
                        v-model="DForm.D2.Adult.Fritid[8]"
                      />
                      <span class="ml-2"
                        >Söker "kickar" och / eller tar för många risker</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.9"
                        v-model="DForm.D2.Adult.Fritid[9]"
                      />
                      <span class="ml-2"
                        >Har kommit i kontakt med polisen / rättssystemet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VFR.10"
                        v-model="DForm.D2.Adult.Fritid[10]"
                      />
                      <span class="ml-2">Hetsäter</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Adult.Fritid.Annat"
                        @click="DForm.D2.Adult.Fritid.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Adult.Fritid.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2VFR.Message"
                      v-model="DForm.D2.Adult.Fritid.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2VFRRadio"
                        id="D2VFRRadio1"
                        :value="true"
                        v-model="DForm.D2.Adult.Fritid.Symptom"
                      />
                      <label for="D2VFRRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2VFRRadio"
                        id="D2VFRRadio2"
                        :value="false"
                        v-model="DForm.D2.Adult.Fritid.Symptom"
                      />
                      <label for="D2VFRRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white border p-5 rounded ml-2 mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Fritid / fritidintressen</h2>
                <h4>Exempel från barn och ungdomen:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BFR.1"
                        v-model="DForm.D2.Child.Fritid[1]"
                      />
                      <span class="ml-2"
                        >Kan inte koppla av ordentligt på fritiden</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BFR.2"
                        v-model="DForm.D2.Child.Fritid[2]"
                      />
                      <span class="ml-2"
                        >Måste idrotta mycket för att kunna koppla av</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BFR.3"
                        v-model="DForm.D2.Child.Fritid[3]"
                      />
                      <span class="ml-2">Skador p.g.a uttalat idrottande</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BFR.4"
                        v-model="DForm.D2.Child.Fritid[4]"
                      />
                      <span class="ml-2"
                        >Klarar inte att läsa ut en bok eller se klart en
                        film</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BFR.5"
                        v-model="DForm.D2.Child.Fritid[5]"
                      />
                      <span class="ml-2"
                        >Håller på med något hela tiden vilket gör dig
                        övertrött</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BFR.6"
                        v-model="DForm.D2.Child.Fritid[6]"
                      />
                      <span class="ml-2"
                        >Tröttnar snabbt på fritidintressen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BFR.7"
                        v-model="DForm.D2.Child.Fritid[7]"
                      />
                      <span class="ml-2"
                        >Söker "kickar" och / eller tar för många risker</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BFR.8"
                        v-model="DForm.D2.Child.Fritid[8]"
                      />
                      <span class="ml-2"
                        >Har kommit i kontakt med polisen / rättsystemet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BFR.9"
                        v-model="DForm.D2.Child.Fritid[9]"
                      />
                      <span class="ml-2"
                        >Råkat ut för ökat antal olycktillbud</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Child.Fritid.Annat"
                        @click="DForm.D2.Child.Fritid.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Child.Fritid.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2BFR.Message"
                      v-model="DForm.D2.Child.Fritid.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2BFRRadio"
                        id="D2BFRRadio1"
                        :value="true"
                        v-model="DForm.D2.Child.Fritid.Symptom"
                      />
                      <label for="D2BFRRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2BFRRadio"
                        id="D2BFRRadio2"
                        :value="false"
                        v-model="DForm.D2.Child.Fritid.Symptom"
                      />
                      <label for="D2BFRRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white border p-5 rounded mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Självförtroende / självbild</h2>
                <h4>Exempel från vuxen ålder:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSJ.1"
                        v-model="DForm.D2.Adult.Sjalvfortroende[1]"
                      />
                      <span class="ml-2"
                        >Osäkerhet p.g.a negativa kommentarer från andra</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSJ.2"
                        v-model="DForm.D2.Adult.Sjalvfortroende[2]"
                      />
                      <span class="ml-2"
                        >Negativ självbild p.g.a erfarenheter av
                        misslyckanden</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSJ.3"
                        v-model="DForm.D2.Adult.Sjalvfortroende[3]"
                      />
                      <span class="ml-2"
                        >Rädd för att misslyckas om du börjar med nya
                        saker</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSJ.4"
                        v-model="DForm.D2.Adult.Sjalvfortroende[4]"
                      />
                      <span class="ml-2"
                        >Reagerar överdrivet häftigt på kritik</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSJ.5"
                        v-model="DForm.D2.Adult.Sjalvfortroende[5]"
                      />
                      <span class="ml-2">Perfektionism</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2VSJ.6"
                        v-model="DForm.D2.Adult.Sjalvfortroende[6]"
                      />
                      <span class="ml-2"
                        >Känner sig obekväm p.g.a symptom på ADHD</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Adult.Sjalvfortroende.Annat"
                        @click="DForm.D2.Adult.Sjalvfortroende.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Adult.Sjalvfortroende.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2VSJ.Message"
                      v-model="DForm.D2.Adult.Sjalvfortroende.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2VSJRadio"
                        id="D2VSJRadio1"
                        :value="true"
                        v-model="DForm.D2.Adult.Sjalvfortroende.Symptom"
                      />
                      <label for="D2VSJRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2VSJRadio"
                        id="D2VSJRadio2"
                        :value="false"
                        v-model="DForm.D2.Adult.Sjalvfortroende.Symptom"
                      />
                      <label for="D2VSJRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white border p-5 rounded ml-2 mt-2">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Självförtroende / självbild</h2>
                <h4>Exempel från barn och ungdomen:</h4>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSJ.1"
                        v-model="DForm.D2.Child.Sjalvfortroende[1]"
                      />
                      <span class="ml-2"
                        >Osäkerhet p.g.a negativa kommentarer från andra</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSJ.2"
                        v-model="DForm.D2.Child.Sjalvfortroende[2]"
                      />
                      <span class="ml-2"
                        >Negativ självbild p.g.a erfarenheter av
                        misslyckanden</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSJ.3"
                        v-model="DForm.D2.Child.Sjalvfortroende[3]"
                      />
                      <span class="ml-2"
                        >Rädd för att misslyckas om du börjar med nya
                        saker</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSJ.4"
                        v-model="DForm.D2.Child.Sjalvfortroende[4]"
                      />
                      <span class="ml-2"
                        >Reagerar överdrivet häftigt på kritik</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="D2BSJ.5"
                        v-model="DForm.D2.Child.Sjalvfortroende[5]"
                      />
                      <span class="ml-2">Perfektionism</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="DForm.D2.Child.Sjalvfortroende.Annat"
                        @click="DForm.D2.Child.Sjalvfortroende.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="DForm.D2.Child.Sjalvfortroende.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="D2BSJ.Message"
                      v-model="DForm.D2.Child.Sjalvfortroende.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Funktionsnedsättning finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="D2BSJRadio"
                        id="D2BSJRadio1"
                        :value="true"
                        v-model="DForm.D2.Child.Sjalvfortroende.Symptom"
                      />
                      <label for="D2BSJRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="D2BSJRadio"
                        id="D2BSJRadio2"
                        :value="false"
                        v-model="DForm.D2.Child.Sjalvfortroende.Symptom"
                      />
                      <label for="D2BSJRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- D3 -->
      <div class="flex">
        <div
          class="flex flex-1 items-center justify-center bg-white border p-5 rounded mt-2 mr-2 "
        >
          <p class="font-bold">
            Vuxen ålder: Föreligger funktionsnedsättning ≥ 2 områden?
          </p>
        </div>
        <div
          class="flex-1 text-center bg-white border p-5 text-lg rounded  mt-2"
        >
          <p v-if="this.VFF == true">Ja</p>
          <p v-else>Nej</p>
        </div>
      </div>
      <div class="flex">
        <div
          class="flex flex-1 items-center justify-center bg-white border p-5 rounded mt-2 mr-2 "
        >
          <p class="font-bold">
            Barn och ungdom: Föreligger funktionsnedsättning ≥ 2 områden?
          </p>
        </div>
        <div
          class="flex-1 text-center bg-white border p-5 text-lg rounded  mt-2"
        >
          <p v-if="this.BFF == true">Ja</p>
          <p v-else>Nej</p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <textarea
            name="AText"
            id="AText"
            class="w-full h-full border"
            style="min-height: 200px;"
            v-model="HIText"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      DForm: {
        D1: {
          Answer: { SvarNej: false, Message: null },
        },
        D2: {
          Adult: {
            Utbildning: { Annat: false, Message: null },
            Familj: { Annat: false, Message: null },
            Social: { Annat: false, Message: null },
            Fritid: { Annat: false, Message: null },
            Sjalvfortroende: { Annat: false, Message: null },
            Summa: this.VFFSumma,
          },
          Child: {
            Utbildning: { Annat: false, Message: null },
            Familj: { Annat: false, Message: null },
            Social: { Annat: false, Message: null },
            Fritid: { Annat: false, Message: null },
            Sjalvfortroende: { Annat: false, Message: null },
            Summa: this.BFFSumma,
          },
        },
      },
    };
  },
  props: {
    sDForm: Object,
  },
  watch: {
    DForm: {
      handler() {
        this.$store.dispatch("setDForm", this.DForm);
        this.DForm.D2.Adult.Summa = this.VFFSumma;
        this.DForm.D2.Child.Summa = this.BFFSumma;
      },
      deep: true,
    },
  },
  computed: {
    VFFSumma() {
      let VFFSumma = 0;
      VFFSumma += this.DForm.D2.Adult.Utbildning.Symptom == true ? 1 : 0;
      VFFSumma += this.DForm.D2.Adult.Familj.Symptom == true ? 1 : 0;
      VFFSumma += this.DForm.D2.Adult.Social.Symptom == true ? 1 : 0;
      VFFSumma += this.DForm.D2.Adult.Fritid.Symptom == true ? 1 : 0;
      VFFSumma += this.DForm.D2.Adult.Sjalvfortroende.Symptom == true ? 1 : 0;
      return VFFSumma;
    },
    BFFSumma() {
      let BFFSumma = 0;
      BFFSumma += this.DForm.D2.Child.Utbildning.Symptom == true ? 1 : 0;
      BFFSumma += this.DForm.D2.Child.Familj.Symptom == true ? 1 : 0;
      BFFSumma += this.DForm.D2.Child.Social.Symptom == true ? 1 : 0;
      BFFSumma += this.DForm.D2.Child.Fritid.Symptom == true ? 1 : 0;
      BFFSumma += this.DForm.D2.Child.Sjalvfortroende.Symptom == true ? 1 : 0;
      return BFFSumma;
    },
    VFF() {
      let temp = false;
      if (this.VFFSumma >= 2) {
        temp = true;
      }
      return temp;
    },
    BFF() {
      let temp = false;
      if (this.BFFSumma >= 2) {
        temp = true;
      }
      return temp;
    },
    HIText() {
      let text = "";
      let cols = [
        "Utbildning",
        "Familj",
        "Social",
        "Fritid",
        "Sjalvfortroende",
      ];

      cols.forEach((col) => {
        if (this.DForm.D2.Adult[col].Annat || this.DForm.D2.Child[col].Annat)
          text += col + ":\r\n";
        if (this.DForm.D2.Adult[col].Annat === true)
          text += this.DForm.D2.Adult[col].Message + "\r\n";
        if (this.DForm.D2.Child[col].Annat === true)
          text += this.DForm.D2.Child[col].Message + "\r\n";
      });

      return text;
    },
  },
  created() {
    if (this.sDForm) this.DForm = this.sDForm;
    this.$store.dispatch("setDForm", this.DForm);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
