<template>
    <div class="flex flex-col">
        <div class="flex flex-col w-1/5 text-center">
            <span class="text-grey-dark text-lg">{{ form.score_type ? form.score_type : 'Totalt index' }}</span>
            <span class="text-4xl">{{ form.score }}</span>
        </div>
        <div class="w-full" v-if="form.code == 'PID5'">
            <p-i-d5-chart
                :id="`barchart_${namedScores[1].title}`"
                :title="'T-värden egenskaper'"
                :data="tscoreProp"
                propwidth="700"
                :max="140"
            />
            <p-i-d5-chart
                :id="`barchart_${namedScores[2].title}`"
                :title="'T-värden domäner'"
                :data="tscoreDom"
                propwidth="700"
                :max="140"
            />
        </div>
        <div class="flex pt-6">

            <div v-if="!['WHODAS12'].includes(form.code) &&  sectionScores" class="flex flex-col w-full text-center">
                <span class="text-lg text-grey-dark">Nedbrutet per område</span>
                <div v-for="(item, key) in sectionScores" :key="key" class="flex mt-4">
                    <div class="w-2/3 text-right text-lg text-grey-darker px-2"><span v-html="item.title"></span></div>
                    <div class="w-1/3 text-bold text-left text-lg px-2">{{ item.score }}</div>
                </div>
            </div>

            <div v-if="namedScores && namedScores.length" class="flex flex-col w-full text-center">
                <span v-if="!['PID5'].includes(form.code)" class="text-lg text-grey-dark">Nedbrutet per område</span>
                <div class="flex">
                    <div class="w-full">
                        <div v-if="!['PID5'].includes(form.code)" v-for="(item, key) in namedScores" :key="key" class="flex mt-4">
                            <div class="w-2/3 text-right text-lg text-grey-darker px-2"><span v-html="item.title"></span></div>
                            <div class="w-1/3 text-bold text-left text-lg px-2">{{ item.score }}</div>
                        </div>
                        <div class="flex flex-col w-full items-center" v-if="['PID5'].includes(form.code)">
                            <div class="flex flex-col w-1/2 py-2">
                                <span class="text-lg text-left text-grey-dark">{{ $t('form.namedscores.pid5.validityscales') }}:</span>
                                <div class="text-left text-lg text-grey-darker">Overreporting Scale <b>{{ namedScores[4].score }}</b> – {{ $t('form.namedscores.pid5.limit') }} = 3 </div>
                                <div class="text-left text-lg text-grey-darker">Report Inconsistency Scale <b>{{ namedScores[5].score }}</b> – {{ $t('form.namedscores.pid5.limit') }} = 18</div>
                                <div class="text-left text-sm text-grey-darker"><span v-if="validScale" v-html="$t('form.namedscores.pid5.validScale')"></span><span v-else v-html="$t('form.namedscores.pid5.invalidScale')"></span></div>
                            </div>
                            <div class="flex flex-col w-1/2 py-2">
                                <span class="text-lg text-left text-grey-dark">{{ $t('form.namedscores.pid5.screening') }}:</span>
                                <div class="text-left text-lg text-grey-darker">Borderline personlighetssyndrom <b>{{ namedScores[3].score }}</b> – {{ $t('form.namedscores.pid5.limit') }} = 11</div>
                                <div class="text-left text-sm text-grey-darker"><span v-if="validScreening" v-html="$t('form.namedscores.pid5.validScreening')"></span><span v-else v-html="$t('form.namedscores.pid5.invalidScreening')"></span></div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div v-if="numberOfMarkedQuestions" class="flex flex-col w-4/5 text-center">
                <div class="flex mt-4">
                    <div class="w-2/3 text-right text-lg text-grey-darker px-2">Antal markerade svar</div>
                    <div class="w-1/3 text-bold text-left text-lg px-2">{{ numberOfMarkedQuestions }}</div>
                </div>
            </div>

        </div>

<!-- Show PID-5 Scores
     <div class="w-2/3" v-else-if="typeof item.score == 'object'">
    <div class="flex justify-end p-1" v-for="(x, i) in item.score" :key="i">
        <div class="w-full" v-if="typeof x == 'object'">
            <div class="flex w-full p-1" v-for="(y, f) in x" :key="f">
                <div class="w-3/4 text-right text-lg text-grey-darker px-2">{{ $t("form.namedscores.pid5." + f ) }}</div>
                <div class="w-1/4 text-bold text-left text-lg px-2">{{ y }}</div>
            </div>
        </div>
        <div class="flex w-full text-right" v-else>
            <div class="w-3/4 text-right text-lg text-grey-darker px-2">{{ $t("form.namedscores.pid5." + i ) }}</div>
            <div class="w-1/4 text-bold text-left text-lg px-2">{{ x }}</div>
        </div>
    </div>
</div>
<div v-else class="w-1/3 text-bold text-left text-lg px-2">{{ item.score }}</div> 
-->

<!--
        <div>
            <div v-if="sectionScores && chart">

                <ResizableContainer>
                    <FormSectionsChart
                        slot-scope="{ width, height}"
                        :key="`_${form.response_id}`"
                        :id="`chart_${form.response_id}`"
                        :code="form.code"
                        :sections="sectionScores"
                        :width="width"
                        :height="200"
                        />
                </ResizableContainer>

                <ResizableContainer>
                    <SpiderChart
                        v-if="form.code == 'WHODAS36'"
                        slot-scope="{ width, height}"
                        :key="`_${form.response_id}`"
                        :id="`spiderchart_${form.response_id}`"
                        :code="form.code"
                        :sections="[sectionScores]"
                        :width="width"
                        :height="500"
                        />
                </ResizableContainer>
            </div>
        </div>
 -->
    </div>
</template>

<script>
import ResizableContainer from './ResizableContainer.vue'
import FormSectionsChart from './charts/FormSectionsChart.vue'
import SpiderChart from './charts/SpiderChart.vue'
import PID5Chart from './charts/PID5Chart.vue'

export default {
    name: 'FormScore',
    props: ['form'],
    components: {
        ResizableContainer,
        FormSectionsChart,
        SpiderChart,
        PID5Chart
    },
    data () {
        return {
            text: true,
            chart: true,
        }
    },
    computed: {
        sectionScores () {
            let sections = this.form.sections
            if (sections.length == 1) {
                return null
            }

            let scores = sections.map(s => {
                return { title: s.title, score: s.score }
            })

            return scores
        },
        namedScores () {
            if (!this.form.named_scores) {
                return null
            }
            let formCode = this.form.code.toLowerCase()
            let named = Object.entries(this.form.named_scores).map(d => {
                return {
                    title: this.$t(`form.namedscores.${formCode}.${d[0]}`),
                    score: d[1]
                }
            })

            return named
        },
        validScale() {
            if(this.form.code == "PID5"){
                if(this.namedScores[4].score >= 3 || this.namedScores[5].score >= 18){
                    return false;
                }else{
                    return true;
                }
            }
        },
        validScreening() {
            if(this.form.code == "PID5"){
                if(this.namedScores[3].score >= 11){
                    return false;
                }else{
                    return true;
                }
            }
        },
        numberOfMarkedQuestions () {
            return this.form.number_of_marked_questions || null
        },
        properties() {
            
            var property = [];

            if(this.form.code == "PID5"){
                for(const prop in this.namedScores[0].score){
                    property.push({
                        category: prop,
                        value: this.namedScores[0].score[prop]
                    });
                }
            }

            return property;
        },
        domains() {
            
            var domain = [];
            if(this.form.code == "PID5"){
                for(const dom in this.namedScores[1].score){
                    domain.push({
                        category: dom,
                        value: this.namedScores[1].score[dom]
                    });
                }
            }

            return domain;
        },
        tscoreProp() {
            
            var tscore = [];
            if(this.form.code == "PID5"){
                for(const t in this.namedScores[2].score.properties){
                    tscore.push({
                        category: t,
                        value: this.namedScores[2].score.properties[t]
                    });
                }
            }

            return tscore;

        },
        tscoreDom() {
            
            var tscore = [];
            if(this.form.code == "PID5"){
                for(const t in this.namedScores[2].score.domains){
                    tscore.push({
                        category: t,
                        value: this.namedScores[2].score.domains[t]
                    });
                }
            }

            return tscore;

        }
    }
}
</script>