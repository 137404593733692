<template>
  <div class="bg-white rounded rounded-lg">
    <div class="text-lg text-center py-2">{{ title }}</div>
    <div v-if="data" :id="`barchart-${id}`"></div>
  </div>
</template>
<style>
.tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
}

.tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center;
}
.tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}
.rect:hover {
  fill-opacity: 40%;
}
</style>
<script>
import * as d3 from "d3";
import d3Tip from "d3-tip";

export default {
  name: "PID5Chart",
  props: ["id", "data", "propwidth", "title", "max"],
  data() {
    return {};
  },
  watch: {
    propwidth: {
      immediate: false,
      handler: function (value) {
        if (value) {
          this.createChart();
        }
      },
    },
  },
  mounted() {
    this.createChart();
  },
  computed: {
    width() {
      return this.propwidth ? this.propwidth : this.$el.offsetWidth;
    },
    height() {
      return this.dataCount > 10 ? 600 : 400;
    },
    dataCount() {
      return this.data.length;
    },
    maxScore() {
      return this.max ? this.max : d3.max(this.data, (d) => d.value);
    },
  },
  methods: {
    createChart() {
      d3.tip = new d3Tip();
      let margin = {
        top: 10,
        right: 30,
        left: 50,
        bottom: 20,
      };

      if (this.dataCount > 10) {
        margin.bottom = 200;
      }

      d3.select(`#barchart-${this.id}`).select("svg").remove();

      let svg = d3
        .select(`#barchart-${this.id}`)
        .append("svg")
        .attr("class", "test")
        .attr("width", this.width)
        .attr("height", this.height);

      let x = d3
        .scaleBand()
        .domain(
          this.data.map((d) => this.$t("form.namedscores.pid5." + d.category))
        )
        .range([margin.left, this.width - margin.right])
        .padding(0.1);

      let y = d3
        .scaleLinear()
        .domain([0, this.maxScore])
        .nice()
        .range([this.height - margin.bottom, margin.top]);

      let xAxis = (g) =>
        g
          .attr("transform", `translate(0, ${this.height - margin.bottom})`)
          .call(d3.axisBottom(x).tickSizeOuter(0));

      let yAxis = (g) =>
        g
          .attr("transform", `translate(${margin.left}, 0)`)
          .call(d3.axisLeft(y))
          .call((g) => g.select(".domain").remove());

      svg
        .append("g")
        .call(xAxis)
        .selectAll("text")
        .style("text-anchor", this.dataCount > 10 ? "end" : "mid")
        .attr("dx", this.dataCount > 10 ? "-.8em" : "")
        .attr("dy", this.dataCount > 10 ? ".15em" : "1em")
        .attr("transform", () => {
          return this.dataCount > 10 ? "rotate(-60)" : "rotate(0)";
        })
        .attr("font-size", "1em")
        .attr("font-weight", "700");

      let tip = d3.tip
        .attr("class", "tip")
        .offset([-10, 0])
        .html((d) => {
          return (
            "<p>" +
            this.$t("form.namedscores.pid5." + d.category) +
            ": " +
            d.value +
            "</p>"
          );
        });

      svg.append("g").call(yAxis);

      let rect = svg
        .append("g")
        .selectAll("rect")
        .data(this.data)
        .enter()
        .append("rect");

      let rect2 = svg.append("g").append("rect");

      svg.call(tip);

      rect
        .attr("x", (d) => x(this.$t("form.namedscores.pid5." + d.category)))
        .attr("y", (d) => y(d.value))
        .attr("width", x.bandwidth())
        .attr("height", (d) => {
          return y(0) - y(d.value);
        })
        .attr("class", "rect")
        .attr("fill", (d) => {
          if (d.value <= 3) {
            return "#00bfff";
          } else if (d.value <= 35) {
            return "#fffa61";
          } else if (d.value >= 65) {
            return "#ff4545";
          } else {
            return "#53d94c";
          }
        })
        .on("mouseover", tip.show)
        .on("mouseout", tip.hide);

      rect2
        .attr("x", x(0))
        .attr("y", y(65))
        .attr("width", this.width)
        .attr("height", 2)
        .attr("fill", "yellow");
    },
  },
};
</script>

               <!-- .selectAll("text")
                    .style("text-anchor", "end")
                    .attr("dx", "-.8em")
                    .attr("dy", ".15em")
                    .attr("transform", (d) => {
                        return "rotate(-60)"
                    })
                    .attr("font-size", "1.2em")
                    .attr("font-weight", "700");

                attr('fill', (d) => {
                    if (d.value <= 3){
                        return "#abc123";
                    }else if (d.value <= 35){
                        return "#fffa61";
                    }else if (d.value >= 65){
                        return "#ff4545";
                    }else {
                        return "#53d94c";
                    }
                })

                -->