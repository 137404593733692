<template>
    <div>
        <Header :title="$t('routes.admin.usagestats')" />
        <!-- <div class="w-full flex mt-4 bg-white p-4">
            <table class="table">
                <thead  class="text-sm pb-2">
                    <tr>
                        <th>Organisation</th>
                        <th>Antal användare</th>
                        <th>Antal nya användare under föregående månad</th>
                        <th>Antal patienter som fått nybesökspaket under föregående månad</th>
                        <th>Antal patienter som vårdivare tittat på något formulär som ingår i nybesökspaketet</th>
                    </tr>
                </thead>
                <tbody v-if="monthlyStats !== null" class="mt-2">
                    <tr class="border-t">
                        <td>{{ monthlyStats['num_users'].length > 0 ? monthlyStats['num_users'][0]['name'] : 0 }}</td>
                        <td>{{ monthlyStats['num_users'].length > 0 ? monthlyStats['num_users'][0]['num'] : 0 }}</td>
                        <td>{{ monthlyStats['new_users'].length > 0 ? monthlyStats['new_users'][0]['num'] : 0 }}</td>
                        <td>{{ monthlyStats['package_newvisit_added'].length > 0 ? monthlyStats['package_newvisit_added'][0]['num'] : 0 }}</td>
                        <td>{{ monthlyStats['package_newvisit_viewed'].length > 0 ? monthlyStats['package_newvisit_viewed'][0]['num'] : 0 }}</td>
                    </tr>
                </tbody>
            </table>
        </div> -->

        <div class="w-full flex mt-4 bg-white p-4">
            <table class="table select-text">
                <thead  class="text-sm pb-2">
                    <tr>
                        <th>År-Månad</th>
                        <th>Enhet</th>
                        <th>Antal nya användare under månad</th>
                        <th>Antal användare som tilldelat nybesökspaket under månad</th>
                        <th>Antal användare som tittat på ett formulär som ingår i nybesökspaket under månad</th>
                    </tr>
                </thead>
                <tbody v-if="monthlyStatsRollingYear !== null" class="mt-2">
                    <template v-for="users, ym in monthlyStatsRollingYear['new_users']">
                    <tr class="border-t" :class="key === 0 ? 'border-blue-darker' : ''" v-for="unit, key in uniqueUnits">
                        <td class="text-left px-2 sm:whitespace-no-wrap">{{ key === 0 ? ym : '&#160;' }}</td>
                        <td class="text-left px-2 sm:whitespace-no-wrap">{{ unit }}</td>
                        <td class="text-center px-2">{{ monthlyStatsRollingYear['new_users'][ym] && monthlyStatsRollingYear['new_users'][ym][unit] ? monthlyStatsRollingYear['new_users'][ym][unit].num : '' }}</td>
                        <td class="text-center px-2">{{ monthlyStatsRollingYear['package_newvisit_added'][ym] && monthlyStatsRollingYear['package_newvisit_added'][ym][unit] ? monthlyStatsRollingYear['package_newvisit_added'][ym][unit].num : '' }}</td>
                        <td class="text-center px-2">{{ monthlyStatsRollingYear['package_newvisit_viewed'][ym] && monthlyStatsRollingYear['package_newvisit_viewed'][ym][unit] ? monthlyStatsRollingYear['package_newvisit_viewed'][ym][unit].num : '' }}</td>
                    </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div class="w-full flex flex-wrap">
            <div class="w-full md:w-1/2 lg:w-1/3 my-2" v-if="patientcounter">
                <ResizableContainer class="mx-1">
                    <bar-chart
                        id="patient-counts-total"
                        slot-scope="{ width }"
                        :data="patientcounter.totals"
                        :propwidth="width"
                        title="Patienter totalt"
                    >
                    </bar-chart>
                </ResizableContainer>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 my-2" v-if="patientcounter">
                <ResizableContainer class="mx-1">
                    <line-chart
                        id="patient-counts-monthly"
                        slot-scope="{ width }"
                        :data="patientcounter.monthly"
                        :propwidth="width"
                        title="Nya patienter, månadsvis"
                    >
                    </line-chart>
                </ResizableContainer>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 my-2" v-if="formcounter">
                <ResizableContainer class="mx-1">
                    <line-chart
                        id="form-counts-monthly"
                        slot-scope="{ width }"
                        :data="formcounter.monthly"
                        :propwidth="width"
                        title="Delade formulär, månadsvis"
                    >
                    </line-chart>
                </ResizableContainer>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 my-2" v-if="priocounter">
                <ResizableContainer class="mx-1">
                    <pie-chart
                        id="prio-counts-monthly"
                        slot-scope="{ width }"
                        :data="priocounter.totals"
                        :propwidth="width"
                        title="Patientprioriteringar"
                    >
                    </pie-chart>
                </ResizableContainer>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 my-2" v-if="auditlogcounter">
                <ResizableContainer class="mx-1">
                    <stats-table
                        id="auditlog-counts-total"
                        slot-scope="{ width }"
                        :data="auditlogcounter.totals"
                        :propwidth="width"
                        title="Antal händelser"
                    >
                    </stats-table>
                </ResizableContainer>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import ResizableContainer from '@/components/ResizableContainer.vue'
import BarChart from '@/components/charts/BarChart.vue'
import LineChart from '@/components/charts/LineChart.vue'
import PieChart from '@/components/charts/PieChart.vue'
import StatsTable from '@/components/charts/StatsTable.vue'

export default {
    name: 'AdminUsagestats',
    components: {
        Header,
        ResizableContainer,
        BarChart,
        LineChart,
        PieChart,
        StatsTable,
    },
    data() {
        return {
            monthlyStats: null,
            monthlyStatsRollingYear: null,
            patientcounter: null,
            formcounter: null,
            priocounter: null,
            auditlogcounter: null,
        }
    },
    mounted() {
        this.loadStats()
    },
    computed: {
        uniqueUnits() {
            if (this.monthlyStatsRollingYear === null)
                return [];

            const unitsAdded = Object.values(this.monthlyStatsRollingYear['package_newvisit_added'])
                .filter(d => d)
                .map((v) => Object.keys(v))
                .reduce((acc, cur) => {
                    acc.push(...cur.map(d => d))
                    return acc
                }, []);

            const unitsViewed = Object.values(this.monthlyStatsRollingYear['package_newvisit_viewed'])
                .filter(d => d)
                .map((v) => Object.keys(v))
                .reduce((acc, cur) => {
                    acc.push(...cur.map(d => d))
                    return acc
                }, []);

            const unique = [...new Set([...unitsAdded, ...unitsViewed])];

            return unique;
        }
    },
    methods: {
        unitAndNumFromObjects(o) {
            console.log(Object.entries(o));
            return o;
        },
        loadStats() {
            this.$store.dispatch('loadMonthlyStats')
                .then(() => {
                    this.monthlyStats = this.$store.getters.monthlyStats
                }).catch(() => {
                    console.log('error');
                })
            this.$store.dispatch('loadMonthlyStatsRollingYear')
                .then(() => {
                    this.monthlyStatsRollingYear = this.$store.getters.monthlyStatsRollingYear
                }).catch(() => {
                    console.log('error');
                })
            this.$store.dispatch('loadPatientCounter')
                .then(() => {
                    this.patientcounter = this.$store.getters.patientcounter
                })

            this.$store.dispatch('loadFormCounter')
                .then(() => {
                    this.formcounter = this.$store.getters.formcounter
                })

            this.$store.dispatch('loadPrioCounter')
                .then(() => {
                    this.priocounter = this.$store.getters.priocounter
                })

            this.$store.dispatch('loadAuditlogCounter')
                .then(() => {
                    this.auditlogcounter = this.$store.getters.auditlogcounter
                })
        }
    }
}
/*
[antal kallade] - antal aktiva patienter

[antal delade formulär] - antal sparade formulär / uppdelat per typ/namn

[antal prio 1] - antal patienter med prio1
[antal prio 2] - antal patienter med prio2
[antal prio 3] - antal patienter med prio3

[andel prio 1] - antal prio1 / antal patienter
[andel prio 2] - antal prio2 / antal patienter
[andel prio 3] - antal prio3 / antal patienter
 */
</script>