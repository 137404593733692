<template>
    <div>
        <Header title="Graftest" />
        <button type="button" @click="updateTime()">update1</button>
        <div id="test"></div>


<!--         <ResizableContainer>
            <MonthlyChart :data="stats.monthly.data" slot-scope="{ width, height }" :size="width" />
        </ResizableContainer>
 -->
    </div>
</template>

<script>
import Header from '../Header.vue'
import ResizableContainer from '../ResizableContainer.vue'
import MonthlyChart from '../charts/MonthlyChart.vue'
import * as d3 from 'd3'
import { formMetadata } from '../../utils/metadata.js'

export default {
    name: 'AdminGraphtest',
    props: [],
    components: {
        Header,
        ResizableContainer,
        MonthlyChart
    },
    watch: {
        time: {
            immediate: false,
            handler () {
                this.createChart()
            }
        }
    },
    computed: {
        chart () {
            return {
                margin: { top: 10, right: 10, bottom: 10, left: 30 },
                width: 800,
                height: 500,
            }
        }
    },
    data () {
        return {
            time: null,
            code: 'WHODAS36',
            stats: {
                weekly: {
                    title: this.$t('stats.overall.weekly'),
                    data: {
                        new: { title: this.$t('stats.weekly.referrals'), value: 18 },
                        updated: { title: this.$t('stats.weekly.updates'), value: 6 },
                    }
                },
                monthly: {
                    title: this.$t('stats.overall.monthly'),
                    data: {
                        numbers: [{
                            label: 'accepted',
                            value: 700,
                        }, {
                            label: 'backreferred',
                            value: 129,
                        }],
                        text: [
                            { label: 'stats.labels.monthly.days', value: 18 },
                            { label: 'stats.labels.monthly.recurring', value: '12%' },
                            { label: 'stats.labels.monthly.volunteers', value: '58%' },
                        ]
                    }
                },
                results: {
                    title: this.$t('stats.overall.results'),
                    data: [
                        {
                            label: 'whodas',
                            data: [
                                { month: '0', value: 21 },
                                { month: '6', value: 15 },
                                { month: '12', value: 10 },
                                { month: '18', value: 7 },
                                { month: '24', value: 4 },
                            ]
                        },
                        {
                            label: 'madrs',
                            data: [
                                { month: '0', value: 10 },
                                { month: '6', value: 9 },
                                { month: '12', value: 7 },
                                { month: '18', value: 6 },
                                { month: '24', value: 5 },
                            ]
                        },
                        {
                            label: 'audit',
                            data: [
                                { month: '0', value: 15 },
                                { month: '6', value: 13 },
                                { month: '12', value: 11 },
                                { month: '18', value: 9 },
                                { month: '24', value: 7 },
                            ]
                        },
                        {
                            label: 'dudit',
                            data: [
                                { month: '0', value: 8 },
                                { month: '6', value: 6 },
                                { month: '12', value: 5 },
                                { month: '18', value: 3 },
                                { month: '24', value: 2 },
                            ]
                        },
                    ]
                }
            }
        }
    },
    mounted () {
        this.createChart()
    },
    methods: {
        updateTime () {
            this.time = new Date()
        },
        createChart() {
            let data = this.getData()

            d3.select('#test').select('*').remove()

            let x = d3.scaleBand().range([0, +this.chart.width]).padding(.1)
                .domain(data.map(d => d.title ))

            let y = d3.scaleLinear().range([this.chart.height, 0])
                .domain([0, formMetadata[this.code].sectionChartMax])

            let color = d3.scaleOrdinal(d3.schemeSet2).domain(d3.map(this.selections, d => d.title))

            let svg = d3.select('#test')
                .append('svg')
                .attr('width', this.chart.width + this.chart.margin.left + this.chart.margin.right)
                .attr('height', this.chart.height + this.chart.margin.top + this.chart.margin.bottom)
                .append('g')
                .attr('transform', 'translate('+ this.chart.margin.left +', '+ this.chart.margin.top +')')

            svg.selectAll('.bar')
                .data(data)
                .enter().append("rect")
                    .attr("class", 'bar')
                    .attr("x", d => x(d.title))
                    .attr("width", x.bandwidth())
                    .attr("y", d => y(d.score))
                    .attr("height", d => (this.chart.height - y(d.score)))
                    .attr('fill', d => color(d.title) )

            svg.append("g")
                .attr("transform", "translate(0," + this.chart.height + ")")
                .call(d3.axisBottom(x))
                .selectAll("text")
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-55)")

            svg.append("g")
                .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('d')));

        },
        getData () {
            return [
                {
                    score: 58,
                    title: "Förstå och kommunicera",
                },
                {
                    score: 75,
                    title: "Förflyttning",
                },
                {
                    score: 75,
                    title: "Personlig vård",
                },
                {
                    score: 80,
                    title: "Relationer",
                },
                {
                    score: 75,
                    title: "Dagliga aktiviteter",
                },
                {
                    score: 72,
                    title: "Delaktighet i samhället",
                }
            ]
        }
    }
}
</script>