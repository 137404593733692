<template>
  <div>
    <div
      class="text-xl font-semibold"
      v-for="(project, index) in consents"
      :key="index"
    >
      {{ project.name }} - 
      {{ $t('research.signedWithBankId') }}:
      {{ formatDate(project.date) }}
      <table class="m-2">
        <tr class="text-base font-normal" v-for="(consent, name) in project.consents" :key="name">
            <td>{{ $t('research.' + name) }}</td>
            <td class="pl-12 text-center"><font-awesome-icon v-if="consent" class="text-green text-3xl" icon="check" /><font-awesome-icon v-else class="text-red text-3xl" icon="times" /></td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { localDate } from "../utils/dateformat";
export default {
  name: "PatientConsents",
  props: {
    consents: Array,
  },
  methods: {
    formatDate(date) {
      return date ? localDate(Date.parse(date)) : null;
    },
  },
};
</script>
