import { apiGetRequest } from "./api";

const activeMoveRequest = () => {
    return apiGetRequest('activemoverequests');
}

export default {
    state: {
        sidebarOpen: false,
        sidebarShown: false,
        activeMoveRequests: null
    },
    mutations: {
        setSidebarShown (state) {
            state.sidebarShown = true
        },
        closeSidebar (state) {
            state.sidebarOpen = false
            state.sidebarShown = false
        },
        toggleSidebar (state) {
            state.sidebarOpen = !state.sidebarOpen
        },
        setActiveMoveRequests (state, activeMoveRequests) {
            state.activeMoveRequests = activeMoveRequests.active;
        }
    },
    actions: {
        setSidebarShown ({ commit }) {
            commit('setSidebarShown')
        },
        closeSidebar ({ commit }) {
            commit('closeSidebar')
        },
        toggleSidebar ({ commit }) {
            commit('toggleSidebar')
        },
        async setActiveMoveRequests({ commit }) {
            const activeMoveRequests = await activeMoveRequest().then(data => data);
            commit('setActiveMoveRequests', activeMoveRequests)
        }
    },
    getters: {
        isSidebarOpen: state => state.sidebarOpen,
        isSidebarShown: state => state.sidebarShown,
        activeMoveRequests: state => state.activeMoveRequests
    }
}