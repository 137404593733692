<template>
  <div class="container bg-grey-lighter">
    <Header :title="title" />

    <div class="flex mt-4 text-left w-full">
      <div :class="containerWidth()" class="bg-grey-lighter">
        <PatientView
          v-if="$store.getters.selectedPatient !== null"
          :headers="headers"
          :selectedPatient="$store.getters.selectedPatient"
          @setPatientRejected="setPatientRejected"
        ></PatientView>

        <div v-if="revealList">
          <div
            v-if="$store.getters.selectedPatient === null"
            class="w-full bg-white px-2 py-2"
          >
            <div class="flex justify-between mb-2">
              <div>Visar {{ listCount }} av {{ listTotal }} patienter.</div>
              <div>
                <button
                  @click="reveal()"
                  type="button"
                  class="text-blue border border-blue hover:bg-blue hover:text-white rounded text-sm px-4 py-1"
                >
                  Göm patientlista
                </button>
              </div>
            </div>

            <input
              placeholder="Ange namn eller personnummer för att söka i listan"
              type="text"
              name="searchTerms"
              v-model="searchTerms"
              class="border border-grey rounded-lg px-2 py-2"
              :class="searchClass()"
            />
            <a>
              <font-awesome-icon
                icon="search"
                class="mx-2 text-blue align-middle"
              ></font-awesome-icon>
            </a>
            <a v-if="searchTerms !== ''" @click="emptySearchTerms()">
              <font-awesome-icon
                icon="trash-alt"
                class="mx-2 text-grey align-middle"
              ></font-awesome-icon>
            </a>
          </div>

          <div v-if="!patients" class="text-center mt-16 w-full">
            <font-awesome-icon
              icon="glasses"
              class="text-8xl mx-2 text-grey-dark align-middle"
            ></font-awesome-icon>
            <div class="text-grey-dark">Inga patienter matchade sökningen</div>
          </div>

          <transition name="component-fade" mode="out-in">
            <component
              v-if="$store.getters.selectedPatient === null"
              :is="tableType"
              :headers="headers"
              :searchTerms="searchTerms"
              @setSortColumn="setSortColumn"
              :checkedItems="checkedItems"
              @toggleRow="toggleRow"
            ></component>
          </transition>

          <div class="pagination-div" v-if="patients && $store.getters.selectedPatient === null">
            <paginate
              :page-count="pages"
              :page-range="3"
              :margin-pages="2"
              :click-handler="changePage"
              :prev-text="'<'"
              :next-text="'>'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :prev-class="'prev-page'"
              :next-class="'next-page'"
            ></paginate>
          </div>
        </div>

        <div v-if="!revealList && $store.getters.selectedPatient === null">
          <div class="w-full bg-white px-2 py-2">
            <div class="flex justify-between">
              <div class="flex-1 flex items-center">
                <BaseInputSsn
                  v-model.trim="ssn"
                  :validSsn.sync="validSsn"
                  @enter="searchBySsn"
                />
                <div>
                  <button
                    @click="searchBySsn"
                    :disabled="!validSsn"
                    type="button"
                    class="ml-1 px-2 py-1 text-sm rounded"
                    :class="{
                      'cursor-not-allowed bg-grey text-grey-darkest border-grey-darker': !validSsn,
                      'text-blue border border-blue hover:bg-blue hover:text-white': validSsn,
                    }"
                  >
                    Sök
                  </button>
                </div>
              </div>
              <div>
                <button
                  @click="reveal()"
                  type="button"
                  class="text-red border border-red hover:bg-red hover:text-white rounded text-sm px-4 py-1"
                >
                  Visa patientlista
                </button>
              </div>
            </div>
          </div>
          <div v-if="foundPatient" class="w-full bg-white mt-2 px-2 py-2">
            <table class="bg-white padded-table w-full border-collapse">
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.key"
                    class="text-base text-grey-darker uppercase break-words text-center"
                  >
                    <span class="ml-3 mr-1">{{
                      $t(`tableheaders.referral.${header.key}`)
                    }}</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  @click="setSelectedPatient($event, foundPatient.id)"
                  class="hover:cursor-pointer hover:bg-grey-lighter"
                >
                  <td class="py-2">
                    {{ foundPatient.first_name }} {{ foundPatient.last_name }}
                  </td>
                  <td class="text-center">{{ foundPatient.whodas36 }}</td>
                  <td class="text-center">{{ foundPatient.madrs }}</td>
                  <td class="text-center">{{ foundPatient.audit }}</td>
                  <td class="text-center">{{ foundPatient.dudit }}</td>
                  <td class="text-center">{{ foundPatient.dsm5 }}</td>
                  <td class="text-center">{{ foundPatient.lpfsbf }}</td>
                  <td class="text-center">{{ foundPatient.asrs }}</td>
                  <td class="text-center">{{ foundPatient.pid5 }}</td>
                  <td class="text-center">{{ foundPatient.raads14 }}</td>
                  <td class="text-center">{{ foundPatient.core10 }}</td>
                  <td class="text-center">{{ foundPatient.coreom }}</td>
                  <td class="text-center">{{ foundPatient.raads80 }}</td>
                  <td class="text-right">
                    <img src="/images/cheveron-right.png" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            v-if="
              ssnSearchedFinished &&
                !foundPatient &&
                $store.getters.selectedPatient === null
            "
            class="text-center mt-16 w-full"
          >
            <font-awesome-icon
              icon="glasses"
              class="text-8xl mx-2 text-blue align-middle"
            ></font-awesome-icon>
            <div class="text-grey-dark">
              Hittade ingen patient med angivet personnummer
            </div>
          </div>
        </div>
      </div>

      <!--
            <div v-if="$store.getters.selectedPatient === null" class="w-1/4 p-2 bg-grey-lighter">
                <ReferralsSideBar />
            </div>
 -->
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import ReferralsSideBar from "./ReferralsSideBar.vue";
import ReferralsTable from "./ReferralsTable.vue";
import PatientsTable from "./PatientsTable.vue";
import PatientView from "./PatientView.vue";

export default {
  name: "List",
  props: ["type", "headers", "dispatch"],
  components: {
    Header,
    ReferralsSideBar,
    ReferralsTable,
    PatientsTable,
    PatientView,
  },
  data() {
    return {
      checkedItems: [],
      searchTerms: "",
      sort: null,
      sortAsc: true,
      searching: false,
      revealList: false,
      ssn: null,
      validSsn: false,
      foundPatient: null,
      ssnSearchedFinished: false,
    };
  },
  computed: {
    title() {
      return this.type == "referrals"
        ? "Aktuella egenremisser"
        : "Våra patienter";
    },
    patients() {
      return this.$store.getters.currentList;
    },
    tableType() {
      return this.type == "referrals" ? "ReferralsTable" : "PatientsTable";
    },
    listCount() {
      return this.$store.getters.listCount;
    },
    listTotal() {
      return this.$store.getters.listTotal;
    },
    total() {
      return this.$store.getters.total;
    },
    pages() {
      return this.$store.getters.listPages || 1;
    },
    sortCol() {
      return this.$store.getters.sortcolumn;
    },
    sortOrder() {
      return this.$store.getters.sortorder;
    },
    page() {
      return this.$store.getters.listPage;
    },
    // paramString() {
    //   let string = new URLSearchParams({
    //     page: this.page,
    //     sort: this.sortCol,
    //     sortAsc: this.sortOrder,
    //   });

    //   return string.toString();
    // },
  },
  mounted() {
    // this.loadList()
  },
  watch: {
    type() {
      // this.loadList()
    },
  },
  methods: {
    reveal() {
      this.revealList = !this.revealList;
      if (this.revealList) {
        this.loadList();
        this.ssnSearchedFinished = false;
        this.foundPatient = null;
        this.ssn = null;
      }
    },
    loadList() {
      this.$store.dispatch("setListType", this.type);
      this.$store.dispatch(this.dispatch);
    },
    setSortColumn(key) {
      this.sortAsc = this.sort == key ? !this.sortAsc : this.sortAsc;
      this.sort = key;
    },
    setPatientRejected() {
      // this.$store.dispatch('setSelectedPatient', null).then(() => {
      //     this.data.patients = this.data.patients.filter(p => {
      //         return p.id !== patientId
      //     })
      // })
    },
    setPatientAccepted() {
      // this.$store.dispatch('setSelectedPatient', null).then(() => {
      //     this.data.patients = this.data.patients.filter(p => {
      //         return p.id !== patientId
      //     })
      // })
    },
    searchClass() {
      return this.searchTerms !== "" ? "w-full -mr-16" : "w-full -mr-8";
    },
    emptySearchTerms() {
      this.searchTerms = "";
    },
    containerWidth() {
      return "w-full";
    },
    toggleRow(id, checked) {
      if (checked) {
        if (this.checkedItems.indexOf(id) === -1) {
          this.checkedItems.push(id);
        }
      } else {
        if (this.checkedItems.indexOf(id) !== -1) {
          this.checkedItems.splice(this.checkedItems.indexOf(id), 1);
        }
      }
    },
    searchBySsn() {
      if (!this.validSsn) {
        alert("Ange ett komplett personnummer för att söka");
        return false;
      }
      this.$store
        .dispatch("searchBySsn", this.ssn)
        .then((response) => {
          let p = response;
          p.accepted = p.rejected_date === null ? true : false;
          if (p.recent_scores) {
            let dudit = p.recent_scores.find((r) => r.code == "DUDIT");
            let audit = p.recent_scores.find((r) => r.code == "AUDIT");
            let whodas36 = p.recent_scores.find((r) => r.code == "WHODAS36");
            let madrs = p.recent_scores.find((r) => r.code == "MADRS");
            let dsm5 = p.recent_scores.find((r) => r.code == "DSM5");
            let lpfsbf = p.recent_scores.find((r) => r.code == "LPFSBF");
            let asrs = p.recent_scores.find((r) => r.code == "ASRS");
            let pid5 = p.recent_scores.find((r) => r.code == "PID5");
            let raads14 = p.recent_scores.find((r) => r.code == "RAADS14");
            let core10 = p.recent_scores.find((r) => r.code == "CORE10");
            let coreom = p.recent_scores.find((r) => r.code == "COREOM");
            let raads80 = p.recent_scores.find((r) => r.code == "RAADS80");

            p.dudit = dudit ? dudit.score : null;
            p.audit = audit ? audit.score : null;
            p.whodas36 = whodas36 ? whodas36.score : null;
            p.madrs = madrs ? madrs.score : null;
            p.dsm5 = dsm5 ? dsm5.score : null;
            p.lpfsbf = lpfsbf ? lpfsbf.score : null;
            p.asrs = asrs ? asrs.score : null;
            p.pid5 = pid5 ? pid5.score : null;
            p.raads14 = raads14 ? raads14.score : null;
            p.core10 = core10 ? core10.score : null;
            p.coreom = coreom ? coreom.score : null;
            p.raads80 = raads80 ? raads80.score : null;
          }

          this.foundPatient = p;
        })
        .catch(() => {
          this.foundPatient = null;
        })
        .finally(() => {
          this.ssnSearchedFinished = true;
        });
    },
    acceptedIcon(item) {
      return item.accepted === true ? "check" : "retweet";
    },
    acceptedClass(item) {
      if (item.accepted === true) {
        return "flex items-center justify-around rounded-full w-8 h-8 bg-green text-white text-center font-bold align-middle";
      }

      return "flex items-center justify-center rounded-full border border-indigo w-8 h-8 text-grey-dark text-center font-bold align-middle";
    },
    prioClass(item) {
      if (item.priority === 1) {
        return "flex items-center justify-center rounded-full w-8 h-8 bg-red text-white text-center font-bold align-middle";
      }

      return "flex items-center justify-center rounded-full border border-indigo w-8 h-8 text-grey-dark text-center font-bold align-middle";
    },
    setSelectedPatient(evt, patientId) {
      this.$store.dispatch("setSelectedPatient", patientId);
    },
    changePage(pageNum) {
      this.$store.dispatch("changePage", pageNum).then(() => {
        this.$store.dispatch("loadOrganisationPatients");
      });
    },
  },
};
</script>

<style>
/* .component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.2s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
} */
.pagination-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border: 1px solid lightgray;
  background: white;
  padding: 0px;
  border-radius: 10px;
  overflow: hidden;
  height: 35px;
  min-width: 250px;
}
.page-item a,
.prev-page a,
.next-page a {
  background: white;
  padding: 10px;
  font-weight: bold;
}
.page-item a:hover,
.page-item.active > a,
.prev-page a:hover,
.next-page a:hover {
  background: #dbe1e7;
}
.page-item.disabled > a {
  background: rgb(240, 240, 240);
}
</style>
