<template>
    <div>
        <div v-if="$store.getters.userAuthenticated" class="bg-white flex justify-end py-1 px-6">
            <div>Inloggad som <span class="font-bold">{{ userFullName }} ({{ userOrganisation }}) </span></div>
        </div>

        <div class="bg-white flex justify-between py-4 px-6">
            <div class="pt-3">
                <SidebarToggle />
            </div>
            <div class="text-3xl font-bold pt-2">{{ title }}</div>
            <div><img src="/images/logo-rh-psyk-sm.png"></div>
        </div>
    </div>
</template>

<script>
import SidebarToggle from './base/SidebarToggle.vue'

export default {
    name: 'Header',
    props: ['title'],
    components: {
        SidebarToggle,
    },
    mounted () {
    },
    computed: {
        userFullName() {
            return `${this.$store.getters.userData.firstname} ${this.$store.getters.userData.lastname}`
        },
        userOrganisation() {
            return this.$store.getters.userData.organisationName
        }
    },
    methods: {

    }
}
</script>