<template>
  <div v-if="groups.length > 0">
    <div v-for="(group, gIndex) in groups" :key="gIndex" class="flex flex-col">
      <span class="font-bold text-2xl text-center">{{ group.title }}</span>
      <div class="overview-chart-container">
        <div v-for="(graph, index) in group.graphs" :key="index">
          <div class="text-lg text-center py-2 flex justify-center items-center">
            {{ graph.title }}
            <div v-if="graph.help" class="p-2">
              <font-awesome-icon @mouseover="showTip($event, graph.help)" @mouseleave="hideTip()" :icon="['fas', 'question-circle']" />
            </div>
          </div>
          <overview-graph :graph="graph" :group="gIndex" :index="index" />
        </div>
      </div>
    </div>
    <div ref="tip" class="tip n absolute border-box" style="display:none;">
      <p v-html="helpText"></p>
    </div>
  </div>
</template>

<style>
.overview-chart-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

@media screen and (max-width: 1250px) {
  .overview-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>

<script>
import OverviewGraph from "@/components/charts/OverviewGraph.vue";

export default {
  name: "PatientGraphOverview",
  components: {
    OverviewGraph,
  },
  mounted() {},
  props: ["groups"],
  watch: {},
  data() {
    return {
      helpText: ""
    };
  },
  computed: {},
  methods: {
    showTip(event, text) {
      this.helpText = text;
      let tip = this.$refs.tip;
      this.$nextTick().then(() => {
        tip.style.display = "block";
        tip.style.top = `${event.pageY - (tip.clientHeight + 20)}px`;
        tip.style.left = `${event.pageX - (tip.clientWidth / 2)}px`;
      })
    },
    hideTip() {
      this.helpText = "";
      let tip = this.$refs.tip;
      tip.style.display = "none";
    }
  },
};
</script>
