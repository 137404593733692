import { format, differenceInDays } from "date-fns";

const locales = require("./locales.js");
// const formats = require("./formats.js");

const locale = localStorage.getItem("user-locale") || "sv";

const localFormat = date => {
  return format(date, "yyyy-MM-dd HH:mm", {
    locale: locales[locale],
    awareOfUnicodeTokens: true
  });
};

const localDate = date => {
  return format(date, "yyyy-MM-dd", {
    locale: locales[locale],
    awareOfUnicodeTokens: true
  });
};

const daysSince = date => {
  return differenceInDays(new Date(), date);
};

export { localFormat, localDate, daysSince };
