<template>
  <!-- START INFO -->
  <div>
    <div class="mt-2 mx-auto p-2 bg-white rounded flex">
      <div class="flex items-center" @click="goBack()">
        <font-awesome-icon
          class="p-2 text-5xl cursor-pointer"
          icon="chevron-left"
        ></font-awesome-icon>
        <div class="p-2 cursor-pointer">Tillbaka</div>
      </div>
    </div>
    <div class="mt-2 mx-auto p-4 bg-white rounded">
      <input
        class="bg-gray-400 appearance-none border-2 border-gray-400 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
        id="patient-intervju-datum"
        type="date"
        v-model="info.date"
      />
    </div>
    <div class="mt-2 mx-auto p-4 bg-white rounded flex flex-col items-start">
      <div class="p-2">
        <input
          id="patient-intervju-checkbox-1"
          type="radio"
          value="1"
          v-model="info.typ"
        />
        <label for="patient-intervju-checkbox-1">Patientintervju</label>
      </div>
      <div class="p-2">
        <input
          id="patient-intervju-checkbox-2"
          type="radio"
          value="2"
          v-model="info.typ"
        />
        <label for="patient-intervju-checkbox-2">Anhörigintervju</label>
      </div>
      <div class="p-2">
        <input
          id="patient-intervju-checkbox-3"
          type="radio"
          value="3"
          v-model="info.typ"
        />
        <label for="patient-intervju-checkbox-3"
          >Patient- och anhörigintervju</label
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        date: null,
        typ: null,
      },
    };
  },
  props: {
    sInfo: Object,
    patientId: Number
  },
  watch: {
    info: {
      handler() {
        this.$store.dispatch("setDiva5Info", this.info);
      },
      deep: true,
    },
  },
  created() {
    if (this.sInfo) this.info = this.sInfo;
    this.$store.dispatch("setDiva5Info", this.sInfo);
  },
  methods: {
    goBack() {
      this.$store.dispatch("setSelectedPatient", this.patientId).then(() => {
        this.$router.push({ name: "list_patients" });
      });
    },
  },
};
</script>
