<template>
  <div>
    <div
      v-if="error"
      class="mb-2 mx-auto p-4 max-w-xs rounded-lg border border-red bg-red-lightest"
    >
      {{ error }}
    </div>
    <BaseButton @click.native="loginSiths">{{ $t("login.siths") }}</BaseButton>
    <div v-html="sithsdata"></div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      sithsdata: null,
      error: this.$route.params.errorMsg,
    };
  },
  methods: {
    loginSiths() {
      this.$store
        .dispatch("loginSiths")
        .then((data) => {
          if (data.redirectUrl && data.redirectUrl !== null) {
            window.location = data.redirectUrl;
          } else {
            this.error = this.$t("login.error.general");
          }
        })
        .catch(() => {
          this.error = this.$t("login.error.siths");
        });
    },
  },
};
</script>
