<template>
    <div>
        <div v-if="!sent" class="flex flex-col mx-4 my-4">
            <span class="text-grey-darkest font-semibold">Be om ytterligare information</span>

            <span class="text-grey-dark">Välj ett formulär att sända till patienten</span>
            <div class="border border-grey rounded rounded-lg">
                <div v-for="(form, key) in forms" class="h-12 px-2 py-2 border border-b border-grey-light flex justify-between items-center" @click="selectForm(form.code)" :key="key">
                    <span>{{ form.title }}</span>
                    <span v-show="selected == form.code"><img src="/images/checkmark.png" /></span>
                </div>
            </div>

            <textarea class="border border-grey-darkest rounded rounded-lg p-2 my-2" v-html="getFormText"></textarea>
            <textarea id="user_signature" class="border border-grey-darkest rounded rounded-lg p-2 my-2" v-html="userSignature"></textarea>

            <div class="text-center">
                <BaseButton class="mt-2" @click.native="sendComplementaryForms()">Skicka meddelande</BaseButton>
                <BaseButton class="mt-2" color="white">Återställ till standardtext</BaseButton>
                <BaseButton class="mt-2" color="red" @click.native="$emit('resetPatientActivity', 'complementaryforms')">Avbryt</BaseButton>
            </div>
        </div>

        <div v-else class="flex flex-col items-center">
            <div><img src="/images/check-done.png" class="w-32"></div>
            <div>Sänt till patient!</div>
        </div>

    </div>

</template>

<script>
export default {
    name: 'PatientComplementaryForms',
    data () {
        return {
            forms: [
                { code: 'adhd', title: 'adhd', text: 'Tack för dina svar. Du har möjliga symptom av ADHD. Jag vill be dig svara på ytterligare frågor specifikt kring det för att bättre förstå ditt tillstånd.' },
                { code: 'sleep', title: 'sleep', text: 'Tack för dina svar. Du har möjliga symptom av Sömn. Jag vill be dig svara på ytterligare frågor specifikt kring det för att bättre förstå ditt tillstånd.' },
                { code: 'anxiety', title: 'anxiety', text: 'Tack för dina svar. Du har möjliga symptom av Ångest. Jag vill be dig svara på ytterligare frågor specifikt kring det för att bättre förstå ditt tillstånd.' },
                { code: 'eatingdisorders', title: 'eatingdisorders', text: 'Tack för dina svar. Du har möjliga symptom av Ätstörningar. Jag vill be dig svara på ytterligare frågor specifikt kring det för att bättre förstå ditt tillstånd.' },
                { code: 'schitzofrenia', title: 'schitzofrenia', text: 'Tack för dina svar. Du har möjliga symptom av Schizofreni. Jag vill be dig svara på ytterligare frågor specifikt kring det för att bättre förstå ditt tillstånd.' },
            ],
            selected: null,
            sent: false
        }
    },
    mounted () {

    },
    computed: {
        userSignature () {
            return 'Med vänliga hälsningar, ' + this.$store.getters.userData.firstname + ' ' + this.$store.getters.userData.lastname + ' ' + 'titel'
        },
        getFormText () {
            if (this.selected === null) {
                return ''
            }

            let form = this.forms.find((f) => {
                return this.selected == f.code
            })

            return form.text
        }
    },
    methods: {
        selectForm (code) {
            this.selected = code
        },
        sendComplementaryForms () {
            let message = this.getFormText + ' ' + document.getElementById('user_signature').innerHTML

            this.$store.dispatch('sendComplementaryForms', { patient_id: this.$store.getters.selectedPatient, message: message })
                .then(() => {
                    this.sent = true
                })
                .catch(() => {

                })
        }
    }
}
</script>