<template>
  <div>
    <table
      v-if="patients && patients.length > 0"
      class="bg-white padded-table w-full border-collapse"
    >
      <thead>
        <tr>
          <th
            v-for="header in headers"
            :key="header.key"
            @click="sortPatients(header.key)"
            class="text-base text-grey-darker uppercase break-words text-center"
          >
            <span class="ml-3 mr-1">{{
              $t(`tableheaders.referral.${header.key}`)
            }}</span>
            <font-awesome-icon
              :icon="sortIcon"
              class="text-sm"
              :class="transparentIcon(header.key)"
            ></font-awesome-icon>
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody is="transition-group" name="slide-fade">
        <tr
          v-for="item in patients"
          :key="item.id"
          @click="setSelectedPatient($event, item.id)"
          class="hover:cursor-pointer hover:bg-grey-light"
          @mouseover="display = item.id"
          @mouseout="display = null"
        >
          <td class="border-b border-grey py-2 pl-2 relative">
            <span
              class="absolute pin-t pin-l mt-2 -ml-16 px-1 py-1 text-grey"
              :class="item.id == display ? 'inline-block' : 'hidden'"
              >Id {{ item.id }}</span
            >
            <span>{{ item.first_name }} {{ item.last_name }}</span>
          </td>
          <td class="border-b border-grey text-center">{{ item.whodas36 }}</td>
          <td class="border-b border-grey text-center">{{ item.madrs }}</td>
          <td class="border-b border-grey text-center">{{ item.audit }}</td>
          <td class="border-b border-grey text-center">{{ item.dudit }}</td>
          <td class="border-b border-grey text-center">{{ item.dsm5 }}</td>
          <td class="border-b border-grey text-center">{{ item.lpfsbf }}</td>
          <td class="border-b border-grey text-center">{{ item.asrs }}</td>
          <td class="border-b border-grey text-center">{{ item.pid5 }}</td>
          <td class="border-b border-grey text-center">{{ item.raads14 }}</td>
          <td class="border-b border-grey text-center">{{ item.core10 }}</td>
          <td class="border-b border-grey text-center">{{ item.coreom }}</td>
          <td class="border-b border-grey text-center">{{ item.raads80 }}</td>
          <td class="border-b border-grey text-right">
            <img src="/images/cheveron-right.png" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { daysSince, localFormat, localDate } from "../utils/dateformat.js";
import debounce from "lodash/debounce";

export default {
  name: "PatientsTable",
  props: ["headers", "searchTerms"],
  data() {
    return {
      display: null,
    };
  },
  watch: {
    searchTerms: {
      immediate: false,
      deep: true,
      handler: function() {
        this.search();
      },
    },
  },
  computed: {
    sortCol() {
      return this.$store.getters.sortcolumn;
    },
    sortOrder() {
      return this.$store.getters.sortorder;
    },
    sortIcon() {
      return this.sortOrder ? "chevron-up" : "chevron-down";
    },
    patients() {
      return this.$store.getters.patientList;
    },
  },
  methods: {
    transparentIcon(key) {
      return key == this.sortCol ? "text-indigo" : "text-transparent";
    },
    setSelectedPatient(evt, patientId) {
      if (evt.target.dataset.noclick === undefined) {
        this.$store.dispatch("setSelectedPatient", patientId);
      }
    },
    formatDateAndTime(value) {
      return localFormat(value);
    },
    formatDate(value) {
      return localDate(value);
    },
    numberOfDaysSinceReferral(date) {
      return daysSince(date);
    },
    acceptedIcon(item) {
      return item.accepted === true ? "check" : "retweet";
    },
    acceptedClass(item) {
      if (item.accepted === true) {
        return "flex items-center justify-around rounded-full w-8 h-8 bg-green text-white text-center font-bold align-middle";
      }

      return "flex items-center justify-center rounded-full border border-indigo w-8 h-8 text-grey-dark text-center font-bold align-middle";
    },
    prioClass(item) {
      if (item.priority === 1) {
        return "flex items-center justify-center rounded-full w-8 h-8 bg-red text-white text-center font-bold align-middle";
      }

      return "flex items-center justify-center rounded-full border border-indigo w-8 h-8 text-grey-dark text-center font-bold align-middle";
    },
    sortPatients(key) {
      this.$store.dispatch("sortPatients", key).then(() => {
        this.$store.dispatch("loadOrganisationPatients");
      });
    },
    search: debounce(function() {
      this.$store
        .dispatch("setSearchTerms", this.searchTerms)
        .then(() => {
          this.$store.dispatch("changePage", 1).then(() => {
            this.$store.dispatch("loadOrganisationPatients");
          });
        })
        .catch(() => {});
    }, 200),
  },
};
</script>

<style>
/* .slide-fade-enter-active {
  transition: all 0.15s ease;
}
.slide-fade-leave-active {
  transition: all 0.15s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
} */
</style>
