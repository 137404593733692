<template>
  <div>
    <Header :title="$t('user.' + form)" />
    <div v-if="loaded">
      <component
        :is="form"
        :patientId="patientId"
        :id="id"
        :form="userForm"
      ></component>
    </div>
    <div v-else class="w-full mb-6 mt-6">
      <div class="bg-white p-2 w-full text-center pt-8">
        <font-awesome-icon
          icon="spinner"
          class="mx-2 text-blue align-middle"
          spin
          size="2x"
        ></font-awesome-icon>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import DIVA5 from "@/components/forms/diva5/DIVA5.vue";
export default {
  name: "Form",
  data() {
    return {
      form: this.$route.params.form,
      patientId: this.$route.params.patientId,
      id: this.$route.params.id,
      userForm: null,
      loaded: false,
    };
  },
  mounted() {
    if (this.id) {
      this.$store.dispatch("loadUserForm", this.id).then(() => {
        this.userForm = this.$store.getters.getUserForm;
        this.loaded = true;
      });
    } else {
      this.loaded = true;
      if (this.patientId === undefined) {
        this.$router.push({ name: "list_patients" });
      }
    }
  },
  components: {
    Header,
    DIVA5,
  },
};
</script>
