import { apiPostRequest, apiGetRequest } from "./api";

const sortAsc = (a, b) => a - b;
const sortDesc = (a, b) => b - a;

const patientRequest = (patientId) => {
  return apiGetRequest(`patients/${patientId}`);
};

const referralsRequest = () => {
  return apiGetRequest("patients/referrals");
};

const patientsRequest = (params = "page=1") => {
  return apiGetRequest("patients?" + params);
};

const answersRequest = (responseId) => {
  return apiGetRequest(`forms/answers/${responseId}`);
};

const scorehistoryRequest = (patientId) => {
  return apiGetRequest(`history/scores/${patientId}`);
};

const rejectRequest = (data) => {
  return apiPostRequest("patients/reject/", data);
};

const acceptRequest = (data) => {
  return apiPostRequest("patients/accept/", data);
};

const sendComplementaryFormsRequest = (data) => {
  return apiPostRequest("notification", data);
};

const userFormsRequest = (patientId) => {
  return apiGetRequest("user/forms/" + patientId);
};

const getOverviewGraphDataRequest = (patientId) => {
  return apiGetRequest("patient/overviewData/" + patientId);
};

export default {
  state: {
    selectedPatient: null,
    selectedPatientData: null,
    listType: null,
    referralList: [],
    patientList: [],
    listsort: {
      referrals: { col: null, asc: true },
      patients: {
        col: null,
        asc: true,
        page: 1,
        total: 0,
        pages: 1,
        searchTerms: ""
      },
    },
    answersLoaded: false,
    complementaryFormsSent: false,
  },
  mutations: {
    setComplementaryFormsSent(state, value) {
      state.complementaryFormsSent = value;
    },
    setAnswersLoaded(state, value) {
      state.answersLoaded = value;
    },
    setSelectedPatient(state, patientId) {
      state.selectedPatient = patientId;
    },
    setSelectedPatientData(state, data) {
      state.selectedPatientData = data;
    },
    setReferralList(state, data) {
      state.referralList = data;
    },
    setPatientList(state, data) {
      state.patientList = data;
    },
    excludePatientFromReferrals(state, patientId) {
      state.referralList.data = state.referralList.data.filter(
        (p) => p.id !== patientId
      );
    },
    setListType(state, type) {
      state.listType = type;
    },
    sortReferrals(state, key) {
      const oldCol = state.listsort.referrals.col;
      state.listsort.referrals = {
        col: key,
        asc: oldCol != key ? true : !state.listsort.referrals.asc,
      };
    },
    sortPatients(state, key) {
      const oldCol = state.listsort.patients.col;
      state.listsort.patients.col = key;
      state.listsort.patients.asc =
        oldCol != key ? true : !state.listsort.patients.asc;
    },
    changePage(state, page) {
      state.listsort.patients.page = page;
    },
    setSearchTerms(state, searchTerms) {
      state.listsort.patients.searchTerms = searchTerms;
    }
  },
  actions: {
    setSelectedPatient({ commit }, patientId) {
      commit("setSelectedPatient", patientId);
    },
    setListType({ commit }, type) {
      commit("setSelectedPatient", null);
      commit("setListType", type);
    },
    sortReferrals({ commit }, key) {
      commit("sortReferrals", key);
    },
    sortPatients({ commit }, key) {
      commit("sortPatients", key);
    },
    changePage({ commit }, page) {
      commit("changePage", page);
    },
    async loadPatientOverview({ commit }, patientId) {
      const patient = await patientRequest(patientId).then((data) => data);

      let referralform = null;
      if (patient.form_responses !== null) {
        const form = patient.form_responses
          .filter((r) => r.code == "OWNREFERRAL")
          .sort((a, b) => {
            return a.created < b.created;
          })[0];

        if (
          patient.own_referral_id !== null &&
          typeof patient.own_referral_id !== "undefined"
        ) {
          referralform = await answersRequest(patient.own_referral_id).then(
            (data) => data
          );
        } else if (form && typeof form !== "undefined") {
          referralform = await answersRequest(form.response_id).then(
            (data) => data
          );
        }
      }
      const scorehistory = await scorehistoryRequest(patientId).then(
        (data) => data
      );
      patient.form_responses =
        patient.form_responses != null
          ? patient.form_responses
              .filter((form) => form.code !== "OWNREFERRAL")
              .sort((a, b) => a.code.localeCompare(b.code))
          : [];

      const userForms = await userFormsRequest(patientId).then((data) => data);

      const consents = await apiGetRequest("getAllConsents/" + patientId).then(
        (response) => response
      );

      const graphs = await getOverviewGraphDataRequest(patientId).then((data) => data);

      commit("setSelectedPatientData", {
        patient: patient,
        referralform: referralform,
        scorehistory: scorehistory,
        userForms: userForms,
        consents: consents,
        graphs: graphs
      });

      return true;
    },
    async loadReferralList({ commit }) {
      return await referralsRequest()
        .then((data) => {
          data.data.map((p) => {
            p.accepted = p.rejected_date === null ? true : false;
            if (p.recent_scores) {
              let dudit = p.recent_scores.find((r) => r.code == "DUDIT");
              let audit = p.recent_scores.find((r) => r.code == "AUDIT");
              let whodas36 = p.recent_scores.find((r) => r.code == "WHODAS36");
              let whodas12 = p.recent_scores.find((r) => r.code == "WHODAS12");
              let madrs = p.recent_scores.find((r) => r.code == "MADRS");
              let dsm5 = p.recent_scores.find((r) => r.code == "DSM5");
              let lpfsbf = p.recent_scores.find((r) => r.code == "LPFSBF");
              let asrs = p.recent_scores.find((r) => r.code == "ASRS");
              let pid5 = p.recent_scores.find((r) => r.code == "PID5");
              let raads14 = p.recent_scores.find((r) => r.code == "RAADS14");
              let raads80 = p.recent_scores.find((r) => r.code == "RAADS80");
              let core10 = p.recent_scores.find((r) => r.code == "CORE10");
              let coreom = p.recent_scores.find((r) => r.code == "COREOM");

              p.dudit = dudit ? dudit.score : null;
              p.audit = audit ? audit.score : null;
              p.whodas36 = whodas36 ? whodas36.score : null;
              p.whodas12 = whodas12 ? whodas12.score : null;
              p.madrs = madrs ? madrs.score : null;
              p.dsm5 = dsm5 ? dsm5.score : null;
              p.lpfsbf = lpfsbf ? lpfsbf.score : null;
              p.asrs = asrs ? asrs.score : null;
              p.pid5 = pid5 ? pid5.score : null;
              p.raads14 = raads14 ? raads14.score : null;
              p.raads80 = raads80 ? raads80.score : null;
              p.core10 = core10 ? core10.score : null;
              p.coreom = coreom ? coreom.score : null;
            }

            return p;
          });
          commit("setReferralList", data);
        })
        .catch(() => {
          commit("setReferralList", null);
        });
    },
    async loadOrganisationPatients({ commit, state }) {
      let string = new URLSearchParams({
        page: state.listsort.patients.page,
        sort: state.listsort.patients.col,
        sortAsc: state.listsort.patients.asc,
        searchTerms: state.listsort.patients.searchTerms
      });

      return await patientsRequest(string.toString())
        .then((data) => {
          if (data.data) {
            data.data.map((p) => {
              p.accepted = p.rejected_date === null ? true : false;
              if (p.recent_scores) {
                let dudit = p.recent_scores.find((r) => r.code == "DUDIT");
                let audit = p.recent_scores.find((r) => r.code == "AUDIT");
                let whodas36 = p.recent_scores.find(
                  (r) => r.code == "WHODAS36"
                );
                let whodas12 = p.recent_scores.find(
                  (r) => r.code == "WHODAS12"
                );
                let madrs = p.recent_scores.find((r) => r.code == "MADRS");
                let dsm5 = p.recent_scores.find((r) => r.code == "DSM5");
                let lpfsbf = p.recent_scores.find((r) => r.code == "LPFSBF");
                let asrs = p.recent_scores.find((r) => r.code == "ASRS");
                let pid5 = p.recent_scores.find((r) => r.code == "PID5");
                let raads14 = p.recent_scores.find((r) => r.code == "RAADS14");
                let raads80 = p.recent_scores.find((r) => r.code == "RAADS80");
                let core10 = p.recent_scores.find((r) => r.code == "CORE10");
                let coreom = p.recent_scores.find((r) => r.code == "COREOM");

                p.dudit = dudit ? dudit.score : null;
                p.audit = audit ? audit.score : null;
                p.whodas36 = whodas36 ? whodas36.score : null;
                p.whodas12 = whodas12 ? whodas12.score : null;
                p.madrs = madrs ? madrs.score : null;
                p.dsm5 = dsm5 ? dsm5.score : null;
                p.lpfsbf = lpfsbf ? lpfsbf.score : null;
                p.asrs = asrs ? asrs.score : null;
                p.pid5 = pid5 ? pid5.score : null;
                p.raads14 = raads14 ? raads14.score : null;
                p.raads80 = raads80 ? raads80.score : null;
                p.core10 = core10 ? core10.score : null;
                p.coreom = coreom ? coreom.score : null;
              }

              return p;
            });
          }
          commit("setPatientList", data);
        })
        .catch(() => {
          commit("setPatientList", null);
        });
    },
    async acceptPatient({ commit }, data) {
      return await acceptRequest(data)
        .then(() => {
          commit("setSelectedPatient", null);
          commit("excludePatientFromReferrals", data.patient_id);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    async rejectPatient({ commit }, data) {
      return await rejectRequest(data)
        .then(() => {
          commit("setSelectedPatient", null);
          commit("excludePatientFromReferrals", data.patient_id);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    async sendComplementaryForms({ commit }, data) {
      let notification = {
        to_patient_id: data.patient_id,
        message: data.message,
        title: "extra form",
        from: "tesuser",
      };
      commit("setComplementaryFormsSent", true);
      return await sendComplementaryFormsRequest(notification)
        .then(() => {
          return true;
        })
        .catch(() => false);
    },
    async loadFormAnswers({ commit }, responseId) {
      return await answersRequest(responseId).then((data) => {
        commit("setAnswersLoaded", true);
        return data;
      });
      // .catch(error => {  })
    },
    setSearchTerms({ commit }, searchTerms) {
      commit('setSearchTerms', searchTerms);
    }
  },
  getters: {
    selectedPatient: (state) => state.selectedPatient,
    selectedPatientData: (state) => state.selectedPatientData,
    referralList: (state) => {
      if (!state.referralList) return null;

      if (state.listsort.referrals.col === null) {
        return state.referralList.data;
      }

      return state.referralList.data.sort((a, b) => {
        return state.listsort.referrals.asc
          ? sortAsc(
              a[state.listsort.referrals.col],
              b[state.listsort.referrals.col]
            )
          : sortDesc(
              a[state.listsort.referrals.col],
              b[state.listsort.referrals.col]
            );
      });
    },
    patientList: (state) => {
      if (!state.patientList) return null;

      return state.patientList.data;
    },
    patientListTotal: (state) => {
      return state.patientList ? state.patientList.total : 0;
    },
    patientListCount: (state) => {
      return state.patientList ? state.patientList.count : 0;
    },
    referralListTotal: (state) => {
      return state.referralList ? state.referralList.total : 0;
    },
    referralListCount: (state) => {
      return state.referralList ? state.referralList.count : 0;
    },
    patientPages: (state) => {
      return state.referralList ? state.patientList.pages : 0;
    },
    patientPage: (state) => {
      return state.referralList ? state.patientList.page : 1;
    },
    referralPage: (state) => {
      return state.referralList ? state.referralList.page : 1;
    },
    referralPages: (state) => {
      return state.referralList ? state.referralList.pages : 0;
    },
    listTotal: (state, getters) => {
      return state.listType == "referrals"
        ? getters.referralListTotal
        : getters.patientListTotal;
    },
    listCount: (state, getters) => {
      return state.listType == "referrals"
        ? getters.referralListCount
        : getters.patientListCount;
    },
    listPages: (state, getters) => {
      return state.listType == "referrals"
        ? getters.referralPages
        : getters.patientPages;
    },
    listPage: (state, getters) => {
      return state.listType == "referrals"
        ? getters.referralPage
        : getters.patientPage;
    },
    sortcolumn: (state) =>
      state.listType ? state.listsort[state.listType].col : "patient_id",
    sortorder: (state) =>
      state.listType ? state.listsort[state.listType].asc : true,
    page: (state) => (state.listType ? state.listsort[state.listType].page : 1),
    total: (state) =>
      state.listType ? state.listsort[state.listType].total : 0,
    currentList: (state, getters) => {
      return state.listType == "referrals"
        ? getters.referralList
        : getters.patientList;
    },
  },
};
