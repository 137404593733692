<template>
    <div class="flex flex-col mx-4 my-4">
        <span class="text-grey-darkest font-semibold">Återremittera och skicka svar</span>

        <textarea class="border border-grey-darkest rounded rounded-lg p-2 my-2" v-model="message"></textarea>
        <textarea class="border border-grey-darkest rounded rounded-lg p-2 my-2">Med vänliga hälsningar ...</textarea>

        <div class="text-center">
            <BaseButton class="mt-2" @click.native="rejectPatient()">Skicka remissvar</BaseButton>
            <BaseButton class="mt-2" color="white">Återställ till standardtext</BaseButton>
            <BaseButton class="mt-2" color="red" @click.native="$emit('resetPatientActivity', 'reject')">Avbryt</BaseButton>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PatientReject',
    props: ['patientId'],
    data () {
        return {
            message: 'Tack för ....'
        }
    },
    methods: {
        rejectPatient () {
            let data = {
                patient_id: this.patientId,
                message: this.message
            }
            this.$store.dispatch('rejectPatient', data)
                .then(() => {
                })
                .catch(() => {
                })
        }
    }
}
</script>