<template>
  <div class="bg-white rounded rounded-lg">
    <div class="bg-grey-light text-grey-darkest rounded-t-lg text-lg text-center py-2">{{ title }}</div>
    <div v-for="(item, key) in sorteddata" :key="key" class="flex py-1">
      <div class="w-2/3 text-right mr-2">{{ item.category }}</div>
      <div class="w-1/3 text-left font-bold">{{ item.value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsTable",
  props: ["id", "data", "propwidth", "title"],
  computed: {
    sorteddata() {
      let sorted = this.data;

      return sorted.sort((a, b) => {
        return a.category.localeCompare(b.category);
      });
    }
  }
};
</script>
