<template>
  <div :id="id"></div>
</template>

<script>
//https://bl.ocks.org/alandunning/4c36eb1abdb248de34c64f5672afd857
import * as d3 from "d3";
import { formMetadata } from "../../utils/metadata.js";

export default {
  name: "SpiderChart",
  props: {
    id: String,
    code: String,
    width: {
      type: Number,
      default: 500,
    },
    height: {
      type: Number,
      default: 500,
    },
    sections: Array,
  },
  data() {
    return {
      svg: null,
      count: 0,
      config: {
        color: d3.scaleOrdinal(d3.schemeCategory10), //.range(["#6F257F", "#CA0D59"]),
        factor: 1,
        format: d3.format("%"),
        heightExtra: 50,
        labelFactor: 1.1,
        levels: 5,
        opacityArea: 0.5,
        radians: 2 * Math.PI,
        toRight: 5,
        widthExtra: 50,
        wrapWidth: 60,
        pointSize: 6,
      },
    };
  },
  watch: {
    width: {
      immediate: true,
      handler(val) {
        if (val !== null) this.createChart();
      },
    },
  },
  computed: {
    chart() {
      return {
        margin: { t: 40, r: 40, b: 40, l: 40 },
        width: Math.min(this.width, this.height),
        height: Math.min(this.width, this.height),
        radius: Math.min(this.width / 2, this.height / 2),
      };
    },
    numberOfCategories() {
      return this.sections[0].length;
    },
    categoryNames() {
      return this.sections[0].map((d) => d.title);
    },
    maxValue() {
      return 100; //Math.max(d3.max(this.sections[0], d => d.score), 10)
    },
    metadata() {
      return formMetadata[this.code];
    },
    categories() {
      let sectionMaxValue = this.metadata.sectionChartMax;

      return this.sections.map((s) => {
        return s.map((c, i) => {
          return {
            percent: Math.round((c.score / sectionMaxValue) * 100, 2),
            score: c.score,
            title: c.title,
            axis: i + 1,
          };
        });
      });
    },
  },
  methods: {
    createChart() {
      let svg = d3.select(`#${this.id}`).selectAll("svg").data([null]);

      svg
        .enter()
        .append("svg")
        .attr(
          "width",
          this.chart.width +
            this.config.widthExtra +
            this.chart.margin.l +
            this.chart.margin.r
        )
        .attr(
          "height",
          this.chart.height +
            this.config.heightExtra +
            this.chart.margin.t +
            this.chart.margin.b
        )
        .append("g")
        .attr("id", "chart-container");

      this.svg = d3.select("#chart-container");

      this.svg.attr(
        "transform",
        "translate(" +
          (this.chart.width +
            this.config.widthExtra +
            this.chart.margin.l +
            this.chart.margin.r) /
            2 +
          ", " +
          (this.chart.height +
            this.config.heightExtra +
            this.chart.margin.t +
            this.chart.margin.b) /
            2 +
          ")"
      );

      //     this.updateSpider()
      // },
      // updateSpider() {
      let rScale = d3
        .scaleLinear()
        .domain([0, this.maxValue])
        .range([0, this.chart.radius]);

      let angleSlice = this.config.radians / this.categoryNames.length;

      let axisGrid = this.svg.append("g").attr("class", "axisGrid");

      //sections
      for (let j = 0; j < this.config.levels; j++) {
        let levelFactor =
          this.config.factor *
          this.chart.radius *
          ((j + 1) / this.config.levels);

        axisGrid
          .selectAll(".levels")
          .data(this.categoryNames)
          .enter()
          .append("line")
          .attr("x1", (d, i) => {
            return (
              levelFactor *
              (this.config.factor *
                Math.sin((i * this.config.radians) / this.numberOfCategories))
            );
          })
          .attr("y1", (d, i) => {
            return (
              levelFactor *
              (this.config.factor *
                Math.cos((i * this.config.radians) / this.numberOfCategories))
            );
          })
          .attr("x2", (d, i) => {
            return (
              levelFactor *
              (this.config.factor *
                Math.sin(
                  ((i + 1) * this.config.radians) / this.numberOfCategories
                ))
            );
          })
          .attr("y2", (d, i) => {
            return (
              levelFactor *
              (this.config.factor *
                Math.cos(
                  ((i + 1) * this.config.radians) / this.numberOfCategories
                ))
            );
          })
          .attr("class", "levelLine")
          .style("stroke", "grey")
          .style("stroke-opacity", "0.75")
          .style("stroke-width", "0.9px");

        // .attr("transform", "translate(" + (levelFactor) + ", " + (levelFactor) + ")");
      }

      //Text indicating at what % each level is
      for (let j = 0; j < this.config.levels; j++) {
        let levelFactor =
          this.config.factor *
          this.chart.radius *
          ((j + 1) / this.config.levels);
        axisGrid
          .selectAll(".levels")
          .data([1]) //dummy data
          .enter()
          .append("svg:text")
          .attr("x", () => {
            return levelFactor * (this.config.factor * Math.sin(0));
          })
          .attr("y", () => {
            return -levelFactor * (this.config.factor * Math.cos(0));
          })
          .attr("class", "text-sm font-sans text-grey-dark")
          // .attr("transform", "translate(" + (this.chart.width/2-levelFactor + this.config.toRight) + ", " + (this.chart.height/2-levelFactor) + ")")
          .attr("fill", "#737373")
          .text(((j + 1) * 100) / this.config.levels + "%");
      }

      let series = 0;
      this.categories.forEach((y) => {
        let dataValues = [];
        axisGrid.selectAll(".nodes").data(y, (j, i) => {
          dataValues.push([
            (this.chart.width / 2) *
              ((parseFloat(Math.max(j.percent, 0)) / this.maxValue) *
                this.config.factor *
                Math.sin((i * this.config.radians) / this.numberOfCategories)),
            (this.chart.height / 2) *
              ((parseFloat(Math.max(j.percent, 0)) / this.maxValue) *
                this.config.factor *
                Math.cos((i * this.config.radians) / this.numberOfCategories)),
          ]);
        });
        dataValues.push(dataValues[0]);
        axisGrid
          .selectAll(".area")
          .data([dataValues])
          .enter()
          .append("polygon")
          .attr("class", "radar-chart-serie" + series)
          .style("stroke-width", "3px")
          .style("stroke", this.config.color(series))
          .attr("points", function (d) {
            let str = "";
            for (let i = 0; i < d.length; i++) {
              str += d[i][0] + "," + -d[i][1] + " ";
            }

            return str;
          })
          .style("fill", () => this.config.color(series))
          .style("fill-opacity", this.config.opacityArea)
          .on("mouseover", function () {
            let z = "polygon." + d3.select(this).attr("class");
            axisGrid
              .selectAll("polygon")
              .transition(100)
              .style("fill-opacity", 0.1);
            axisGrid.selectAll(z).transition(100).style("fill-opacity", 0.7);
          })
          .on("mouseout", () => {
            axisGrid
              .selectAll("polygon")
              .transition(100)
              .style("fill-opacity", this.config.opacityArea);
          });

        series++;
      });
      series = 0;

      let tooltips = d3.select("body").select(".toolTip").data([null]);

      let tooltip = tooltips.enter().append("div").attr("class", "toolTip");

      this.categories.forEach((y) => {
        let dataValues = [];
        axisGrid
          .selectAll(".nodes")
          .data(y)
          .enter()
          .append("svg:circle")
          .attr("class", "radar-chart-serie" + series)
          .attr("r", this.config.pointSize)
          .attr("alt", function (j) {
            return Math.max(j.percent, 0);
          })
          .attr("cx", (j, i) => {
            dataValues.push([
              (this.chart.width / 2) *
                ((parseFloat(Math.max(j.percent, 0)) / this.maxValue) *
                  this.config.factor *
                  Math.sin(
                    (i * this.config.radians) / this.numberOfCategories
                  )),
              (this.chart.height / 2) *
                ((parseFloat(Math.max(j.percent, 0)) / this.maxValue) *
                  this.config.factor *
                  Math.cos(
                    (i * this.config.radians) / this.numberOfCategories
                  )),
            ]);
            return (
              (this.chart.width / 2) *
              ((Math.max(j.percent, 0) / this.maxValue) *
                this.config.factor *
                Math.sin((i * this.config.radians) / this.numberOfCategories))
            );
          })
          .attr("cy", (j, i) => {
            return (
              (-this.chart.height / 2) *
              ((Math.max(j.percent, 0) / this.maxValue) *
                this.config.factor *
                Math.cos((i * this.config.radians) / this.numberOfCategories))
            );
          })
          .attr("data-id", (j) => j.axis)
          .style("fill", "#fff")
          .style("stroke-width", "2px")
          .style("stroke", this.config.color(series))
          .style("fill-opacity", 0.9)
          .on("mouseover", (d) => {
            tooltip
              .style("left", d3.event.pageX + 15 + "px")
              .style("top", d3.event.pageY - 25 + "px")
              .style("display", "inline-block")
              .html(d.title + "<br><span>" + d.percent + "%</span>");
          })
          .on("mouseout", () => {
            tooltip.style("display", "none");
          });

        series++;
      });

      // axisGrid.selectAll(".circularLevel")
      //   .data(d3.range(1, this.numberOfCategories-1))
      //   .enter()
      //   .append('circle')
      //   .attr('class', 'circularLevel')
      //   .attr('r', (d, i) => { return this.chart.radius/this.config.levels * i })
      //   .style('fill', '#FFFFFF')
      //   .style('stroke', '#a9a9a9')
      //   .style('fill-opacity', .15)

      // let gradientRange = ['green', 'red']
      // let gradientColor = d3.scaleLinear().range(gradientRange).domain([0, 1]);

      // let radialGradient = axisGrid.append("defs")
      //     .append("radialGradient")
      //     .attr("id", "radial-gradient");

      // radialGradient.append("stop")
      //     .attr("offset", "50%")
      //     .attr("stop-color", gradientColor(0));

      // radialGradient.append("stop")
      //     .attr("offset", "100%")
      //     .attr("stop-color", gradientColor(1));

      // axisGrid
      //   .append('circle')
      //   .attr('r', this.chart.radius)
      //   // .style('fill', ['red', 'green'])
      //   .style('fill', 'url(#radial-gradient)')
      //   // .style('stroke', '#a9a9a9')
      //   .style('fill-opacity', .35)

      // axisGrid.selectAll(".axisLabel")
      //   .data(d3.range(1, (this.config.levels+1)).reverse())
      //   .enter()
      //   .append("text")
      //   // .attr('text-anchor', 'middle')
      //   .attr("class", "axisLabel")
      //   .attr("x", 4)
      //   .attr("y", (d) => -d*this.chart.radius/this.config.levels )
      //   .attr("dy", "0.4em")
      //   .style("font-size", "10px")
      //   .attr("fill", "#737373")
      //   .text((d,i) => Math.round(((this.maxValue * d/this.config.levels)/this.maxValue)*100), 0)

      let axis = axisGrid
        .selectAll(".axis")
        .data(this.categoryNames)
        .enter()
        .append("g")
        .attr("class", "axis");

      //Append the lines
      axis
        .append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", (d, i) => {
          return (
            rScale(this.maxValue * 1) * Math.cos(angleSlice * i - Math.PI / 2)
          );
        })
        .attr("y2", (d, i) => {
          return (
            rScale(this.maxValue * 1) * Math.sin(angleSlice * i - Math.PI / 2)
          );
        })
        .attr("class", "line")
        .style("stroke", "#565656")
        .style("stroke-width", ".3px");

      // Append the labels at each axis
      axis
        .append("text")
        .attr("class", "font-sans text-xs whitespace-break")
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .attr("x", (d, i) => {
          return (
            rScale(this.maxValue * this.config.labelFactor) *
            Math.cos(angleSlice * i - Math.PI / 2)
          );
        })
        .attr("y", (d, i) => {
          return (
            rScale(this.maxValue * this.config.labelFactor) *
            Math.sin(angleSlice * i - Math.PI / 2)
          );
        })
        .text((d) => d);
      // .call(this.wrap, this.config.wrapWidth);
    },
    // wrap (text, width) {
    //   text.each(function() {
    //     let text = d3.select(this),
    //       words = text.text().split(/\s+/).reverse(),
    //       word,
    //       line = [],
    //       lineNumber = 0,
    //       lineHeight = 1.1, // ems
    //       y = text.attr("y"),
    //       x = text.attr("x"),
    //       dy = parseFloat(text.attr("dy")),
    //       tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");

    //       while (word = words.pop()) {
    //           line.push(word);
    //           tspan.text(line.join(" "));
    //           if (tspan.node().getComputedTextLength() > width) {
    //           line.pop();
    //           tspan.text(line.join(" "));
    //           line = [word];
    //           tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
    //       }
    //     }
    //   })
    // }
  },
};
</script>

<style>
.axis path,
.axis line {
  fill: none;
  stroke: #d4d8da;
  stroke-width: 2px;
  shape-rendering: crispEdges;
}
.toolTip {
  pointer-events: none;
  position: absolute;
  display: none;
  min-width: 50px;
  height: auto;
  background: none repeat scroll 0 0 #ecefea;
  padding: 3px 5px 2px 5px;
  border-radius: 2px;
  text-align: center;
  line-height: 1.3;
  color: #3b4750;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}
.toolTip:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ecefea;
  position: absolute;
  left: -12px;
  top: 10%;
  margin-top: 12px;
}
.toolTip span {
  font-weight: 500;
  color: #081f2c;
}
</style>