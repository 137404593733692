import { apiGetRequest } from './api';

const monthlyStatsRequest = () => {
  return apiGetRequest(`stats/monthlystats`);
};

const monthlyStatsRollingYearRequest = () => {
  return apiGetRequest(`stats/monthlystats-rolling-year`);
};

const patientCounterRequest = () => {
  return apiGetRequest(`stats/patientcounter`);
};

const formCounterRequest = () => {
  return apiGetRequest(`stats/formcounter`);
};
const prioCounterRequest = () => {
  return apiGetRequest(`stats/priocounter`);
};
const auditlogCounterRequest = () => {
  return apiGetRequest(`stats/auditlogcounter`);
};

export default {
  state: {
    monthlyStatsRollingYear: null,
    monthlyStats: null,
    patientcounter: null,
    formcounter: null,
    priocounter: null,
    auditlogcounter: null,
  },
  mutations: {
    setMonthlyStats(state, data) {
      state.monthlyStats = data;
    },
    setMonthlyStatsRollingYear(state, data) {
      state.monthlyStatsRollingYear = data;
    },
    setPatientCounter(state, data) {
      state.patientcounter = data;
    },
    setFormCounter(state, data) {
      state.formcounter = data;
    },
    setPrioCounter(state, data) {
      state.priocounter = data;
    },
    setAuditlogCounter(state, data) {
      state.auditlogcounter = data;
    },
  },
  actions: {
    async loadMonthlyStats({ commit }) {
      return await monthlyStatsRequest()
        .then((response) => {
          commit('setMonthlyStats', response.data);
          return true;
        })
        .catch(() => false);
    },
    async loadMonthlyStatsRollingYear({ commit }) {
      return await monthlyStatsRollingYearRequest()
        .then((response) => {
          commit('setMonthlyStatsRollingYear', response.data);
          return true;
        })
        .catch(() => false);
    },
    async loadPatientCounter({ commit }) {
      return await patientCounterRequest()
        .then((response) => {
          commit('setPatientCounter', response.data);
          return true;
        })
        .catch(() => false);
    },
    async loadFormCounter({ commit }) {
      return await formCounterRequest()
        .then((response) => {
          commit('setFormCounter', response.data);
          return true;
        })
        .catch(() => false);
    },
    async loadPrioCounter({ commit }) {
      return await prioCounterRequest()
        .then((response) => {
          commit('setPrioCounter', response.data);
          return true;
        })
        .catch(() => false);
    },
    async loadAuditlogCounter({ commit }) {
      return await auditlogCounterRequest()
        .then((response) => {
          commit('setAuditlogCounter', response.data);
          return true;
        })
        .catch(() => false);
    },
  },
  getters: {
    monthlyStats: (state) => state.monthlyStats,
    monthlyStatsRollingYear: (state) => state.monthlyStatsRollingYear,
    patientcounter: (state) => state.patientcounter,
    formcounter: (state) => state.formcounter,
    priocounter: (state) => state.priocounter,
    auditlogcounter: (state) => state.auditlogcounter,
  },
};
