<template>
    <div>
        <div class="text-grey-dark">{{ form.date }}</div>
        <div class="font-bold mb-2">DIVA-5</div>
        <div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.Diagnos') }}</div>
                <div>
                    <div class="ml-2">{{ $t('user.forms.diagnos.' + bedomning.Diagnos) }} - {{ bedomning.Allvarlighetsgrad != false ? bedomning.Allvarlighetsgrad : "" }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.BarnSy') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.BarnSy ? "Ja" : "Nej" }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.VuxenOupp') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.VuxenOupp ? "Ja" : "Nej" }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.VuxenHyper') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.VuxenHyper ? "Ja" : "Nej" }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.LivsLangtSymp') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.LivsLangtSymp ? "Ja" : "Nej" }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.PsykStoB') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.PsykStoB ? "Ja" : "Nej" }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.PsykStoV') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.PsykStoV ? "Ja" : "Nej" }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.PsykAnnat') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.PsykAnnat ? "Ja, genom" : "Nej" }}</div>
                    <div class="ml-2">{{ bedomning.PsykAnnatMessage }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.Stodfamilj') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.Stodfamilj }} - {{ bedomning.Stodfamiljannat }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.Stodpartner') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.Stodpartner }} - {{ bedomning.StodPartnerAnnat }}</div>
                </div>
            </div>
            <div class="ml-4 mb-4">
                <div class="text-grey-dark">{{ $t('user.forms.Betyg') }}</div>
                <div>
                    <div class="ml-2">{{ bedomning.Betyg }}</div>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
export default {
    name: "Summary",
    props: {
        form: Object
    },
    computed: {
        bedomning() {
            return this.form.Bedomning;
        }
    },
}
</script>