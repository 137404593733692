<template>
    <div></div>
</template>

<script>
export default {
    name: 'DevTemp',
    props: [],
    mounted () {
        let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9mcmlza2FyZXZhcmRhZy5zZSIsImlhdCI6MTU2Nzk2MTU3MiwiZXhwIjoxNTY3OTk3NTcyLCJJZGVudGl0eUlkIjoic2l0aHMtMTExMTExMTExIiwiUGF0aWVudElkIjo4NSwiRmlyc3ROYW1lIjoiVG9sdmFuIiwiTGFzdE5hbWUiOiJUb2x2YW5zc29uIiwiU2Vzc2lvbklkIjoiMzIxNjU0OTg3IiwiVXNlcklkIjoxNywiT3JnYW5pc2F0aW9uSWQiOjQ5LCJPcmdhbmlzYXRpb25OYW1lIjoiVGVzdGVuaGV0IDEiLCJSb2xlcyI6IkFETUlOIn0.lMNnGxdAbRI9nF0LpqrnpsQsJ9awzPdy5JkYlyeeR7o'

        localStorage.setItem('user-token', token)
        // this.$store.commit('loginSuccess', true)
        this.$router.push({ name: 'list_patients' })
    },
}
</script>