import Vue from 'vue'
import Vuex from 'vuex'
import uiStore from './store/ui.js'
import userStore from './store/user.js'
import patientStore from './store/patient.js'
import adminStore from './store/admin.js'
import statsStore from './store/stats.js'
import formStore from './store/form.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui: uiStore,
    user: userStore,
    patient: patientStore,
    admin: adminStore,
    stats: statsStore,
    form: formStore
  }
})
