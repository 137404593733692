<template>
    <div>
        <div v-if="!editing" class="font-bold pb-2">{{ title }}</div>

        <textarea id="id"
            :placeholder="title"
            :name="id"
            @blur="blur"
            v-model="textanswer"
            class="rounded-lg border border-grey-light px-2 py-1 text-left text-xl w-full"></textarea>
    </div>
</template>

<script>
export default {
    name: "BaseTextarea",
    props: {
        formId: String,
        id: Number,
        title: String,
        answer: String,
        editing: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            textanswer: this.answer
        }
    },
    methods: {
        blur (evt) {
            let value = evt.target.value

            this.$store.dispatch('answer', {
                formId: this.formId,
                id: this.id,
                value: value,
                type: 'open',
                label: ''
            })
        }
    }
}
</script>
