<template>
  <div>
    <div class="bg-white p-4">
      <div>
        <Login />
        <div class="p-2 mt-1">
          {{ $t("login.sithsinfo") }}
        </div>
      </div>
    </div>

    <!-- <div class="bg-grey-lighter p-4"> -->
    <!-- <NationalOverview /> -->
    <!-- </div> -->
  </div>
</template>

<script>
import Login from "./Login.vue";
import NationalOverview from "./NationalOverview.vue";

export default {
  name: "Home",
  components: {
    Login,
    NationalOverview,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
