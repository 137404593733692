<template>
    <div class="bg-white rounded rounded-lg">
        <div class="bg-grey-light text-grey-darkest rounded-t-lg text-lg text-center py-2">{{ title }}</div>
        <div v-if="data" :id="`piechart-${id}`"></div>
    </div>
</template>

<script>
import * as d3 from 'd3';

export default {
    name: 'PieChart',
    props: ['id', 'data', 'propwidth', 'title'],
    data () {
        return {
        }
    },
    watch: {
        propwidth: {
            immediate: false,
            handler: function(value) {
                if (value) {
                    this.createChart()
                }
            }
        }
    },
    mounted () {
        this.createChart()
    },
    computed: {
        width () {
            return this.propwidth ? this.propwidth : this.$el.offsetWidth
        },
        height () {
            return this.width - 20
        },
        size () {
            return Math.min(this.width, this.height)
        }
    },
    methods: {
        createChart () {

            let data = this.data.filter(d => d.category != null)
            let total = data.reduce((acc, d) => { return acc + +d.value }, 0)

            d3.select(`#piechart-${this.id}`).select("svg").remove()

            let svg = d3.select(`#piechart-${this.id}`)
                .append('svg')
                .attr('class', 'test')
                .attr('width', this.width)
                .attr('height', this.height)

            let colors = d3.scaleOrdinal(d3.schemePastel1)

            let radius = this.size / 2.5

            let pie = d3.pie()
                .sort(null)
                .value(d => { return d.value })

            let innerArc = d3.arc()
                .innerRadius(radius * 0.7)
                .outerRadius(radius * 0.4);

            let outerArc = d3.arc()
                .outerRadius(radius * 0.9)
                .innerRadius(radius * 0.9);


            let piepath = d3.arc()
                .outerRadius(radius - 10)
                .innerRadius(20)


            let arcG = svg.append('g')
                .attr('transform', 'translate(' + this.size / 2 + ',' + this.size / 2 + ')')

            let arc = arcG
                .selectAll(".arc")
                .data(pie(data))
                .enter()
                .append("g")
                .attr("class", "arc");

            arc.append("path")
                .attr("d", piepath)
                .attr("fill", (d, i) => { return colors(i) })

            arcG.append("g")
                .attr("class", "labels");
            arcG.append("g")
                .attr("class", "lines");

            let midAngle = (d) => { return d.startAngle + (d.endAngle - d.startAngle) / 2; }

            svg.select('.lines')
                .selectAll('polyline')
                .data(pie(data))
                .enter()
                .append('polyline')
                .attr('points', function(d) {
                    var pos = outerArc.centroid(d);
                    pos[0] = radius * 0.65 * (midAngle(d) < Math.PI ? 1 : -1);
                    return [innerArc.centroid(d), outerArc.centroid(d), pos]
                });

            svg.select('.labels').selectAll('text')
                .data(pie(data))
                .enter()
                .append('text')
                .attr('dy', '.35em')
                .html(function(d) {
                    let percentage = Math.round(d.data.value/total * 100)

                    return `
                    <tspan x="0" class="text-sm">Prio ${d.data.category}</tspan>
                    <tspan x="0" dy="20" class="text-sm font-bold">${d.data.value}st.<tspan class="font-normal text-grey-dark"> (${percentage}%)</tspan></tspan>`;
                })
                .attr('transform', function(d) {
                    var pos = outerArc.centroid(d);
                    pos[0] = radius * 0.7 * (midAngle(d) < Math.PI ? 1 : -1);
                    return 'translate(' + pos + ')';
                })
                .style('text-anchor', function(d) {
                    return (midAngle(d)) < Math.PI ? 'start' : 'end';
                });
        }
    }
}
</script>

<style>
path.slice{
    stroke-width:2px;
}

polyline{
    opacity: .3;
    stroke: black;
    stroke-width: 2px;
    fill: none;
}
</style>
