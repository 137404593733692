<template>
  <div class="container mx-auto">
    <h1>{{ form.code }}</h1>

    <div
      v-for="(section, sectionkey) in form.sections"
      :key="sectionkey"
      class="text-left"
    >
      <h3>{{ section.title }}</h3>

      <div
        v-for="(question, questionkey) in section.questions"
        :key="questionkey"
      >
        <span>Type: {{ question.type }}</span>

        <div
          v-if="question.type === 'symbol'"
          class="flex flex-row justify-around"
        >
          <div v-for="(option, optionkey) in question.options" :key="optionkey">
            <div class="flex flex-row justify-around">
              <span>{{ option.key }}</span
              ><font-awesome-icon
                :icon="option.icon"
                class="ml-2"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminFormView",
  props: ["code"],
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    this.loadForm();
  },
  methods: {
    loadForm() {
      this.$store
        .dispatch("loadForm", this.code)
        .then((response) => {
          this.form = response.data;
        })
        .catch(() => {});
    },
  },
};
</script>