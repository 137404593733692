<template>
    <div class="mx-2 my-4">
        <div v-if="includeHeader">
            <div class="text-grey-dark">{{ form.created }}</div>
            <div class="font-bold">{{ form.title }}</div>
        </div>

        <div class="mt-2 ml-4">
            <div v-for="(section, index) in sections" :key="index">
                <span class="italic text-blue-darker">{{ section.title }}</span>
                <div v-for="(question, key) in section.questions" :key="key" class="my-2">
                    <div class="text-grey-dark">{{ question.view_id ?  question.view_id : question.question_id }}. {{ question.title }}</div>
                    <div class="flex">
                        <div v-if="question.numericAnswer" class="ml-4">{{ question.numericAnswer }} - </div>
                        <div v-if="question.answer" class="ml-1" v-html="question.answer"></div>
                        <div v-if="question.marked" class="ml-1 text-blue">({{ markedText }})</div>
                    </div>
                </div>
            </div>
        </div>

        <button class="block w-full text-center mt-8 mb-2" @click="$emit('toggleAnswersLoaded', form.response_id)">Dölj svar</button>

    </div>
</template>

<script>
export default {
    name: 'FormView',
    props: {
        responseId: {
            type: Number
        },
        includeHeader: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            form: null,
            // questions: [],
            sections: []
        }
    },
    watch: {
        responseId: {
            immediate: true,
            handler (value) {
                // this.questions.splice(0, this.questions.length)
                this.sections.splice(0, this.sections.length);
                this.loadForm(value)
            }
        }
    },
    methods: {
        loadForm (responseId) {
            this.$store.dispatch('loadFormAnswers', responseId)
                .then(data => {
                    this.form = data
                    this.flattenSections()
                })
        },
        flattenSections() {
            this.form.sections.forEach((section, id) => {
                this.sections[id] = {
                    title: section.title,
                    questions: []
                };

                section.questions.map(q => {

                    let text = q.answer != null ? true : false;

                    if (!text) {
                        let values = q.answers.map(a => {
                            return a.text != '-' ? a.text : `${a.text} <span class="italic text-blue-darker">(${this.$t('info.undecisive_answer')} ${a.value})</span>`
                        })

                        let numericAnswer = q.answers[0].value;
                        q.numericAnswer = numericAnswer.toString();

                        q.answer = values.join('<br />');
                    }

                    this.sections[id].questions.push(q);
                })
            })

            this.$forceUpdate();
        },
    },
    computed: {
        markedText () {
            console.log(this.form.code)
            switch (this.form.code) {
                case "ASRS":
                    return "Innan 12 års ålder";
                default:
                    return "";
            }
        }
    }
}
</script>