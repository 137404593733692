<template>
    <div class="bg-white rounded rounded-lg">
        <div class="bg-grey-light text-grey-darkest rounded-t-lg text-lg text-center py-2">{{ title }}</div>
        <div v-if="data" :id="`barchart-${id}`"></div>
    </div>
</template>

<script>
import * as d3 from 'd3';

export default {
    name: 'BarChart',
    props: ['id', 'data', 'propwidth', 'title'],
    data () {
        return {
        }
    },
    watch: {
        propwidth: {
            immediate: false,
            handler: function(value) {
                if (value) {
                    this.createChart()
                }
            }
        }
    },
    mounted () {
        this.createChart()
    },
    computed: {
        width () {
            return this.propwidth ? this.propwidth : this.$el.offsetWidth
        },
        height () {
            return Math.round((this.width/16) * 9) - 20
        }
    },
    methods: {
        createChart () {

            let margin = {
                top: 10,
                right: 30,
                left: 30,
                bottom: 20,
            }

            d3.select(`#barchart-${this.id}`).select("svg").remove()

            let svg = d3.select(`#barchart-${this.id}`)
                .append('svg')
                .attr('class', 'test')
                .attr('width', this.width)
                .attr('height', this.height)

            let colors = d3.scaleOrdinal(d3.schemePastel1)

            let x = d3.scaleBand()
                .domain(this.data.map(d => d.category))
                .range([margin.left, this.width - margin.right])
                .padding(0.1)

            let y = d3.scaleLinear()
                .domain([0, d3.max(this.data, d => d.value)]).nice()
                .range([this.height - margin.bottom, margin.top])


            let xAxis = g => g
                .attr("transform", `translate(0, ${this.height - margin.bottom})`)
                .call(d3.axisBottom(x)
                    .tickSizeOuter(0))

            let yAxis = g => g
                .attr("transform", `translate(${margin.left}, 0)`)
                .call(d3.axisLeft(y))
                .call(g => g.select(".domain").remove())

            svg.append("g")
                .call(xAxis);

            svg.append("g")
                .call(yAxis);

            let rect = svg
                .append('g')
                .selectAll('rect')
                .data(this.data)
                .enter()
                .append('rect')

            rect
                .attr('x', d => x(d.category))
                .attr('y', d => y(d.value))
                .attr('width', x.bandwidth())
                .attr('height', d => { return y(0) - y(d.value) })
                .attr('fill', (d, i) => colors(i))

        }
    }
}
</script>

