<template>
    <div>
        <table v-if="patients.length > 0" class="bg-white padded-table w-full border-collapse">
            <thead>
                <tr>
                    <th v-for="header in headers" :key="header.key" 
                        @click="$store.dispatch('sortReferrals', header.key)" 
                        class="text-base text-grey-darker uppercase break-words text-center"
                    >
                        <span class="ml-3 mr-1">{{ $t(`tableheaders.referral.${header.key}`) }}</span>
                        <font-awesome-icon :icon="sortIcon" class="text-sm" :class="transparentIcon(header.key)"></font-awesome-icon>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody is="transition-group" name="slide-fade">
                <tr v-for="item in patients" :key="item.id" @click="setSelectedPatient($event, item.id)" class="hover:cursor-pointer hover:bg-grey-light">

<!--
                    <td class="border-b border-grey" data-noclick="true">
                        <input data-noclick="true" type="checkbox" class="ml-2" :value="item.id" @click="$emit('toggleRow', $event.target.value, $event.target.checked)">
                    </td>
 -->
                    <td class="border-b border-grey py-1 pl-2">{{ item.first_name }} {{ item.last_name }}</td>
                    <!-- <td class="border-b border-grey text-center text-grey-dark" :title="formatDate(item.own_referral_date)">{{ numberOfDaysSinceReferral(item.own_referral_date) }}</td> -->
                    <td class="border-b border-grey text-center py-1"><div class="w-full flex justify-around text-center"><div :class="prioClass(item)">{{ item.priority }}</div></div></td>
                    <td class="border-b border-grey text-center">{{ item.whodas36 }}</td>
                    <td class="border-b border-grey text-center">{{ item.madrs }}</td>
                    <td class="border-b border-grey text-center">{{ item.audit }}</td>
                    <td class="border-b border-grey text-center">{{ item.dudit }}</td>
                    <td class="border-b border-grey text-center">{{ item.dsm5 }}</td>
                    <td class="border-b border-grey text-right"><img src="/images/cheveron-right.png" /></td>
                </tr>
            </tbody>
        </table>
        <div v-else class="text-center mt-16 w-full">
            <font-awesome-icon icon="glasses" class="text-8xl mx-2 text-grey-dark align-middle"></font-awesome-icon>
            <div class="text-grey-dark">Inga remisser matchade sökningen</div>
        </div>
    </div>

</template>

<script>
import { daysSince, localFormat } from '../utils/dateformat.js'

export default {
    name: 'ReferralsTable',
    props: ['headers', 'searchTerms'],
    data () {
        return {

        }
    },
    computed: {
        sortCol () {
            return this.$store.getters.sortcolumn
        },
        sortOrder () {
            return this.$store.getters.sortorder
        },        
        sortIcon () {
            return this.sortOrder ? 'chevron-up' : 'chevron-down'
        },
        patients () {
            let list = this.$store.getters.referralList

            if (this.searchTerms !== '') {
                let re = new RegExp(`${this.searchTerms}`, 'i')
                list = list.filter(row => {
                    if (
                        row.first_name.search(re) > -1
                        || row.last_name.search(re) > -1
                        || row.national_id.search(re) > -1
                    ) {
                        return true
                    }
                })
            }

            return list
        }
    },
    methods: {
        transparentIcon (key) {
            return key == this.sortCol ? 'text-indigo' : 'text-transparent'
        },
        setSelectedPatient (evt, patientId) {
            if (evt.target.dataset.noclick === undefined) {
                this.$store.dispatch('setSelectedPatient', patientId)
            }
        },
        formatDate (value) {
            return localFormat(value)
        },
        numberOfDaysSinceReferral (date) {
            return daysSince(date)
        },
        prioClass (item) {
            if (item.priority === 1) {
                return 'flex items-center justify-center rounded-full w-8 h-8 bg-red text-white text-center font-bold align-middle'
            }

            return 'flex items-center justify-center rounded-full border border-indigo w-8 h-8 text-grey-dark text-center font-bold align-middle'
        }
    }
}
</script>

<style>
/* .slide-fade-enter-active {
  transition: all .1s ease;
}
.slide-fade-leave-active {
  transition: all .1s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
} */
</style>