<template>
    <div class="container mx-auto bg-white">
        <Header title="Administrera paket"/>

        <div v-if="!loaded" class="w-full h-64 flex content-center justify-center">
            <div>
                <font-awesome-icon icon="spinner" spin></font-awesome-icon>
                <span class="pl-2">Laddar... </span>
            </div>
        </div>

        <div class="px-4 py-4" v-else>
            <packages v-for="(pack, i) in packages" :key="i" :pack="pack" @removePackage="removePackage"/>
            <div>
                <button class="bg-green p-2 rounded-lg text-white text-lg mx-2" v-on:click="addPackage">Lägg till</button>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../Header.vue'
import Packages from '../Packages.vue'

export default {
    name: "AdminPackages",
    props: [],
    components: {
        Header,
        Packages
    },
    data() {
        return {
            loaded: false,
            packages: [],
            allForms: []
        }
    },
    mounted() {
        this.loadPackages();
        this.loadForms();
    },
    computed: {
        
    },
    methods: {
        loadPackages() {
            this.$store.dispatch('loadPackages').then(response => {
                this.loaded = true;
                this.packages = response;
            })
        },
        loadForms() {
            this.$store.dispatch('loadAllForms').then(response => {
                this.allForms = response;
            })
        },
        addPackage() {
            this.packages.push({
                'title': "",
                'forms': [],
                'availableForms': this.allForms,
            });
        },
        removePackage(pack) {
            if(confirm("Är du säker?")){
                if(pack.id){
                    this.$store.dispatch("removePackage", pack.id).then(() => {
                        this.packages.splice(this.packages.indexOf(pack), 1);
                    }).catch(error => {
                        alert(error.response.data.message);
                    })
                }else{
                    this.packages.splice(this.packages.indexOf(pack), 1);
                }
            }
        }
    },
}
</script>