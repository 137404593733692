<template>
  <div>
    <div class="mt-2 flex flex-wrap justify-center">
      <ResizableContainer>
        <MultiLineChart
          slot-scope="{ width, height }"
          :id="`history-score-chart`"
          :data="scorehistory"
        />
      </ResizableContainer>
    </div>
  </div>
</template>

<script>
// import { formMetadata } from "../utils/metadata.js";
import ResizableContainer from "./ResizableContainer.vue";
import MultiLineChart from "./charts/MultiLineChart.vue";

export default {
  name: "PatientOverviewFormHistory",
  props: ["scorehistory"],
  components: {
    ResizableContainer,
    MultiLineChart,
  },
  computed: {
    // sortedForms () {
    // let forms = this.forms
    //     forms.sort((a, b) => a.code.localeCompare(b.code))
    //     forms = forms
    //         .filter(f => formMetadata[f.code] !== undefined)
    //         .map(f => {
    //             f.color = formMetadata[f.code].color || 'black'
    //             f.maxScore = formMetadata[f.code].maxScore || 100
    //             return f
    //         })
    // return forms
    // }
  },
};
</script>
