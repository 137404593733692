var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"title":_vm.$t('routes.admin.usagestats')}}),_vm._v(" "),_c('div',{staticClass:"w-full flex mt-4 bg-white p-4"},[_c('table',{staticClass:"table select-text"},[_vm._m(0),_vm._v(" "),(_vm.monthlyStatsRollingYear !== null)?_c('tbody',{staticClass:"mt-2"},[_vm._l((_vm.monthlyStatsRollingYear['new_users']),function(users,ym){return _vm._l((_vm.uniqueUnits),function(unit,key){return _c('tr',{staticClass:"border-t",class:key === 0 ? 'border-blue-darker' : ''},[_c('td',{staticClass:"text-left px-2 sm:whitespace-no-wrap"},[_vm._v(_vm._s(key === 0 ? ym : ' '))]),_vm._v(" "),_c('td',{staticClass:"text-left px-2 sm:whitespace-no-wrap"},[_vm._v(_vm._s(unit))]),_vm._v(" "),_c('td',{staticClass:"text-center px-2"},[_vm._v(_vm._s(_vm.monthlyStatsRollingYear['new_users'][ym] && _vm.monthlyStatsRollingYear['new_users'][ym][unit] ? _vm.monthlyStatsRollingYear['new_users'][ym][unit].num : ''))]),_vm._v(" "),_c('td',{staticClass:"text-center px-2"},[_vm._v(_vm._s(_vm.monthlyStatsRollingYear['package_newvisit_added'][ym] && _vm.monthlyStatsRollingYear['package_newvisit_added'][ym][unit] ? _vm.monthlyStatsRollingYear['package_newvisit_added'][ym][unit].num : ''))]),_vm._v(" "),_c('td',{staticClass:"text-center px-2"},[_vm._v(_vm._s(_vm.monthlyStatsRollingYear['package_newvisit_viewed'][ym] && _vm.monthlyStatsRollingYear['package_newvisit_viewed'][ym][unit] ? _vm.monthlyStatsRollingYear['package_newvisit_viewed'][ym][unit].num : ''))])])})})],2):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"w-full flex flex-wrap"},[(_vm.patientcounter)?_c('div',{staticClass:"w-full md:w-1/2 lg:w-1/3 my-2"},[_c('ResizableContainer',{staticClass:"mx-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var width = ref.width;
return _c('bar-chart',{attrs:{"id":"patient-counts-total","data":_vm.patientcounter.totals,"propwidth":width,"title":"Patienter totalt"}})}}])})],1):_vm._e(),_vm._v(" "),(_vm.patientcounter)?_c('div',{staticClass:"w-full md:w-1/2 lg:w-1/3 my-2"},[_c('ResizableContainer',{staticClass:"mx-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var width = ref.width;
return _c('line-chart',{attrs:{"id":"patient-counts-monthly","data":_vm.patientcounter.monthly,"propwidth":width,"title":"Nya patienter, månadsvis"}})}}])})],1):_vm._e(),_vm._v(" "),(_vm.formcounter)?_c('div',{staticClass:"w-full md:w-1/2 lg:w-1/3 my-2"},[_c('ResizableContainer',{staticClass:"mx-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var width = ref.width;
return _c('line-chart',{attrs:{"id":"form-counts-monthly","data":_vm.formcounter.monthly,"propwidth":width,"title":"Delade formulär, månadsvis"}})}}])})],1):_vm._e(),_vm._v(" "),(_vm.priocounter)?_c('div',{staticClass:"w-full md:w-1/2 lg:w-1/3 my-2"},[_c('ResizableContainer',{staticClass:"mx-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var width = ref.width;
return _c('pie-chart',{attrs:{"id":"prio-counts-monthly","data":_vm.priocounter.totals,"propwidth":width,"title":"Patientprioriteringar"}})}}])})],1):_vm._e(),_vm._v(" "),(_vm.auditlogcounter)?_c('div',{staticClass:"w-full md:w-1/2 lg:w-1/3 my-2"},[_c('ResizableContainer',{staticClass:"mx-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var width = ref.width;
return _c('stats-table',{attrs:{"id":"auditlog-counts-total","data":_vm.auditlogcounter.totals,"propwidth":width,"title":"Antal händelser"}})}}])})],1):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"text-sm pb-2"},[_c('tr',[_c('th',[_vm._v("År-Månad")]),_vm._v(" "),_c('th',[_vm._v("Enhet")]),_vm._v(" "),_c('th',[_vm._v("Antal nya användare under månad")]),_vm._v(" "),_c('th',[_vm._v("Antal användare som tilldelat nybesökspaket under månad")]),_vm._v(" "),_c('th',[_vm._v("Antal användare som tittat på ett formulär som ingår i nybesökspaket under månad")])])])}]

export { render, staticRenderFns }