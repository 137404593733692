<template>
  <div
    id="app"
    class="font-sans select-none h-full"
    :class="conditionalBackground"
  >
    <div
      class="flex-col pb-5"
      :class="conditionalBackground"
      @click="closeSidebar"
    >
      <router-view
        class="container mx-auto min-h-screen text-center"
      ></router-view>
    </div>

    <Sidebar />
    <BasePopUp />

    <portal-target name="modals"></portal-target>
    <portal-target name="notifications"></portal-target>
  </div>
</template>

<script>
import Sidebar from "./components/base/Sidebar.vue";
import { isPast } from "date-fns";

export default {
  name: "App",
  components: {
    Sidebar,
  },
  computed: {
    demo() {
      return DEMOMODE;
    },
    conditionalBackground() {
      return {
        "bg-grey-lighter": !this.demo,
      };
    },
  },
  mounted() {
    if (this.$route.meta.requireAuth) {
      this.checkJwtExpiration();
    }
    if (this.demo) {
      let text = "demo";
      var body = document.getElementsByTagName("body")[0];
      var bg =
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='150px' width='150px'>" +
        "<text transform='translate(20, 75) rotate(-45)' fill='rgb(200,205,211)' font-size='20' >" +
        text +
        '</text></svg>")';
      body.style.backgroundColor = "#f8f8f8";
      body.style.backgroundImage = bg;
    }
  },
  methods: {
    closeSidebar() {
      if (this.$store.getters.isSidebarShown === true) {
        this.$store.dispatch("closeSidebar");
      }
    },
    checkJwtExpiration() {
      return this.$store
        .dispatch("checkJwtExpiration")
        .then((exp) => {
          let d = new Date(exp * 1000);
          if (isPast(d)) {
            this.$store.dispatch("logout");
            this.$router.push("/");
          }
        })
        .catch(() => {});
    },
  },
};
</script>
