<template>
  <!-- START INFO -->
  <div class="container mt-5 mx-auto">
    <form>
      <!-- A1 -->
      <div class="text-2xl bg-red p-5 rounded font-bold text-white">
        Del 1: Symptom på ouppmärksamhet (DSM-5 kriterium A1)
      </div>
      <div class="bg-white p-5 rounded border mt-2">
        <p>
          <span class="font-bold">Instruktioner för intervjun: </span>Symptomen
          i vuxen ålder måste ha funnits i minst 6 månader. Symptomen i
          barndomen gäller för åldern 5-12 år. För att ett symptom skall kunna
          tillskrivas ADHD krävs att det förekommer ständigt och inte bara i
          perioder.
        </p>
      </div>
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl border bg-blue-darker text-white p-5 rounded mt-2 mr-2"
        >
          <h3 class="font-bold">A1</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg  mt-2 border"
        >
          <p>
            Har du ofta svårt att vara tillräckligt uppmärksam på detaljer eller
            gör du slarvfel i ditt arbete eller i samband med andra aktiviteter?
            Och hur var det då du var barn (i skolarbetet eller under andra
            aktiviteter)
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1V.1"
                        v-model="AForm.A1.Adult[1]"
                      />
                      <span class="ml-2">Gör slarvfel</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1V.2"
                        v-model="AForm.A1.Adult[2]"
                      />
                      <span class="ml-2"
                        >Arbetar långsamt för att undvika fel</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1V.3"
                        v-model="AForm.A1.Adult[3]"
                      />
                      <span class="ml-2">Arbetsresultatet blir felaktigt</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1V.4"
                        v-model="AForm.A1.Adult[4]"
                      />
                      <span class="ml-2"
                        >Läser inte instruktioner noggrant</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1V.5"
                        v-model="AForm.A1.Adult[5]"
                      />
                      <span class="ml-2">Förbiser eller missar detaljer</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1V.6"
                        v-model="AForm.A1.Adult[6]"
                      />
                      <span class="ml-2">Är dålig på detaljarbete</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1V.7"
                        v-model="AForm.A1.Adult[7]"
                      />
                      <span class="ml-2"
                        >Behöver för mycket tid för att avsluta detaljerade
                        uppgifter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1V.8"
                        v-model="AForm.A1.Adult[8]"
                      />
                      <span class="ml-2">Fastnar i detaljer</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1V.9"
                        v-model="AForm.A1.Adult[9]"
                      />
                      <span class="ml-2"
                        >Arbetar för snabbt och gör fel p.g.a det</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A1.Adult.Annat"
                        @click="AForm.A1.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A1.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A1V.Message"
                      v-model="AForm.A1.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <span class="ml-2">Symptom finns</span>
                    <input
                      type="radio"
                      id="A1VRadio1"
                      class="form-checkbox"
                      style="margin-left: 0.3em"
                      name="A1VRadio"
                      :value="true"
                      v-model="AForm.A1.Adult.Symptom"
                    />
                    <label for="A1VRadio1" class="ml-1">Ja /</label>
                    <input
                      type="radio"
                      id="A1VRadio2"
                      class="form-checkbox ml-1"
                      name="A1VRadio"
                      :value="false"
                      v-model="AForm.A1.Adult.Symptom"
                    />
                    <label for="A1VRadio2" class="ml-1">Nej</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1B.1"
                        v-model="AForm.A1.Child[1]"
                      />
                      <span class="ml-2">Gör slarvfel i skolarbetet</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1B.2"
                        v-model="AForm.A1.Child[2]"
                      />
                      <span class="ml-2"
                        >Gör fel genom att inte läsa igenom frågor
                        ordentligt</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1B.3"
                        v-model="AForm.A1.Child[3]"
                      />
                      <span class="ml-2">Förbiser eller missar detaljer</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1B.4"
                        v-model="AForm.A1.Child[4]"
                      />
                      <span class="ml-2">Arbetsresultatet blir felaktigt</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1B.5"
                        v-model="AForm.A1.Child[5]"
                      />
                      <span class="ml-2"
                        >Läser inte igenom frågor ordentligt som därför blir
                        obesvarade</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1B.6"
                        v-model="AForm.A1.Child[6]"
                      />
                      <span class="ml-2"
                        >Besvara inte frågor på baksidan på ett prov</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1B.7"
                        v-model="AForm.A1.Child[7]"
                      />
                      <span class="ml-2"
                        >Kommentarer från andra om slarvigt arbete</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1B.8"
                        v-model="AForm.A1.Child[8]"
                      />
                      <span class="ml-2"
                        >Kontrollerar inte svaren på läxor</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A1B.9"
                        v-model="AForm.A1.Child[9]"
                      />
                      <span class="ml-2"
                        >Behöver för mycket tid för att avsluta detaljerade
                        uppgifter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A1.Child.Annat"
                        @click="AForm.A1.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A1.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A1B.Message"
                      v-model="AForm.A1.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A1BRadio"
                        id="A1BRadio1"
                        :value="true"
                        v-model="AForm.A1.Child.Symptom"
                      />
                      <label for="A1BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="A1BRadio"
                        id="A1BRadio2"
                        :value="false"
                        v-model="AForm.A1.Child.Symptom"
                      />
                      <label for="A1BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- A2 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl border bg-blue-darker text-white p-5 rounded mt-2 mr-2"
        >
          <h3 class="font-bold">A2</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg  border"
          style="margin-top: 0.5em"
        >
          <p>
            Har du ofta svårt att bibehålla uppmärksamheten under uppgifter? Och
            hur var det då du var barn (under lek)?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2V.1"
                        v-model="AForm.A2.Adult[1]"
                      />
                      <span class="ml-2"
                        >Kan inte bibehålla uppmärksamheten längre tid under
                        genomförandet av uppgifter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2V.2"
                        v-model="AForm.A2.Adult[2]"
                      />
                      <span class="ml-2"
                        >Distraheras snabbt av egna tankar eller
                        associationer</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2V.3"
                        v-model="AForm.A2.Adult[3]"
                      />
                      <span class="ml-2"
                        >Lättdistraherad av ovidkommande tankar</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2V.4"
                        v-model="AForm.A2.Adult[4]"
                      />
                      <span class="ml-2"
                        >Svårigheter att vara fokuserad under föreläsningar och
                        / eller samtal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2V.5"
                        v-model="AForm.A2.Adult[5]"
                      />
                      <span class="ml-2"
                        >Har svårt att se klart på en film till slutet eller
                        läsa en bok</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2V.6"
                        v-model="AForm.A2.Adult[6]"
                      />
                      <span class="ml-2">Tröttnar snabbt på saker</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2V.7"
                        v-model="AForm.A2.Adult[7]"
                      />
                      <span class="ml-2"
                        >Ställer frågor om ämnen som redan diskuterats</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A2.Adult.Annat"
                        @click="AForm.A2.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A2.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A2V.Message"
                      v-model="AForm.A2.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="A2VRadio"
                        id="A2VRadio1"
                        :value="true"
                        v-model="AForm.A2.Adult.Symptom"
                      />
                      <label for="A2VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A2VRadio"
                        id="A2VRadio2"
                        :value="false"
                        v-model="AForm.A2.Adult.Symptom"
                      />
                      <label for="A2VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2B.1"
                        v-model="AForm.A2.Child[1]"
                      />
                      <span class="ml-2"
                        >Har svårt att bibehålla uppmärksamheten på
                        skolarbetet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2B.2"
                        v-model="AForm.A2.Child[2]"
                      />
                      <span class="ml-2"
                        >Har svårt att bibehålla uppmärksamheten under lek</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2B.3"
                        v-model="AForm.A2.Child[3]"
                      />
                      <span class="ml-2"
                        >Svårigheter att vara fokuserad under lektioner och /
                        eller samtal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2B.4"
                        v-model="AForm.A2.Child[4]"
                      />
                      <span class="ml-2">Lättdistraherad</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2B.5"
                        v-model="AForm.A2.Child[5]"
                      />
                      <span class="ml-2">Koncentrationssvårigheter*</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2B.6"
                        v-model="AForm.A2.Child[6]"
                      />
                      <span class="ml-2"
                        >Behöver struktur för att inte distraheras</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A2B.7"
                        v-model="AForm.A2.Child[7]"
                      />
                      <span class="ml-2">Tröttnar snabbt på aktiviteter*</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A2.Child.Annat"
                        @click="AForm.A2.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A2.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A2B.Message"
                      v-model="AForm.A2.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A2BRadio"
                        id="A2BRadio1"
                        :value="true"
                        v-model="AForm.A2.Child.Symptom"
                      />
                      <label for="A2BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A2BRadio"
                        id="A2BRadio2"
                        :value="false"
                        v-model="AForm.A2.Child.Symptom"
                      />
                      <label for="A2BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- A3 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl border bg-blue-darker text-white p-5 rounded mt-2 mr-2"
        >
          <h3 class="font-bold">A3</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg  border"
          style="margin-top: 0.5em"
        >
          <p>
            Verkar det ofta som om du inte lyssnar när någon talar direkt till
            dig? Och hur var det då du var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3V.1"
                        v-model="AForm.A3.Adult[1]"
                      />
                      <span class="ml-2"
                        >Dagdrömmer eller verkar frånvarande</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3V.2"
                        v-model="AForm.A3.Adult[2]"
                      />
                      <span class="ml-2"
                        >Har svårt att koncentrera dig på ett samtal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3V.4"
                        v-model="AForm.A3.Adult[4]"
                      />
                      <span class="ml-2"
                        >Vet inte vad ett samtal handlade om efter det
                        avslutats</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3V.5"
                        v-model="AForm.A3.Adult[5]"
                      />
                      <span class="ml-2">Byter ofta ämne under ett samtal</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3V.6"
                        v-model="AForm.A3.Adult[6]"
                      />
                      <span class="ml-2"
                        >Andra säger att dina tankar är på annat håll</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3V.7"
                        v-model="AForm.A3.Adult[7]"
                      />
                      <span class="ml-2"
                        >Tankarna är någon annanstans även i frånvaro av
                        uppenbar distraktion</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A3.Adult.Annat"
                        @click="AForm.A3.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A3.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A3V.Message"
                      v-model="AForm.A3.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="A3VRadio"
                        id="A3VRadio1"
                        :value="true"
                        v-model="AForm.A3.Adult.Symptom"
                      />
                      <label for="A3VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A3VRadio"
                        id="A3VRadio2"
                        :value="false"
                        v-model="AForm.A3.Adult.Symptom"
                      />
                      <label for="A3VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3B.1"
                        v-model="AForm.A3.Child[1]"
                      />
                      <span class="ml-2"
                        >Vet inte vad föräldrar / lärare har sagt</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3B.2"
                        v-model="AForm.A3.Child[2]"
                      />
                      <span class="ml-2"
                        >Dagdrömmer eller verkar frånvarande</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3B.3"
                        v-model="AForm.A3.Child[3]"
                      />
                      <span class="ml-2"
                        >Lyssnar först vid ögonkontakt eller höjd röst</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3B.4"
                        v-model="AForm.A3.Child[4]"
                      />
                      <span class="ml-2"
                        >Tankarna är någon annanstans även i frånvaro av
                        uppenbar distraktion</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3B.5"
                        v-model="AForm.A3.Child[5]"
                      />
                      <span class="ml-2"
                        >Måste ofta tilltalas flera gånger</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A3B.6"
                        v-model="AForm.A3.Child[6]"
                      />
                      <span class="ml-2">Frågor behöver upprepas</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A3.Child.Annat"
                        @click="AForm.A3.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A3.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A3B.Message"
                      v-model="AForm.A3.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A3BRadio"
                        id="A3BRadio1"
                        :value="true"
                        v-model="AForm.A3.Child.Symptom"
                      />
                      <label for="A3BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A3BRadio"
                        id="A3BRadio2"
                        :value="false"
                        v-model="AForm.A3.Child.Symptom"
                      />
                      <label for="A3BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- A4 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl border bg-blue-darker text-white p-5 rounded mt-2 mr-2"
        >
          <h3 class="font-bold">A4</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg  mt-2 border"
        >
          <p>
            Har du svårigheter att fullfölja instruktioner och misslyckas ofta
            med att slutföra hemsysslor eller uppgifter i arbetet? Och hur var
            det då du var barn (i skolarbetet)?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4V.1"
                        v-model="AForm.A4.Adult[1]"
                      />
                      <span class="ml-2"
                        >Göra flera saker samtidigt utan att avsluta dem</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4V.2"
                        v-model="AForm.A4.Adult[2]"
                      />
                      <span class="ml-2"
                        >Påbörjar uppgifter men tappar snabbt fokus och växlar
                        lätt spår</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4V.3"
                        v-model="AForm.A4.Adult[3]"
                      />
                      <span class="ml-2"
                        >Behöver en deadline för att avsluta uppgifter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4V.4"
                        v-model="AForm.A4.Adult[4]"
                      />
                      <span class="ml-2"
                        >Har svårt att avsluta pappersarbete</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4V.5"
                        v-model="AForm.A4.Adult[5]"
                      />
                      <span class="ml-2"
                        >Har svårt att följa instruktioner i en manual</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A4.Adult.Annat"
                        @click="AForm.A4.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A4.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A4V.Message"
                      v-model="AForm.A4.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A4VRadio"
                        id="A4VRadio1"
                        :value="true"
                        v-model="AForm.A4.Adult.Symptom"
                      />
                      <label for="A4VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A4VRadio"
                        id="A4VRadio2"
                        :value="false"
                        v-model="AForm.A4.Adult.Symptom"
                      />
                      <label for="A4VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4B.1"
                        v-model="AForm.A4.Child[1]"
                      />
                      <span class="ml-2"
                        >Har svårt att följa instruktioner</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4B.2"
                        v-model="AForm.A4.Child[2]"
                      />
                      <span class="ml-2"
                        >Har svårt att följa instruktioner i flera led</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4B.3"
                        v-model="AForm.A4.Child[3]"
                      />
                      <span class="ml-2"
                        >Påbörjar uppgifter men tappar snabbt fokus och växlar
                        lätt spår</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4B.4"
                        v-model="AForm.A4.Child[4]"
                      />
                      <span class="ml-2">Avslutar inte uppgifter</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4B.5"
                        v-model="AForm.A4.Child[5]"
                      />
                      <span class="ml-2"
                        >Gör inte klart läxorna eller lämnar inte in dem</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A4B.6"
                        v-model="AForm.A4.Child[6]"
                      />
                      <span class="ml-2"
                        >Behövs mycket struktur för att kunna avsluta
                        uppgifter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A4.Child.Annat"
                        @click="AForm.A4.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A4.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A4B.Message"
                      v-model="AForm.A4.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A4BRadio"
                        id="A4BRadio1"
                        :value="true"
                        v-model="AForm.A4.Child.Symptom"
                      />
                      <label for="A4BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A4BRadio"
                        id="A4BRadio2"
                        :value="false"
                        v-model="AForm.A4.Child.Symptom"
                      />
                      <label for="A4BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- A5 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl border bg-blue-darker text-white p-5 rounded mt-2 mr-2"
        >
          <h3 class="font-bold">A5</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg  mt-2 border"
        >
          <p>
            Har du ofta problem med att organisera uppgifter och aktiviteter?
            Och hur var det då du var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.1"
                        v-model="AForm.A5.Adult[1]"
                      />
                      <span class="ml-2"
                        >Har svårt att planera vardagliga aktiviteter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.2"
                        v-model="AForm.A5.Adult[2]"
                      />
                      <span class="ml-2"
                        >Svårigheter att utföra uppgifter med flera
                        efterföljande moment</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.3"
                        v-model="AForm.A5.Adult[3]"
                      />
                      <span class="ml-2"
                        >Rörigt i hemmet och / eller på arbetsplatsen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.4"
                        v-model="AForm.A5.Adult[4]"
                      />
                      <span class="ml-2"
                        >Svårigheter att hålla ordning på saker och
                        tillhörigheter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.5"
                        v-model="AForm.A5.Adult[5]"
                      />
                      <span class="ml-2"
                        >Arbetar rörigt och ostrukturerat
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.6"
                        v-model="AForm.A5.Adult[6]"
                      />
                      <span class="ml-2"
                        >Planerar för mycket eller planerar inte effektivt
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.7"
                        v-model="AForm.A5.Adult[7]"
                      />
                      <span class="ml-2">Dubbelbokar ofta aktiviteter </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.8"
                        v-model="AForm.A5.Adult[8]"
                      />
                      <span class="ml-2">Kommer för sent </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.9"
                        v-model="AForm.A5.Adult[9]"
                      />
                      <span class="ml-2">Svårigheter att hålla deadlines </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.10"
                        v-model="AForm.A5.Adult[10]"
                      />
                      <span class="ml-2"
                        >Kan inte konsekvent använda en kalender eller
                        dagsplanering
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.11"
                        v-model="AForm.A5.Adult[11]"
                      />
                      <span class="ml-2"
                        >Är oflexibel genom att vara bunden till scheman
                      </span>
                    </label>
                  </div>

                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.12"
                        v-model="AForm.A5.Adult[12]"
                      />
                      <span class="ml-2"
                        >Dålig tidsuppfattning och tidshantering
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.13"
                        v-model="AForm.A5.Adult[13]"
                      />
                      <span class="ml-2"
                        >Gör upp scheman som sedan inte används
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5V.14"
                        v-model="AForm.A5.Adult[14]"
                      />
                      <span class="ml-2"
                        >Behöver andra för att få struktur
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A5.Adult.Annat"
                        @click="AForm.A5.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A5.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A5V.Message"
                      v-model="AForm.A5.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A5VRadio"
                        id="A5VRadio1"
                        :value="true"
                        v-model="AForm.A5.Adult.Symptom"
                      />
                      <label for="A5VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A5VRadio"
                        id="A5VRadio2"
                        :value="false"
                        v-model="AForm.A5.Adult.Symptom"
                      />
                      <label for="A5VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5B.1"
                        v-model="AForm.A5.Child[1]"
                      />
                      <span class="ml-2">Har svårt för att bli klar i tid</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5B.2"
                        v-model="AForm.A5.Child[2]"
                      />
                      <span class="ml-2"
                        >Rörigt rum/skrivbord och / eller arbete</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5B.3"
                        v-model="AForm.A5.Child[3]"
                      />
                      <span class="ml-2"
                        >Svårigheter att hålla ordning på saker och
                        tillhörigheter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5B.4"
                        v-model="AForm.A5.Child[4]"
                      />
                      <span class="ml-2">Har svårt att leka ensam</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5B.5"
                        v-model="AForm.A5.Child[5]"
                      />
                      <span class="ml-2"
                        >Har svårt att planera uppgifter och läxor</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A5B.6"
                        v-model="AForm.A5.Child[6]"
                      />
                      <span class="ml-2">Svårigheter att hålla deadlines</span>
                    </label>
                  </div>
                  <div>
                    <div>
                      <label class="inline-flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          name="A5B.7"
                          v-model="AForm.A5.Child[7]"
                        />
                        <span class="ml-2">Gör saker huller om buller </span>
                      </label>
                    </div>
                    <div>
                      <label class="inline-flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          name="A5B.8"
                          v-model="AForm.A5.Child[8]"
                        />
                        <span class="ml-2">Kommer för sent </span>
                      </label>
                    </div>
                    <div>
                      <label class="inline-flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          name="A5B.9"
                          v-model="AForm.A5.Child[9]"
                        />
                        <span class="ml-2">Dålig tidsuppfattning </span>
                      </label>
                    </div>
                    <div>
                      <label class="inline-flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          name="A5B.10"
                          v-model="AForm.A5.Child[10]"
                        />
                        <span class="ml-2"
                          >Har svårt att hålla sig själv sysselsatt
                        </span>
                      </label>
                    </div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A5.Child.Annat"
                        @click="AForm.A5.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A5.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A5B.Message"
                      v-model="AForm.A5.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A5BRadio"
                        id="A5BRadio1"
                        :value="true"
                        v-model="AForm.A5.Child.Symptom"
                      />
                      <label for="A5BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A5BRadio"
                        id="A5BRadio2"
                        :value="false"
                        v-model="AForm.A5.Child.Symptom"
                      />
                      <label for="A5BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- A6 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl border bg-blue-darker text-white p-5 rounded mt-2 mr-2"
        >
          <h3 class="font-bold">A6</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg  mt-2 border"
        >
          <p>
            Undviker du (eller ogillar du, eller är motvillig till att delta i)
            uppgifter som kräver långvarig mental ansträngning? Och hur var det
            då du var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6V.1"
                        v-model="AForm.A6.Adult[1]"
                      />
                      <span class="ml-2"
                        >Gör först det som är lättast eller roligast</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6V.2"
                        v-model="AForm.A6.Adult[2]"
                      />
                      <span class="ml-2"
                        >Skjuter ofta upp tråkiga eller svåra uppgifter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6V.3"
                        v-model="AForm.A6.Adult[3]"
                      />
                      <span class="ml-2"
                        >Skjuter upp uppgifter så att de inte blir färdiga i
                        tid</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6V.4"
                        v-model="AForm.A6.Adult[4]"
                      />
                      <span class="ml-2"
                        >Undviker monotont arbete som t.ex. administriva
                        uppgifter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6V.5"
                        v-model="AForm.A6.Adult[5]"
                      />
                      <span class="ml-2"
                        >Undviker att utarbeta rapporter, fylla i blanketter
                        eller läsa igenom längre texter
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6V.6"
                        v-model="AForm.A6.Adult[6]"
                      />
                      <span class="ml-2"
                        >Tycker inte om att läsa då det är mentalt ansträngande
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6V.7"
                        v-model="AForm.A6.Adult[7]"
                      />
                      <span class="ml-2"
                        >Undviker uppgifter som kräver mycket
                        koncentration</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A6.Adult.Annat"
                        @click="AForm.A6.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A6.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A6V.Message"
                      v-model="AForm.A6.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A6VRadio"
                        id="A6VRadio1"
                        :value="true"
                        v-model="AForm.A6.Adult.Symptom"
                      />
                      <label for="A6VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A6VRadio"
                        id="A6VRadio2"
                        :value="false"
                        v-model="AForm.A6.Adult.Symptom"
                      />
                      <label for="A6VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6B.1"
                        v-model="AForm.A6.Child[1]"
                      />
                      <span class="ml-2"
                        >Undviker läxor eller tycker mycket illa om dem</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6B.2"
                        v-model="AForm.A6.Child[2]"
                      />
                      <span class="ml-2"
                        >Läser få böcker eller tycker inte om att läsa då det är
                        mentalt ansträngande</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6B.3"
                        v-model="AForm.A6.Child[3]"
                      />
                      <span class="ml-2"
                        >Undviker uppgiftersom kräver mycket koncentration</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6B.4"
                        v-model="AForm.A6.Child[4]"
                      />
                      <span class="ml-2"
                        >Tycker illa om skolämnen som kräver mycket
                        koncentration</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A6B.5"
                        v-model="AForm.A6.Child[5]"
                      />
                      <span class="ml-2"
                        >Skjuter ofta upp tråkiga eller svåra uppgifter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A6.Child.Annat"
                        @click="AForm.A6.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A6.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A6B.Message"
                      v-model="AForm.A6.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A6BRadio"
                        id="A6BRadio1"
                        :value="true"
                        v-model="AForm.A6.Child.Symptom"
                      />
                      <label for="A6BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A6BRadio"
                        id="A6BRadio2"
                        :value="false"
                        v-model="AForm.A6.Child.Symptom"
                      />
                      <label for="A6BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- A7 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl border bg-blue-darker text-white p-5 rounded mt-2 mr-2"
        >
          <h3 class="font-bold">A7</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg  mt-2 border"
        >
          <p>
            Tappar du ofta bort saker som är nödvändiga för uppgifter eller
            aktiviteter? Och hur var det då du var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7V.1"
                        v-model="AForm.A7.Adult[1]"
                      />
                      <span class="ml-2"
                        >Förlägger verktyg, pappersarbete, glasögon,
                        mobiltelefoner, plånbok, nycklar eller kalender.</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7V.2"
                        v-model="AForm.A7.Adult[2]"
                      />
                      <span class="ml-2">Glömmer ofta kvar saker</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7V.3"
                        v-model="AForm.A7.Adult[3]"
                      />
                      <span class="ml-2">Tappar bort arbetsdokument</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7V.4"
                        v-model="AForm.A7.Adult[4]"
                      />
                      <span class="ml-2"
                        >Förlorar mycket tid på att leta efter saker</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7V.5"
                        v-model="AForm.A7.Adult[5]"
                      />
                      <span class="ml-2"
                        >Råkar i panik om andra flyttar på saker
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7V.6"
                        v-model="AForm.A7.Adult[6]"
                      />
                      <span class="ml-2"
                        >Lägger undan saker på fel ställen
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7V.8"
                        v-model="AForm.A7.Adult[8]"
                      />
                      <span class="ml-2"
                        >Tappar bort anteckningar, listor eller telefonnummer
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A7.Adult.Annat"
                        @click="AForm.A7.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A7.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A7V.Message"
                      v-model="AForm.A7.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A7VRadio"
                        id="A7VRadio1"
                        :value="true"
                        v-model="AForm.A7.Adult.Symptom"
                      />
                      <label for="A7VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A7VRadio"
                        id="A7VRadio2"
                        :value="false"
                        v-model="AForm.A7.Adult.Symptom"
                      />
                      <label for="A7VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7B.1"
                        v-model="AForm.A7.Child[1]"
                      />
                      <span class="ml-2">Har svårt för att bli klar i tid</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7B.2"
                        v-model="AForm.A7.Child[2]"
                      />
                      <span class="ml-2"
                        >Tappar bort skolmateriel, pennor, böcker eller andra
                        föremål</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7B.3"
                        v-model="AForm.A7.Child[3]"
                      />
                      <span class="ml-2"
                        >Förlägger leksaker, kläder eller läxor</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7B.4"
                        v-model="AForm.A7.Child[4]"
                      />
                      <span class="ml-2"
                        >Ägnar mycket tid åt att leta efter saker</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7B.5"
                        v-model="AForm.A7.Child[5]"
                      />
                      <span class="ml-2"
                        >Råkar i panik om andra flyttar på saker
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A7B.6"
                        v-model="AForm.A7.Child[6]"
                      />
                      <span class="ml-2"
                        >Får kommentarer av föräldrar och / eller lärare om
                        borttappade saker</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A7.Child.Annat"
                        @click="AForm.A7.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A7.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A7B.Message"
                      v-model="AForm.A7.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A7BRadio"
                        id="A7BRadio1"
                        :value="true"
                        v-model="AForm.A7.Child.Symptom"
                      />
                      <label for="A7BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A7BRadio"
                        id="A7BRadio2"
                        :value="false"
                        v-model="AForm.A7.Child.Symptom"
                      />
                      <label for="A7BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- A8 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl border bg-blue-darker text-white p-5 rounded mt-2 mr-2"
        >
          <h3 class="font-bold">A8</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg  mt-2 border"
        >
          <p>
            Blir du ofta lätt distraherad av yttre störningar? Och hur var det
            då du var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A8V.1"
                        v-model="AForm.A8.Adult[1]"
                      />
                      <span class="ml-2"
                        >Har svårt att utestänga störningar från
                        omgivningen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A8V.2"
                        v-model="AForm.A8.Adult[2]"
                      />
                      <span class="ml-2"
                        >Har svårt att ta upp tråden igen efter att ha blivit
                        distraherad</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A8V.3"
                        v-model="AForm.A8.Adult[3]"
                      />
                      <span class="ml-2"
                        >Distraheras lätt av ljud eller händelser</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A8V.4"
                        v-model="AForm.A8.Adult[4]"
                      />
                      <span class="ml-2"
                        >Distraheras lätt av andras samtal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A8V.5"
                        v-model="AForm.A8.Adult[5]"
                      />
                      <span class="ml-2"
                        >Har svårt att filtrera och / eller välja ut information
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A8.Adult.Annat"
                        @click="AForm.A8.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A8.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A8V.Message"
                      v-model="AForm.A8.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A8VRadio"
                        id="A8VRadio1"
                        :value="true"
                        v-model="AForm.A8.Adult.Symptom"
                      />
                      <label for="A8VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A8VRadio"
                        id="A8VRadio2"
                        :value="false"
                        v-model="AForm.A8.Adult.Symptom"
                      />
                      <label for="A8VRadio2" class="ml-1">Nej</label>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A8B.1"
                        v-model="AForm.A8.Child[1]"
                      />
                      <span class="ml-2"
                        >Tittar ofta ut genom fönstret i klassrummet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A8B.2"
                        v-model="AForm.A8.Child[2]"
                      />
                      <span class="ml-2"
                        >Distraheras lätt av ljud eller händelser</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A8B.3"
                        v-model="AForm.A8.Child[3]"
                      />
                      <span class="ml-2"
                        >Har svårt att hitta tråden igen efter att ha blivit
                        distraherad</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A8.Child.Annat"
                        @click="AForm.A8.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A8.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A8B.Message"
                      v-model="AForm.A8.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A8BRadio"
                        id="A8BRadio1"
                        :value="true"
                        v-model="AForm.A8.Child.Symptom"
                      />
                      <label for="A8BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A8BRadio"
                        id="A8BRadio2"
                        :value="false"
                        v-model="AForm.A8.Child.Symptom"
                      />
                      <label for="A8BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- A9 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl border bg-blue-darker text-white p-5 rounded mt-2 mr-2"
        >
          <h3 class="font-bold">A9</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg  mt-2 border"
        >
          <p>
            Är du ofta glömsk i vardagliga aktiviteter? Och hur var det då du
            var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9V.1"
                        v-model="AForm.A9.Adult[1]"
                      />
                      <span class="ml-2"
                        >Glömmer avtalade möten eller andra åtaganden</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9V.2"
                        v-model="AForm.A9.Adult[2]"
                      />
                      <span class="ml-2">Glömmer nycklar, kalender etc</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9V.3"
                        v-model="AForm.A9.Adult[3]"
                      />
                      <span class="ml-2"
                        >Måste ofta påminnas om avtalade möten</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9V.4"
                        v-model="AForm.A9.Adult[4]"
                      />
                      <span class="ml-2"
                        >Glömmer betala räkningar eller ringa tillbaka</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9V.5"
                        v-model="AForm.A9.Adult[5]"
                      />
                      <span class="ml-2"
                        >Måste återvända hem och hämta glömda saker
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9V.6"
                        v-model="AForm.A9.Adult[6]"
                      />
                      <span class="ml-2"
                        >Använder ständigt listor för att inte glömma saker
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9V.7"
                        v-model="AForm.A9.Adult[7]"
                      />
                      <span class="ml-2"
                        >Glömmer att skriva upp eller titta i kalendern
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9V.8"
                        v-model="AForm.A9.Adult[8]"
                      />
                      <span class="ml-2"
                        >Glömmer att utföra sysslor eller göra ärenden</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A9.Adult.Annat"
                        @click="AForm.A9.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A9.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A9V.Message"
                      v-model="AForm.A9.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A9VRadio"
                        id="A9VRadio1"
                        :value="true"
                        v-model="AForm.A9.Adult.Symptom"
                      />
                      <label for="A9VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A9VRadio"
                        id="A9VRadio2"
                        :value="false"
                        v-model="AForm.A9.Adult.Symptom"
                      />
                      <label for="A9VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9B.1"
                        v-model="AForm.A9.Child[1]"
                      />
                      <span class="ml-2"
                        >Glömmer avtalade möten eller instruktioner</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9B.2"
                        v-model="AForm.A9.Child[2]"
                      />
                      <span class="ml-2"
                        >Glömmer att utföra sysslor eller göra ärenden</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9B.3"
                        v-model="AForm.A9.Child[3]"
                      />
                      <span class="ml-2">Behöver ofta påminnas om saker</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9B.4"
                        v-model="AForm.A9.Child[4]"
                      />
                      <span class="ml-2"
                        >Har glömt vad som skall göras halvvägs igenom en
                        uppgift</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9B.5"
                        v-model="AForm.A9.Child[5]"
                      />
                      <span class="ml-2"
                        >Glömmer att ta med saker till skolan</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="A9B.6"
                        v-model="AForm.A9.Child[6]"
                      />
                      <span class="ml-2"
                        >Glömmer kvar saker i skolan eller hos kompisar</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="AForm.A9.Child.Annat"
                        @click="AForm.A9.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="AForm.A9.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="A9B.Message"
                      v-model="AForm.A9.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A9BRadio"
                        id="A9BRadio1"
                        :value="true"
                        v-model="AForm.A9.Child.Symptom"
                      />
                      <label for="A9BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="A9BRadio"
                        id="A9BRadio2"
                        :value="false"
                        v-model="AForm.A9.Child.Symptom"
                      />
                      <label for="A9BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <textarea
            name="AText"
            id="AText"
            class="w-full h-full border"
            style="min-height: 200px;"
            v-model="AText"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      AForm: {
        A1: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        A2: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        A3: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        A4: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        A5: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        A6: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        A7: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        A8: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        A9: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        Summa: {
          Adult: { Summa: this.AVSumma },
          Child: { Summa: this.ABSumma },
        },
      },
    };
  },
  props: {
    sAForm: Object,
  },
  watch: {
    AForm: {
      handler() {
        this.$store.dispatch("setAForm", this.AForm);
        this.AForm.Summa.Adult.Summa = this.AVSumma;
        this.AForm.Summa.Child.Summa = this.ABSumma;
      },
      deep: true,
    },
  },
  computed: {
    AVSumma() {
      let AVSumma = 0;
      AVSumma += this.AForm.A1.Adult.Symptom == true ? 1 : 0;
      AVSumma += this.AForm.A2.Adult.Symptom == true ? 1 : 0;
      AVSumma += this.AForm.A3.Adult.Symptom == true ? 1 : 0;
      AVSumma += this.AForm.A4.Adult.Symptom == true ? 1 : 0;
      AVSumma += this.AForm.A5.Adult.Symptom == true ? 1 : 0;
      AVSumma += this.AForm.A6.Adult.Symptom == true ? 1 : 0;
      AVSumma += this.AForm.A7.Adult.Symptom == true ? 1 : 0;
      AVSumma += this.AForm.A8.Adult.Symptom == true ? 1 : 0;
      AVSumma += this.AForm.A9.Adult.Symptom == true ? 1 : 0;
      return AVSumma;
    },
    ABSumma() {
      let ABSumma = 0;
      ABSumma += this.AForm.A1.Child.Symptom == true ? 1 : 0;
      ABSumma += this.AForm.A2.Child.Symptom == true ? 1 : 0;
      ABSumma += this.AForm.A3.Child.Symptom == true ? 1 : 0;
      ABSumma += this.AForm.A4.Child.Symptom == true ? 1 : 0;
      ABSumma += this.AForm.A5.Child.Symptom == true ? 1 : 0;
      ABSumma += this.AForm.A6.Child.Symptom == true ? 1 : 0;
      ABSumma += this.AForm.A7.Child.Symptom == true ? 1 : 0;
      ABSumma += this.AForm.A8.Child.Symptom == true ? 1 : 0;
      ABSumma += this.AForm.A9.Child.Symptom == true ? 1 : 0;
      return ABSumma;
    },
    AText() {
      let text = "";

      for (let x = 1; x < 10; x++) {
        if (this.AForm["A" + x].Adult.Annat || this.AForm["A" + x].Child.Annat)
          text += "A" + x + ":\r\n";
        if (this.AForm["A" + x].Adult.Annat === true)
          text += this.AForm["A" + x].Adult.Message + "\r\n";
        if (this.AForm["A" + x].Child.Annat === true)
          text += this.AForm["A" + x].Child.Message + "\r\n";
      }

      return text;
    },
  },
  created() {
    if (this.sAForm) this.AForm = this.sAForm;
    this.$store.dispatch("setAForm", this.AForm);
  },
};
</script>
