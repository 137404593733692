<template>
    <div>
        <button class="w-3/4 max-w-xs rounded-lg px-4 py-4 text-center text-lg tracking-wide" :class="setStyle">
            <slot></slot>
        </button>
    </div>
</template>

<script>
export default {
    name: "BaseButton",
    props: ['color'],
    computed: {
        setStyle () {
            switch (this.color) {
                case 'white':
                    return 'bg-white text-grey-darkest border border-grey-darkest';
                case 'red':
                    return 'bg-red text-white';
                default:
                    return 'bg-green text-white';
            }
        }
    }
}
</script>

