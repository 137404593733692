<template>
    <div>
        <input id="id" @blur="blur"
            :placeholder="title"
            :name="id"
            class="rounded-lg border border-grey-light px-8 py-2 text-center text-xl"
            />
        }
    </div>
</template>

<script>
export default {
    name: "BaseInput",
    props: {
        formId: String,
        id: Number,
        title: String
    },
    methods: {
        blur (evt) {
            let value = evt.target.value

            this.$store.dispatch('answer', {
                formId: this.formId,
                id: this.id,
                value: value,
                label: ''
            })
        }
    }
}
</script>
