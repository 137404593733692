import "es6-promise/auto";
import "polyfill-array-includes";
import "../fa.config";

import "./assets/style/app.css";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import axios from "axios";
import messages from "./assets/i18n/messages.js";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import PortalVue from "portal-vue";
import VModal from "vue-js-modal";
import VuejsPaginate from "vuejs-paginate";

Vue.use(PortalVue);
Vue.use(VModal);
Vue.component('paginate', VuejsPaginate);

Vue.prototype.$http = axios;

const token = localStorage.getItem("user-token");

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer: ${token}`;
}

Vue.use(VueI18n);
const locale = localStorage.getItem("user-locale") || "sv";

const i18n = new VueI18n({
  locale: locale,
  messages,
});

const requireComponent = require.context(
  "./components/base/",
  true, //subfolders
  /Base[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.config.productionTip = false;

Vue.prototype.$http.interceptors.response.use(
  response => {
    // intercept the global error
    return response;
  },
  function(error) {
    let originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // if the error is 401 and hasent already been retried
      store.dispatch('logout');
      router.push("/login");
    }
    if (error.response.status === 404 && !originalRequest._retry) {
      originalRequest._retry = true;
      window.location.href = "/";
      return;
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
