import { apiPostRequest, apiGetRequest } from "./api";

export default {
    state: {
        DIVA5: {
            Info: null,
            AForm: [],
            HIForm: [],
            DForm: [],
            Bedomning: [],
        },
        userForm: null
    },
    mutations: {
        setAForm(state, AForm) {
            state.DIVA5.AForm = AForm;
        },
        setHIForm(state, HIForm) {
            state.DIVA5.HIForm = HIForm;
        },
        setDForm(state, DForm) {
            state.DIVA5.DForm = DForm;
        },
        setBedomning(state, Bedomning) {
            state.DIVA5.Bedomning = Bedomning;
        },
        setDiva5Info(state, info) {
            state.DIVA5.Info = info;
        },
        setUserForm(state, data) {
            state.userForm = data;
        }
    },
    actions: {
        setAForm({ commit }, data) {
            commit("setAForm", data)
        },
        setHIForm({ commit }, data) {
            commit("setHIForm", data)
        },
        setDForm({ commit }, data) {
            commit("setDForm", data)
        },
        setBedomning({ commit }, data) {
            commit("setBedomning", data)
        },
        setDiva5Info({ commit }, info) {
            commit("setDiva5Info", info);
        },
        async saveForm({ commit }, form) { // eslint-disable-line
            await apiPostRequest("user/form", form).then((response) => {
                return response;
            });
        },
        async loadUserForm({ commit }, id) {
            await apiGetRequest("user/form/" + id).then((response) => {
                commit("setUserForm", response);
                return response;
            });
        }
    },
    getters: {
        getDiva5: state => state.DIVA5,
        getUserForm: state => state.userForm
    },

}
