<template>
        <div id="monthly-chart" style="width: 200px; height: 200px"></div>
</template>

<script>
import * as d3 from 'd3'

export default {
    name: 'MonthlyChart',
    props: {
        data: {
            type: Object,
            default () {
                return {}
            },
        },
        size: {
            type: Number,
            default: 200,
        }
    },
    data () {
        return {
            // width: this.size ? this.size : 200,
            chart: {
                svg: null,
                g: null,
                color: null,
                pie: null,
                width: null,
                height: null,
                radius: null,
                path: null
            },
        }
    },
    watch: {
        size: {
            immediate: false,
            handler () {

                this.updateChart()
            }
        },
    },
    mounted () {
        this.createChart()
    },
    methods: {
        createChart () {
            this.chart.svg = d3.select('#monthly-chart').append('svg')
                .attr('class', 'chart-container')
                // .attr('width', Math.max(this.size, this.size, 200))
                // .attr('height', Math.max(this.size, this.size, 200))

            this.chart.margin = { top: 0, right: 0, bottom: 0, left: 0 }
            // this.chart.width = +this.size - this.chart.margin.left - this.chart.margin.right - 10
            // this.chart.height = +this.size - this.chart.margin.top - this.chart.margin.bottom - 10

            this.chart.g = this.chart.svg.append('g')
                .attr('class', 'chart-group')
                // .attr('transform', 'translate(' + this.chart.width / 2 + ',' + this.chart.height / 2 + ')')

            this.chart.color = d3.scaleOrdinal(['#38a89d', '#d6bbfc']);

            this.chart.pie = d3.pie()
                .sort(null)
                .value(d => { return d.value })


            // this.updateChart()

        },
        updateChart () {

            this.chart.width = +this.size - this.chart.margin.left - this.chart.margin.right
            this.chart.height = +this.size - this.chart.margin.top - this.chart.margin.bottom
            this.chart.radius = +this.size / 2.5

            d3.select('.chart-container')
                .attr('width', this.chart.height)
                .attr('height', this.chart.height)

            d3.select('.chart-group')
                .attr('transform', 'translate(' + this.chart.width / 2 + ',' + this.chart.height / 2 + ')')

            this.chart.path = d3.arc()
                .outerRadius(this.chart.radius - 10)
                .innerRadius(0)
            // this.chart.label = d3.arc()
            //     .outerRadius(this.chart.radius - 40)
            //     .innerRadius(this.chart.radius - 40)

            this.chart.g.selectAll(".arc").remove()
            const arc = this.chart.g.selectAll(".arc")
                .data(this.chart.pie(this.data.numbers))
                .enter()
                .append("g")
                .attr("class", "arc");

            arc.append("path")
                .attr("d", this.chart.path)
                .attr("fill", d => { return this.chart.color(d.data.label) })

            // arc.append("text")
            //     .attr("transform", d => { return "translate(" + this.chart.label.centroid(d) + ")" })
            //     .attr("dy", "0.35em")
            //     .text(d => { return d.data.label })
        }
    }
}
</script>