<template>
  <div class="container mx-auto bg-white">
    <Header title="Administrera formulär" />
    <div v-if="!loaded" class="w-full h-64 flex content-center justify-center">
      <div>
        <font-awesome-icon icon="spinner" spin></font-awesome-icon>
        <span class="pl-2">Laddar... </span>
      </div>
    </div>
    <div class="px-4 py-4" v-else>
      <div class="flex justify-between text-left">
        <div
          class="font-bold border-b flex items-center justify-start w-12 h-8"
        >
          ID
        </div>
        <div class="font-bold border-b flex items-center justify-start flex-1">
          CODE
        </div>
        <div class="font-bold border-b flex items-center justify-start flex-1">
          TITLE
        </div>
        <div class="font-bold border-b flex items-center justify-start flex-1">
          STATUS
        </div>
        <div class="border-b flex items-center justify-center w-8 h-8"></div>
        <div class="border-b flex items-center justify-center w-8 h-8"></div>
      </div>
      <Forms
        v-for="(form, index) in forms"
        :key="index"
        :form="form"
        @removeForm="removeForm"
      />
      <div class="m-2">
        <button
          class="bg-green p-2 rounded-lg text-white text-lg mx-2"
          v-on:click="addForm()"
        >
          Lägg till
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Forms from "./Forms.vue";
export default {
  name: "AdminForms",
  components: {
    Header,
    Forms,
  },
  data() {
    return {
      loaded: false,
      forms: [],
    };
  },
  mounted() {
    this.loadForms();
  },
  methods: {
    loadForms() {
      this.$store.dispatch("loadAllForms").then((response) => {
        this.forms = response;
        this.loaded = true;
      });
    },
    addForm() {
      this.forms.push({
        id: null,
        code: "",
        title: "",
        status: 0,
      });
    },
    removeForm(form) {
      if (confirm("Är du säker?")) {
        if (form.id) {
          this.$store
            .dispatch("removeFormAdmin", form.id)
            .then(() => {
              this.forms.splice(this.forms.indexOf(form), 1);
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        } else {
          this.forms.splice(this.forms.indexOf(form), 1);
        }
      }
    },
  },
};
</script>
