<template>
    <div class="container mx-auto bg-white">
        <Header title="Administrera organisationer" />

        <div v-if="!loaded" class="w-full h-64 flex content-center justify-center">
            <div>
                <font-awesome-icon icon="spinner" spin></font-awesome-icon>
                <span class="pl-2">Laddar... </span>
            </div>
        </div>

        <div v-else>
        <div class="mx-4 my-4">
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="text-left">Organisation</th>
                        <th class="text-left">Api-nyckel</th>
                    </tr>
                </thead>
                <tbody v-if="organisations">
                    <tr v-for="(item, key) in organisations" :key="key">
                        <td class="text-left">{{ item.name }}</td>
                        <td class="text-left">{{ item.api_key }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="w-full flex flex-row px-4 py-2">
            <input id="name" v-model="name" placeholder="Organisation name" class="w-1/3 rounded-lg border border-grey-light px-8 py-2 text-center text-xl mr-2" />
            <button @click="submit" class="bg-green border border-white text-white rounded-lg px-2 py-2 text-center text-lg">
                <font-awesome-icon icon="plus" class="text-white mx-2 align-middle" :disabled="!enabled" :class="enabledClass"></font-awesome-icon>
            </button>
        </div>
        </div>
    </div>
</template>

<script>
import Header from '../Header.vue'
export default {
    name: 'AdminOrganisations',
    props: [],
    components: {
        Header
    },
    data () {
        return {
            loaded: false,
            name: null,
            apiKey: null
        }
    },
    mounted () {
        this.loadOrganisations()
    },
    computed: {
        enabled () {
            return this.name !== null && this.apiKey !== null
        },
        enabledClass () {
            return this.enabled ? 'text-blue' : 'text-grey'
        },
        organisations() {
            return this.$store.getters.availableOrganisations
        }
    },
    methods: {
        loadOrganisations () {
            this.$store.dispatch('loadOrganisations').then(() => {
                this.loaded = true
                })
        },
        submit () {
            let newOrg = { name: this.name }
            this.$store.dispatch('addOrganisation', newOrg)
                .then(data => {
                    newOrg.id = data.id
                    newOrg.api_key = data.api_key
                    this.organisations.push(newOrg)
                    this.name = null
                })
        }
    }
}
</script>
