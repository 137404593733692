<template>
  <div class="container mt-5 mx-auto">
    <form>
      <!-- HI1 -->
      <div class="text-2xl bg-red p-5 rounded font-bold text-white">
        Del 2: Symptom på hyperaktivitet-impulsivitet (DSM-5 kriterium A2)
      </div>
      <div class="bg-white p-5 rounded border mt-2">
        <p>
          <span class="font-bold">Instruktioner för intervjun: </span>Symptomen
          i vuxen ålder måste ha funnits i minst 6 månader. Symptomen i
          barndomen gäller för åldern 5-12 år. För att ett symptom skall kunna
          tillskrivas ADHD krävs att det förekommer ständigt och inte bara i
          perioder.
        </p>
      </div>
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl bg-blue-darker text-white p-5 rounded mt-2 mr-2 border"
        >
          <h3 class="font-bold">H/I 1</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg mt-2 border"
        >
          <p>
            Har du ofta svårt att sitta stilla med händer eller fötter eller
            skruvar på dig när du sitter? Och hur var det då du var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1V.1"
                        v-model="HIForm.HI1.Adult[1]"
                      />
                      <span class="ml-2">Svårt att sitta stilla</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1V.2"
                        v-model="HIForm.HI1.Adult[2]"
                      />
                      <span class="ml-2">Gungar med benen</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1V.3"
                        v-model="HIForm.HI1.Adult[3]"
                      />
                      <span class="ml-2"
                        >Knackar med pennen eller leker med något</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1V.4"
                        v-model="HIForm.HI1.Adult[4]"
                      />
                      <span class="ml-2"
                        >Tvinnar håret eller biter på naglarna</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1V.5"
                        v-model="HIForm.HI1.Adult[5]"
                      />
                      <span class="ml-2"
                        >Kan kontrollera rastlösheten men blir stressad p.g.a
                        det</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI1.Adult.Annat"
                        @click="HIForm.HI1.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI1.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI1V.Message"
                      v-model="HIForm.HI1.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI1VRadio"
                        id="HI1VRadio1"
                        :value="true"
                        v-model="HIForm.HI1.Adult.Symptom"
                      />
                      <label for="HI1VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI1VRadio"
                        id="HI1VRadio2"
                        :value="false"
                        v-model="HIForm.HI1.Adult.Symptom"
                      />
                      <label for="HI1VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1B.1"
                        v-model="HIForm.HI1.Child[1]"
                      />
                      <span class="ml-2"
                        >Föräldrarna sade ofta "sitt still" eller liknande</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1B.2"
                        v-model="HIForm.HI1.Child[2]"
                      />
                      <span class="ml-2">Gungar med benen</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1B.3"
                        v-model="HIForm.HI1.Child[3]"
                      />
                      <span class="ml-2"
                        >Knackar med pennan eller leker med något</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1B.4"
                        v-model="HIForm.HI1.Child[4]"
                      />
                      <span class="ml-2"
                        >Tvinnar håret eller biter på naglarna</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1B.5"
                        v-model="HIForm.HI1.Child[5]"
                      />
                      <span class="ml-2"
                        >Kan inte förbli avslappnad eller sittande på en
                        stol</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI1B.6"
                        v-model="HIForm.HI1.Child[6]"
                      />
                      <span class="ml-2"
                        >Kan kontrollera rastlösheten men blir stressad p.g.a
                        det</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI1.Child.Annat"
                        @click="HIForm.HI1.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI1.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI1B.Message"
                      v-model="HIForm.HI1.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI1BRadio"
                        id="HI1BRadio1"
                        :value="true"
                        v-model="HIForm.HI1.Child.Symptom"
                      />
                      <label for="HI1BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI1BRadio"
                        id="HI1BRadio2"
                        :value="false"
                        v-model="HIForm.HI1.Child.Symptom"
                      />
                      <label for="HI1BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HI2 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl bg-blue-darker text-white p-5 rounded mt-2 mr-2 border"
        >
          <h3 class="font-bold">H/I 2</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg mt-2 border"
        >
          <p>
            Lämnar du ofta din plats i situationer där man förväntar att du
            skall förbli sittande? Och hur var det då du var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2V.1"
                        v-model="HIForm.HI2.Adult[1]"
                      />
                      <span class="ml-2"
                        >Lämnar ofta sin plats på kontoret eller
                        arbetsplatsen</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2V.2"
                        v-model="HIForm.HI2.Adult[2]"
                      />
                      <span class="ml-2"
                        >Undviker symposier, föreläsningar, kyrkobesök etc</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2V.3"
                        v-model="HIForm.HI2.Adult[3]"
                      />
                      <span class="ml-2"
                        >Går hellre omkring än att sitta stilla</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2V.4"
                        v-model="HIForm.HI2.Adult[4]"
                      />
                      <span class="ml-2"
                        >Sitter aldrig stilla länge, alltid i rörelse</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2V.5"
                        v-model="HIForm.HI2.Adult[5]"
                      />
                      <span class="ml-2"
                        >Blir stressad av svårigheten att sitta stilla</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2V.6"
                        v-model="HIForm.HI2.Adult[6]"
                      />
                      <span class="ml-2"
                        >Hittar på ursäkter för att få gå omkring</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI2.Adult.Annat"
                        @click="HIForm.HI2.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI2.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI2V.Message"
                      v-model="HIForm.HI2.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI2VRadio"
                        id="HI2VRadio1"
                        :value="true"
                        v-model="HIForm.HI2.Adult.Symptom"
                      />
                      <label for="HI2VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI2VRadio"
                        id="HI2VRadio2"
                        :value="false"
                        v-model="HIForm.HI2.Adult.Symptom"
                      />
                      <label for="HI2VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2B.1"
                        v-model="HIForm.HI2.Child[1]"
                      />
                      <span class="ml-2"
                        >Reser dig ofta upp under måltider eller lämnar sin
                        plats i klassrummet
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2B.2"
                        v-model="HIForm.HI2.Child[2]"
                      />
                      <span class="ml-2"
                        >Har väldigt svårt att förbli sittande i skolan eller
                        under måltider</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2B.3"
                        v-model="HIForm.HI2.Child[3]"
                      />
                      <span class="ml-2">Blir tillsagd att sitta kvar</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI2B.4"
                        v-model="HIForm.HI2.Child[4]"
                      />
                      <span class="ml-2"
                        >Hittar på ursäkter för att få gå omkring</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI2.Child.Annat"
                        @click="HIForm.HI2.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI2.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI2B.Message"
                      v-model="HIForm.HI2.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI2BRadio"
                        id="HI2BRadio1"
                        :value="true"
                        v-model="HIForm.HI2.Child.Symptom"
                      />
                      <label for="HI2BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI2BRadio"
                        id="HI2BRadio2"
                        :value="false"
                        v-model="HIForm.HI2.Child.Symptom"
                      />
                      <label for="HI2BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HI3 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl bg-blue-darker text-white p-5 rounded mt-2 mr-2 border"
        >
          <h3 class="font-bold">H/I 3</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg mt-2 border"
        >
          <p>Känner du dig ofta rastlös? Och hur var det då du var barn?</p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI3V.1"
                        v-model="HIForm.HI3.Adult[1]"
                      />
                      <span class="ml-2"
                        >Känsla av inre rastlöshet eller oro inombords</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI3V.2"
                        v-model="HIForm.HI3.Adult[2]"
                      />
                      <span class="ml-2"
                        >Har en ständig känsla av att du måste göra något</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI3V.3"
                        v-model="HIForm.HI3.Adult[3]"
                      />
                      <span class="ml-2">Har svårt att koppla av </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI3.Adult.Annat"
                        @click="HIForm.HI3.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI3.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI3V.Message"
                      v-model="HIForm.HI3.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI3VRadio"
                        id="HI3VRadio1"
                        :value="true"
                        v-model="HIForm.HI3.Adult.Symptom"
                      />
                      <label for="HI3VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI3VRadio"
                        id="HI3VRadio2"
                        :value="false"
                        v-model="HIForm.HI3.Adult.Symptom"
                      />
                      <label for="HI3VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI3B.1"
                        v-model="HIForm.HI3.Child[1]"
                      />
                      <span class="ml-2"
                        >Springer alltid omkring där det är opassande</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI3B.2"
                        v-model="HIForm.HI3.Child[2]"
                      />
                      <span class="ml-2"
                        >Klättrar på möbler eller hoppar i soffan</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI3B.3"
                        v-model="HIForm.HI3.Child[3]"
                      />
                      <span class="ml-2">Klättrar i träd</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI3B.4"
                        v-model="HIForm.HI3.Child[4]"
                      />
                      <span class="ml-2">Känner en inre rastlöshet</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI3.Child.Annat"
                        @click="HIForm.HI3.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI3.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI3B.Message"
                      v-model="HIForm.HI3.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI3BRadio"
                        id="HI3BRadio1"
                        :value="true"
                        v-model="HIForm.HI3.Child.Symptom"
                      />
                      <label for="HI3BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI3BRadio"
                        id="HI3BRadio2"
                        :value="false"
                        v-model="HIForm.HI3.Child.Symptom"
                      />
                      <label for="HI3BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HI4 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl bg-blue-darker text-white p-5 rounded mt-2 mr-2 border"
        >
          <h3 class="font-bold">H/I 4</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg mt-2 border"
        >
          <p>
            Har du svårt att syssla med fritidsaktiviteter på ett stilsamt sätt?
            Och hur var det då du var barn (under lek)?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI4V.1"
                        v-model="HIForm.HI4.Adult[1]"
                      />
                      <span class="ml-2"
                        >Pratar under aktiviteter när det inte är lämpligt</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI4V.2"
                        v-model="HIForm.HI4.Adult[2]"
                      />
                      <span class="ml-2"
                        >Blir ofta snabbt kaxig i sällskap med andra</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI4V.3"
                        v-model="HIForm.HI4.Adult[3]"
                      />
                      <span class="ml-2"
                        >Är högljudd i alla slags situationer</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI4V.4"
                        v-model="HIForm.HI4.Adult[4]"
                      />
                      <span class="ml-2"
                        >Har svårt att utföra aktiviteter på ett tyst sätt</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI4V.5"
                        v-model="HIForm.HI4.Adult[5]"
                      />
                      <span class="ml-2">Har svårt att tala lågmält</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI4.Adult.Annat"
                        @click="HIForm.HI4.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI4.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI4V.Message"
                      v-model="HIForm.HI4.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI4VRadio"
                        id="HI4VRadio1"
                        :value="true"
                        v-model="HIForm.HI4.Adult.Symptom"
                      />
                      <label for="HI4VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI4VRadio"
                        id="HI4VRadio2"
                        :value="false"
                        v-model="HIForm.HI4.Adult.Symptom"
                      />
                      <label for="HI4VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI4B.1"
                        v-model="HIForm.HI4.Child[1]"
                      />
                      <span class="ml-2"
                        >Är högljudd under lek eller i klassrummet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI4B.2"
                        v-model="HIForm.HI4.Child[2]"
                      />
                      <span class="ml-2"
                        >Kan inte sitta tyst och titta på tv eller filmer</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI4B.3"
                        v-model="HIForm.HI4.Child[3]"
                      />
                      <span class="ml-2"
                        >Ombedd att vara tystare eller lugna ner sig</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI4B.4"
                        v-model="HIForm.HI4.Child[4]"
                      />
                      <span class="ml-2"
                        >Blir snabbt kaxig i andras sällskap</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI4.Child.Annat"
                        @click="HIForm.HI4.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI4.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI4B.Message"
                      v-model="HIForm.HI4.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI4BRadio"
                        id="HI4BRadio1"
                        :value="true"
                        v-model="HIForm.HI4.Child.Symptom"
                      />
                      <label for="HI4BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI4BRadio"
                        id="HI4BRadio2"
                        :value="false"
                        v-model="HIForm.HI4.Child.Symptom"
                      />
                      <label for="HI4BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HI5 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl bg-blue-darker text-white p-5 rounded mt-2 mr-2 border"
        >
          <h3 class="font-bold">H/I 5</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg mt-2 border"
        >
          <p>
            Är du alltid "på språng" eller tvungen att hålla igång som om du
            "gick på högvarv"? Och hur var det då du var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5V.1"
                        v-model="HIForm.HI5.Adult[1]"
                      />
                      <span class="ml-2"
                        >Är alltid upptagen med att göra något</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5V.2"
                        v-model="HIForm.HI5.Adult[2]"
                      />
                      <span class="ml-2"
                        >Är obekväm med att sitta still under en längre tid, t
                        ex på restaurang eller möten</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5V.3"
                        v-model="HIForm.HI5.Adult[3]"
                      />
                      <span class="ml-2"
                        >Har mycket energi, alltid på en gång</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5V.4"
                        v-model="HIForm.HI5.Adult[4]"
                      />
                      <span class="ml-2"
                        >Andra uppfattar dig som rastlös och svår att hålla
                        jämna steg med</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5V.5"
                        v-model="HIForm.HI5.Adult[5]"
                      />
                      <span class="ml-2"
                        >Svårt att känna sina egna gränser</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5V.6"
                        v-model="HIForm.HI5.Adult[6]"
                      />
                      <span class="ml-2"
                        >Har svårt att släppa något, pressar på uttal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI5.Adult.Annat"
                        @click="HIForm.HI5.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI5.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI5V.Message"
                      v-model="HIForm.HI5.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI5VRadio"
                        id="HI5VRadio1"
                        :value="true"
                        v-model="HIForm.HI5.Adult.Symptom"
                      />
                      <label for="HI5VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI5VRadio"
                        id="HI5VRadio2"
                        :value="false"
                        v-model="HIForm.HI5.Adult.Symptom"
                      />
                      <label for="HI5VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5B.1"
                        v-model="HIForm.HI5.Child[1]"
                      />
                      <span class="ml-2">Är upptagen hela tiden</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5B.2"
                        v-model="HIForm.HI5.Child[2]"
                      />
                      <span class="ml-2"
                        >Andra uppfattar dig som rastlös och svår att hålla
                        jämna steg med</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5B.3"
                        v-model="HIForm.HI5.Child[3]"
                      />
                      <span class="ml-2"
                        >Är obekväm med att vara still under en längre tid</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5B.4"
                        v-model="HIForm.HI5.Child[4]"
                      />
                      <span class="ml-2">Uttalat aktiv i skolan och hemma</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5B.5"
                        v-model="HIForm.HI5.Child[5]"
                      />
                      <span class="ml-2">Har mycket energi</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI5B.66"
                        v-model="HIForm.HI5.Child[6]"
                      />
                      <span class="ml-2"
                        >Alltid "på språng", pressar på uttal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI5.Child.Annat"
                        @click="HIForm.HI5.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI5.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI5B.Message"
                      v-model="HIForm.HI5.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI5BRadio"
                        id="HI5BRadio1"
                        :value="true"
                        v-model="HIForm.HI5.Child.Symptom"
                      />
                      <label for="HI5BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI5BRadio"
                        id="HI5BRadio2"
                        :value="false"
                        v-model="HIForm.HI5.Child.Symptom"
                      />
                      <label for="HI5BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HI6 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl bg-blue-darker text-white p-5 rounded mt-2 mr-2 border"
        >
          <h3 class="font-bold">H/I 6</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg mt-2 border"
        >
          <p>Pratar du ofta uttalat mycket? Och hur var det då du var barn?</p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6V.1"
                        v-model="HIForm.HI6.Adult[1]"
                      />
                      <span class="ml-2"
                        >Pratar så mycket att folk tråkas ut</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6V.2"
                        v-model="HIForm.HI6.Adult[2]"
                      />
                      <span class="ml-2">Är känd för att prata oavbrutet</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6V.3"
                        v-model="HIForm.HI6.Adult[3]"
                      />
                      <span class="ml-2">Har svårt att sluta prata</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6V.4"
                        v-model="HIForm.HI6.Adult[4]"
                      />
                      <span class="ml-2"
                        >Har en tendens att prata för mycket</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6V.5"
                        v-model="HIForm.HI6.Adult[5]"
                      />
                      <span class="ml-2"
                        >Ger inte andra utrymme att bryta in under ett
                        samtal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6V.6"
                        v-model="HIForm.HI6.Adult[6]"
                      />
                      <span class="ml-2"
                        >Behöver många ord för att få något sagt</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI6.Adult.Annat"
                        @click="HIForm.HI6.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI6.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI6V.Message"
                      v-model="HIForm.HI6.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI6VRadio"
                        id="HI6VRadio1"
                        :value="true"
                        v-model="HIForm.HI6.Adult.Symptom"
                      />
                      <label for="HI6VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI6VRadio"
                        id="HI6VRadio2"
                        :value="false"
                        v-model="HIForm.HI6.Adult.Symptom"
                      />
                      <label for="HI6VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6B.1"
                        v-model="HIForm.HI6.Child[1]"
                      />
                      <span class="ml-2"
                        >Är känd för att vara en pratkvarn</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6B.2"
                        v-model="HIForm.HI6.Child[2]"
                      />
                      <span class="ml-2"
                        >Lärare och föräldrar ber dig ofta att vara tyst</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6B.3"
                        v-model="HIForm.HI6.Child[3]"
                      />
                      <span class="ml-2"
                        >Kommentarer i omdömen från skolan om att prata för
                        mycket</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6B.4"
                        v-model="HIForm.HI6.Child[4]"
                      />
                      <span class="ml-2"
                        >Bestraffas för att prata för mycket</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6B.5"
                        v-model="HIForm.HI6.Child[5]"
                      />
                      <span class="ml-2"
                        >Hindrar andra i skolarbetet genom att prata för
                        mycket</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI6B.6"
                        v-model="HIForm.HI6.Child[6]"
                      />
                      <span class="ml-2"
                        >Ger inte utrymme åt andra i ett samtal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI6.Child.Annat"
                        @click="HIForm.HI6.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI6.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI6B.Message"
                      v-model="HIForm.HI6.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI6BRadio"
                        id="HI6BRadio1"
                        :value="true"
                        v-model="HIForm.HI6.Child.Symptom"
                      />
                      <label for="HI6BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI6BRadio"
                        :value="false"
                        id="HI6BRadio2"
                        v-model="HIForm.HI6.Child.Symptom"
                      />
                      <label for="HI6BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HI7 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl bg-blue-darker text-white p-5 rounded mt-2 mr-2 border"
        >
          <h3 class="font-bold">H/I 7</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg mt-2 border"
        >
          <p>
            Kastar du ofta ur dig svar innan frågan avslutats? Och hur var det
            då du var barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7V.1"
                        v-model="HIForm.HI7.Adult[1]"
                      />
                      <span class="ml-2"
                        >"Babbelmaja", säger vad du tänker"</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7V.2"
                        v-model="HIForm.HI7.Adult[2]"
                      />
                      <span class="ml-2">Säger saker utan att tänka först</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7V.3"
                        v-model="HIForm.HI7.Adult[3]"
                      />
                      <span class="ml-2"
                        >Ger svar åt folk innan de har pratat klart
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7V.4"
                        v-model="HIForm.HI7.Adult[4]"
                      />
                      <span class="ml-2">Avslutar andras meningar</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7V.5"
                        v-model="HIForm.HI7.Adult[5]"
                      />
                      <span class="ml-2">Uppfattas som taktlös</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI7.Adult.Annat"
                        @click="HIForm.HI7.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI7.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI7V.Message"
                      v-model="HIForm.HI7.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI7VRadio"
                        id="HI7VRadio1"
                        :value="true"
                        v-model="HIForm.HI7.Adult.Symptom"
                      />
                      <label for="HI7VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI7VRadio"
                        id="HI7VRadio2"
                        :value="false"
                        v-model="HIForm.HI7.Adult.Symptom"
                      />
                      <label for="HI7VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7B.1"
                        v-model="HIForm.HI7.Child[1]"
                      />
                      <span class="ml-2"
                        >"Babbelmaja", säger saker utan att tänka först</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7B.2"
                        v-model="HIForm.HI7.Child[2]"
                      />
                      <span class="ml-2"
                        >Vill besvara alla frågor i skolan före de andra</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7B.3"
                        v-model="HIForm.HI7.Child[3]"
                      />
                      <span class="ml-2"
                        >Slänger ut sig ett svar även om det är fel</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7B.4"
                        v-model="HIForm.HI7.Child[4]"
                      />
                      <span class="ml-2"
                        >Avbryter andra innan dem pratat klart</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7B.5"
                        v-model="HIForm.HI7.Child[5]"
                      />
                      <span class="ml-2"
                        >Svårigheter med turtagning under samtal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI7B.6"
                        v-model="HIForm.HI7.Child[6]"
                      />
                      <span class="ml-2">Uppfattas som sårande</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI7.Child.Annat"
                        @click="HIForm.HI7.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI7.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI7B.Message"
                      v-model="HIForm.HI7.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI7BRadio"
                        id="HI7BRadio1"
                        :value="true"
                        v-model="HIForm.HI7.Child.Symptom"
                      />
                      <label for="HI7BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI7BRadio"
                        id="HI7BRadio2"
                        :value="false"
                        v-model="HIForm.HI7.Child.Symptom"
                      />
                      <label for="HI7BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HI8 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl bg-blue-darker text-white p-5 rounded mt-2 mr-2 border"
        >
          <h3 class="font-bold">H/I 8</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg mt-2 border"
        >
          <p>
            Har du ofta svårt att vänta på din tur? Och hur var det då du var
            barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8V.1"
                        v-model="HIForm.HI8.Adult[1]"
                      />
                      <span class="ml-2"
                        >Har svårt att vänta i en kö, tränger sig före</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8V.2"
                        v-model="HIForm.HI8.Adult[2]"
                      />
                      <span class="ml-2"
                        >Har svårt att tålamodigt vänta i trafiken /
                        trafikstockningar</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8V.3"
                        v-model="HIForm.HI8.Adult[3]"
                      />
                      <span class="ml-2"
                        >Har svårt att vänta på din tur i samtal</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8V.4"
                        v-model="HIForm.HI8.Adult[4]"
                      />
                      <span class="ml-2">Är otålig</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8V.5"
                        v-model="HIForm.HI8.Adult[5]"
                      />
                      <span class="ml-2"
                        >Påbörjar snabbt relationer / anställningar eller
                        avslutar / lämnar dessa p.g.a otålighet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI8.Adult.Annat"
                        @click="HIForm.HI8.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI8.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI8V.Message"
                      v-model="HIForm.HI8.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI8VRadio"
                        id="HI8VRadio1"
                        :value="true"
                        v-model="HIForm.HI8.Adult.Symptom"
                      />
                      <label for="HI8VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI8VRadio"
                        id="HI8VRadio2"
                        :value="false"
                        v-model="HIForm.HI8.Adult.Symptom"
                      />
                      <label for="HI8VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8B.1"
                        v-model="HIForm.HI8.Child[1]"
                      />
                      <span class="ml-2"
                        >Har svårt att vänta på din tur under gruppaktiviteter
                      </span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8B.2"
                        v-model="HIForm.HI8.Child[2]"
                      />
                      <span class="ml-2"
                        >Har svårt att vänta på din tur i klassrummet</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8B.3"
                        v-model="HIForm.HI8.Child[3]"
                      />
                      <span class="ml-2"
                        >Alltid först att prata eller agera</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8B.4"
                        v-model="HIForm.HI8.Child[4]"
                      />
                      <span class="ml-2">Blir snabbt otålig</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI8B.5"
                        v-model="HIForm.HI8.Child[5]"
                      />
                      <span class="ml-2"
                        >Går över gatan utan att se sig för</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI8.Child.Annat"
                        @click="HIForm.HI8.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI8.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI8B.Message"
                      v-model="HIForm.HI8.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI8BRadio"
                        id="HI8BRadio1"
                        :value="true"
                        v-model="HIForm.HI8.Child.Symptom"
                      />
                      <label for="HI8BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI8BRadio"
                        id="HI8BRadio2"
                        :value="false"
                        v-model="HIForm.HI8.Child.Symptom"
                      />
                      <label for="HI8BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HI9 -->
      <div class="flex">
        <div
          class="flex items-center justify-center text-2xl bg-blue-darker text-white p-5 rounded mt-2 mr-2 border"
        >
          <h3 class="font-bold">H/I 9</h3>
        </div>
        <div
          class="flex-1 text-center bg-blue-light p-5 rounded text-lg mt-2 border"
        >
          <p>
            Avbryter du eller inkräktar du på andra? Och hur var det då du var
            barn?
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från vuxen ålder:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9V.1"
                        v-model="HIForm.HI9.Adult[1]"
                      />
                      <span class="ml-2"
                        >Lägger dig snabbt i vad andra gör</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9V.2"
                        v-model="HIForm.HI9.Adult[2]"
                      />
                      <span class="ml-2">Inkräktar på andra</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9V.3"
                        v-model="HIForm.HI9.Adult[3]"
                      />
                      <span class="ml-2"
                        >Stör folk i deras aktiviteter eller tar över deras
                        uppgift</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9V.4"
                        v-model="HIForm.HI9.Adult[4]"
                      />
                      <span class="ml-2"
                        >Andra kommenterar att du lägger dig i</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9V.5"
                        v-model="HIForm.HI9.Adult[5]"
                      />
                      <span class="ml-2"
                        >Har svårt att respektera andras gränser</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9V.6"
                        v-model="HIForm.HI9.Adult[6]"
                      />
                      <span class="ml-2"
                        >Har en åsikt om allt och uttalar den direkt</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI9.Adult.Annat"
                        @click="HIForm.HI9.Adult.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI9.Adult.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI9V.Message"
                      v-model="HIForm.HI9.Adult.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI9VRadio"
                        id="HI9VRadio1"
                        :value="true"
                        v-model="HIForm.HI9.Adult.Symptom"
                      />
                      <label for="HI9VRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI9VRadio"
                        id="HI9VRadio2"
                        :value="false"
                        v-model="HIForm.HI9.Adult.Symptom"
                      />
                      <label for="HI9VRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- New card -->
        <div class="flex-1 text-center bg-white p-5 rounded ml-2 mt-2 border">
          <div class="lg:flex text-left">
            <div class="mt-4 lg:mt-0 lg:ml-6">
              <div class="block">
                <h2 class="font-bold">Exempel från barndomen:</h2>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center position: fixed;">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9B.1"
                        v-model="HIForm.HI9.Child[1]"
                      />
                      <span class="ml-2"
                        >Avbryter andras lekar eller aktiviteter</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9B.2"
                        v-model="HIForm.HI9.Child[2]"
                      />
                      <span class="ml-2"
                        >Börjar använda andra personers saker utan att fråga
                        eller be om lov</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9B.3"
                        v-model="HIForm.HI9.Child[3]"
                      />
                      <span class="ml-2">Avbryter andras samtal</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9B.4"
                        v-model="HIForm.HI9.Child[4]"
                      />
                      <span class="ml-2">Reagerar på allt</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        name="HI9B.5"
                        v-model="HIForm.HI9.Child[5]"
                      />
                      <span class="ml-2">Kan inte vänta</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="HIForm.HI9.Child.Annat"
                        @click="HIForm.HI9.Child.Message = ''"
                      />
                      <span class="ml-2">Annat</span>
                    </label>
                  </div>
                  <div v-show="HIForm.HI9.Child.Annat">
                    <textarea
                      class="rounded border w-full"
                      rows="4"
                      cols="40"
                      name="HI9B.Message"
                      v-model="HIForm.HI9.Child.Message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <div class="inline-flex items-center">
                      <span class="ml-2">Symptom finns</span>
                      <input
                        type="radio"
                        class="form-checkbox ml-1"
                        name="HI9BRadio"
                        id="HI9BRadio1"
                        :value="true"
                        v-model="HIForm.HI9.Child.Symptom"
                      />
                      <label for="HI9BRadio1" class="ml-1">Ja /</label>
                      <input
                        type="radio"
                        class="form-checkbox"
                        style="margin-left: 0.3em"
                        name="HI9BRadio"
                        id="HI9BRadio2"
                        :value="false"
                        v-model="HIForm.HI9.Child.Symptom"
                      />
                      <label for="HI9BRadio2" class="ml-1">Nej</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1 text-center bg-white p-5 rounded mt-2 border">
          <textarea
            name="AText"
            id="AText"
            class="w-full h-full border"
            style="min-height: 200px;"
            v-model="HIText"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      HIForm: {
        HI1: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        HI2: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        HI3: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        HI4: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        HI5: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        HI6: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        HI7: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        HI8: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        HI9: {
          Adult: { Annat: false, Message: null },
          Child: { Annat: false, Message: null },
        },
        Summa: {
          Adult: { Summa: this.AVSumma },
          Child: { Summa: this.ABSumma },
        },
      },
    };
  },
  props: {
    sHIForm: Object,
  },
  watch: {
    HIForm: {
      handler() {
        this.$store.dispatch("setHIForm", this.HIForm);
        this.HIForm.Summa.Adult.Summa = this.HIVSumma;
        this.HIForm.Summa.Child.Summa = this.HIBSumma;
      },
      deep: true,
    },
  },
  computed: {
    HIVSumma() {
      let HIVSumma = 0;
      HIVSumma += this.HIForm.HI1.Adult.Symptom == true ? 1 : 0;
      HIVSumma += this.HIForm.HI2.Adult.Symptom == true ? 1 : 0;
      HIVSumma += this.HIForm.HI3.Adult.Symptom == true ? 1 : 0;
      HIVSumma += this.HIForm.HI4.Adult.Symptom == true ? 1 : 0;
      HIVSumma += this.HIForm.HI5.Adult.Symptom == true ? 1 : 0;
      HIVSumma += this.HIForm.HI6.Adult.Symptom == true ? 1 : 0;
      HIVSumma += this.HIForm.HI7.Adult.Symptom == true ? 1 : 0;
      HIVSumma += this.HIForm.HI8.Adult.Symptom == true ? 1 : 0;
      HIVSumma += this.HIForm.HI9.Adult.Symptom == true ? 1 : 0;

      return HIVSumma;
    },

    HIBSumma() {
      let HIBSumma = 0;
      HIBSumma += this.HIForm.HI1.Child.Symptom == true ? 1 : 0;
      HIBSumma += this.HIForm.HI2.Child.Symptom == true ? 1 : 0;
      HIBSumma += this.HIForm.HI3.Child.Symptom == true ? 1 : 0;
      HIBSumma += this.HIForm.HI4.Child.Symptom == true ? 1 : 0;
      HIBSumma += this.HIForm.HI5.Child.Symptom == true ? 1 : 0;
      HIBSumma += this.HIForm.HI6.Child.Symptom == true ? 1 : 0;
      HIBSumma += this.HIForm.HI7.Child.Symptom == true ? 1 : 0;
      HIBSumma += this.HIForm.HI8.Child.Symptom == true ? 1 : 0;
      HIBSumma += this.HIForm.HI9.Child.Symptom == true ? 1 : 0;

      return HIBSumma;
    },
    HIText() {
      let text = "";

      for (let x = 1; x < 10; x++) {
        if (this.HIForm["HI" + x].Adult.Annat || this.HIForm["HI" + x].Child.Annat)
          text += "H/I" + x + ":\r\n";
        if (this.HIForm["HI" + x].Adult.Annat === true)
          text += this.HIForm["HI" + x].Adult.Message + "\r\n";
        if (this.HIForm["HI" + x].Child.Annat === true)
          text += this.HIForm["HI" + x].Child.Message + "\r\n";
      }

      return text;
    },
  },
  created() {
    if (this.sHIForm) this.HIForm = this.sHIForm;
    this.$store.dispatch("setHIForm", this.HIForm);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
