import axios from 'axios';

const apiGetRequest = (url) => {
  return axios
    .get(`${process.env.VUE_APP_API_URL}/${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const apiPostRequest = (url, data) => {
  return axios
    .post(`${process.env.VUE_APP_API_URL}/${url}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const apiPutRequest = (url, data) => {
  return axios
    .put(`${process.env.VUE_APP_API_URL}/${url}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const apiDeleteRequest = (url, id) => {
  return axios
    .delete(`${process.env.VUE_APP_API_URL}/${url}/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const apiFileRequest = (url) => {
  return axios
    .get(`${process.env.VUE_APP_API_URL}/${url}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const apiPatchRequest = (url, data) => {
  return axios
    .patch(`${process.env.VUE_APP_API_URL}/${url}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export {
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
  apiDeleteRequest,
  apiFileRequest,
  apiPatchRequest,
};
