<template>
    <div>
<!--         <div class="mt-2 flex justify-center">
            <div v-for="(form, key) in forms"
                @click="$emit('goto', key)"
                :key="key"
                class="h-24 w-24 mx-2 border border-grey-dark rounded rounded-full bg-white flex items-center overflow-hidden">
                <div class="w-full flex flex-col items-center">
                    <span class="h-4 px-2 uppercase break-words text-center">{{ form.code }}</span>
                    <span class="font-semibold">{{ form.score }}</span>
                </div>
            </div>
        </div>
 -->
        <div class="mt-2 flex flex-wrap justify-center">
            <div v-for="(form, key) in sortedForms"
                :key="`_${key}`"
                class="h-24 w-24 mx-2 bg-white text-center">
                <ResizableContainer>
                    <FormScoreChart
                        slot-scope="{ width, height }"
                        :id="`score_chart-${form.code}`"
                        :width="width"
                        :score="form.score"
                        :maxScore="form.maxScore"
                        :code="form.shortCode"
                        :color="form.color"
                    />
                </ResizableContainer>
                <div>
                    <span :title="`Visa ${form.code}`" @click="$emit('goto', form.code)">
                        <font-awesome-icon icon="chevron-down" class="cursor-pointer mx-2 text-blue align-middle"></font-awesome-icon>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formMetadata } from '../utils/metadata.js'
import ResizableContainer from './ResizableContainer.vue'
import FormScoreChart from './charts/FormScoreChart.vue'

export default {
    name: 'PatientOverviewFormScores',
    props: ['forms'],
    components: {
        ResizableContainer,
        FormScoreChart,
    },
    computed: {
        sortedForms () {
            let forms = this.forms
                forms.sort((a, b) => a.code.localeCompare(b.code))

                forms = forms
                    .filter(f => formMetadata[f.code] !== undefined)
                    .map(f => {
                        f.color = formMetadata[f.code].color || 'black'
                        f.maxScore = formMetadata[f.code].maxScore || 100
                        f.shortCode = formMetadata[f.code].hasOwnProperty('shortCode') ? formMetadata[f.code].shortCode : f.code
                        return f
                    })

            return forms
        }
    }
}
</script>