<script>
export default {
    name: 'Logout',
    mounted () {
        this.logout()
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push({ name: 'home' })
                })
                .catch(() => {

                })
        }
    },
    render (createElement) {
        return createElement('div')
    }
}
</script>