<template>
    <div class="container bg-grey-lighter">
        <Header :title="title" />

        <div class="flex text-left w-full mt-2">
            <div class="w-full">
                <ul class="list-reset flex">
                    <li class="p-4 bg-white hover:bg-grey-light cursor-pointer mr-1" @click="changeTab('move')" :class="isActiveTab('move')">Begär flytt av patient</li>
                    <li v-if="isAdmin || isLocalAdmin" class="p-4 bg-white hover:bg-grey-light cursor-pointer mr-1" @click="changeTab('incoming')" :class="isActiveTab('incoming')">Patienter till din enhet</li>
                    <li v-if="isAdmin || isLocalAdmin" class="p-4 bg-white hover:bg-grey-light cursor-pointer mr-1" @click="changeTab('outgoing')" :class="isActiveTab('outgoing')">Patienter till annan enhet</li>
                </ul>

                <div class="p-4 bg-white" v-if="activeTab == 'move'">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="pid">
                        Personnummer
                    </label>
                    <div class="error text-red">
                        {{ error }}
                    </div>
                    <div class="flex">
                        <BaseInputSsn :validSsn.sync="validSsn" v-model="ssn" @enter="searchBySsn"/>
                        <button type="button"
                            class="block px-2 py-1 rounded-lg bg-green border border-white text-white ml-2" @click="searchBySsn">
                            Sök patient
                        </button>
                    </div>

                    <div v-if="patient" class="patient-found mt-4">
                        <div>Patient hittad med id {{ patient.patient_id }}</div>
                        <div>Vill du skicka en begäran om att flytta patienten från {{ patient.from }} till {{ patient.to }}?</div>
                        <button type="button" class="block px-2 py-1 rounded-lg bg-green border border-white text-white mt-2" @click="requestMove">Skicka begäran</button>
                    </div>

                    <div v-if="requestMade" class="mt-2">
                        Begäran om flytt inskickad!
                    </div>

                    <div class="mt-8">
                        <h4>Hur det fungerar:</h4>
                        <ul>
                            <li class="p-1">Ange personnummer på den patient som du vill flytta till din enhet.</li>
                            <li class="p-1">Om patienten redan finns på en enhet kan du skicka in en begäran om att få patienten flyttad.</li>
                            <li class="p-1">När en begäran är inskickad är det upp till den lokala administratören för patientens nuvarande enhet att godkänna flytten.</li>
                            <li class="p-1">Om flytten blir godkänd kommer patienten att finnas tillgänglig på er enhet.</li>
                            <li class="p-1">För att se status på en pågående flyttbegäran kontakta en lokal administratör på din enhet.</li>
                        </ul>
                    </div>
                </div>

                <div class="p-4 bg-white" v-if="activeTab == 'incoming'">
                    <table class="w-full" v-if="incoming">
                        <tr class="border-b-2">
                            <th class="p-1">Begärd av</th>
                            <th class="p-1">När</th>
                            <th class="p-1">Patient</th>
                            <th class="p-1">Från</th>
                            <th></th>
                            <th class="p-1">Till</th>
                            <th class="p-1">Hanterad</th>
                            <th class="p-1">Hanterad av</th>
                            <th>Status</th>
                        </tr>
                        <tr v-for="(item, index) in incoming.data" :key="index" class="border-b" :class="getStatus(item.status)">
                            <td class="p-1">{{ item.requested_by }}</td>
                            <td class="p-1">{{ item.requested_at }}</td>
                            <td class="p-1">{{ item.patient }}</td>
                            <td class="p-1">{{ item.from }}</td>
                            <td><img src="/images/cheveron-right.png" /></td>
                            <td class="p-1">{{ item.to }}</td>
                            <td class="p-1">{{ item.approved_at }}</td>
                            <td class="p-1">{{ item.approved_by }}</td>
                            <td class="p-1">
                                <div v-if="item.status == null">
                                    <button @click="answerMoveRequest(item.id, 2)" type="button" class="flex-1 block px-3 py-2 rounded-lg bg-red border border-white text-white ml-2">Avbryt</button>
                                </div>
                                <div v-else>
                                    {{ getStatusText(item.status) }}
                                </div>
                            </td>
                        </tr>
                    </table>

                    <paginate v-if="incoming.pages > 1"
                        :page-count="incoming.pages"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="changeIncomingPage"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :prev-class="'prev-page'"
                        :next-class="'next-page'"
                    ></paginate>
                </div>

                <div class="p-4 bg-white" v-if="activeTab == 'outgoing'">
                    <table class="w-full" v-if="outgoing">
                        <tr class="border-b-2">
                            <th class="p-1">Begärd av</th>
                            <th class="p-1">När</th>
                            <th class="p-1">Patient</th>
                            <th class="p-1">Från</th>
                            <th></th>
                            <th class="p-1">Till</th>
                            <th class="p-1">Hanterad</th>
                            <th class="p-1">Hanterad av</th>
                            <th class="p-1">Status</th>
                        </tr>
                        <tr v-for="(item, index) in outgoing.data" :key="index" class="border-b" :class="getStatus(item.status)">
                            <td class="p-1">{{ item.requested_by }}</td>
                            <td class="p-1">{{ item.requested_at }}</td>
                            <td class="p-1">{{ item.patient }}</td>
                            <td class="p-1">{{ item.from }}</td>
                            <td><img src="/images/cheveron-right.png" /></td>
                            <td class="p-1">{{ item.to }}</td>
                            <td class="p-1">{{ item.approved_at }}</td>
                            <td class="p-1">{{ item.approved_by }}</td>
                            <td class="p-1">
                                <div class="flex" v-if="item.status == null">
                                    <button @click="answerMoveRequest(item.id, 1)" type="button" class="flex-1 block px-3 py-2 rounded-lg bg-green border border-white text-white ml-2">Ja</button>
                                    <button @click="answerMoveRequest(item.id, 0)" type="button" class="flex-1 block px-3 py-2 rounded-lg bg-red border border-white text-white ml-2">Nej</button>
                                </div>
                                <div v-else>
                                    {{ getStatusText(item.status) }}
                                </div>
                            </td>
                        </tr>
                    </table>

                    <paginate v-if="outgoing.pages > 1"
                        :page-count="outgoing.pages"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="changeOutgoingPage"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :prev-class="'prev-page'"
                        :next-class="'next-page'"
                    ></paginate>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import BaseInputSsn from './base/BaseInputSsn.vue';
import Header from './Header.vue';

export default {
    name: "PatientMove",
    data() {
        return {
            title: "Flytta patient",
            activeTab: 'move',
            validSsn: false,
            ssn: '',
            patient: null,
            error: "",
            requestMade: false,
            incoming: [],
            outgoing: []
        }
    },
    mounted() {
        if (this.isAdmin || this.isLocalAdmin) {
            this.loadIncoming(1);
            this.loadOutgoing(1);
            this.$store.dispatch('setActiveMoveRequests');
        }
    },
    computed: {
        isAdmin() {
            if (!this.$store.getters.userData || !this.$store.getters.userData.hasOwnProperty('roles')) {
                return false
            }
            return this.$store.getters.userData.roles.indexOf('ADMIN') > -1
        },
        isLocalAdmin() {
            if (!this.$store.getters.userData || !this.$store.getters.userData.hasOwnProperty('roles')) {
                return false
            }
            return this.$store.getters.userData.roles.indexOf('LOCALADMIN') > -1
        },
    },
    methods: {
        isActiveTab(tab) {
            return tab == this.activeTab ? 'font-bold text-blue' : '';
        },
        changeTab(tab) {
            this.activeTab = tab;
        },
        searchBySsn() {
            this.error = "";
            this.requestMade = false;

            if (!this.validSsn) {
                alert("Ange ett komplett personnummer för att söka");
                return false;
            }

            this.$store.dispatch('searchBySsnMove', this.ssn)
                .then((response) => {
                    this.patient = response;
                }).catch((error) => {
                    this.error = error.response.data.message;
                });
        },
        requestMove() {
            this.$store.dispatch('requestMove', this.patient.patient_id)
                .then((response) => {
                    if (response.success == true) {
                        this.patient = null;
                        this.error = "";
                        this.ssn = "";
                        this.requestMade = true;
                        if (this.isAdmin || this.isLocalAdmin) {
                            this.loadIncoming(1);
                        }
                    } 
                }).catch((error) => {
                    this.error = error.response.data.message;
                });
        },
        loadIncoming(pageNum) {
            this.$store.dispatch('requestIncomingList', pageNum)
                .then((response) => {
                    this.incoming = response;
                });
        },
        loadOutgoing(pageNum) {
            this.$store.dispatch('requestOutgoingList', pageNum)
                .then((response) => {
                    this.outgoing = response;
                });
        },
        answerMoveRequest(id, answer) {
            this.$store.dispatch('answerMoveRequest', { id: id, answer: answer })
                .then(() => {
                    this.loadOutgoing(1);
                    this.loadIncoming(1);
                    this.$store.dispatch('setActiveMoveRequests');
                });
        },
        getStatus(status) {
            if (status === 1) {
                return 'bg-green-light';
            } else if (status === 0) {
                return 'bg-red-light';
            } else if (status === 2) {
                return 'bg-yellow-light';
            }

            return '';
        },
        getStatusText(status) {
            switch (status) {
                case 0:
                    return 'Nekad';
                case 1:
                    return 'Godkänd';
                case 2:
                    return 'Avbruten';
                default:
                    return ''
            }
        },
        changeIncomingPage(pageNum) {
            this.loadIncoming(pageNum);
        },
        changeOutgoingPage(pageNum) {
            this.loadOutgoing(pageNum);
        }
    },
    components: {
        Header,
        BaseInputSsn
    }
}
</script>