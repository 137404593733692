<template>
  <div class="w-full">
    <div class="w-full bg-white rounded rounded-lg">
      <div v-if="data" :id="`multilinechart-${id}`"></div>
    </div>
    <div
      id="multilinechart-tooltip"
      class="
        fixed
        pin-t pin-l
        z-10
        flex-col
        bg-white
        rounded
        border
        text-sm
        px-2
        py-1
      "
    >
      <div class="flex">
        <div id="type" class="text-sm font-bold"></div>
        <span class="px-1"></span>
        <div id="date" class="text-sm"></div>
      </div>
      <div id="value" class="text-sm"></div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { formMetadata } from "@/utils/metadata.js";

const svSE = require("d3-time-format/locale/sv-SE.json");
d3.timeFormatDefaultLocale(svSE);

export default {
  name: "MultiLineChart",
  props: ["id", "data", "propwidth", "title"],
  data() {
    return {
      visible: {},
    };
  },
  watch: {
    propwidth: {
      immediate: false,
      handler: function (value) {
        if (value) {
          this.createChart();
        }
      },
    },
  },
  mounted() {
    this.createChart();
  },
  computed: {
    width() {
      return this.propwidth ? this.propwidth : this.$el.offsetWidth;
    },
    height() {
      return (Math.round((this.width / 16) * 9) - 20) / 3;
    },
  },
  methods: {
    createChart() {
      d3.select(`#multilinechart-${this.id}`).select("svg").remove();
      if (!this.data.length > 0) {
        return;
      }

      let dateFormat = d3.timeFormat("%Y-%m-%d");
      let parseFullDay = d3.timeParse("%Y-%m-%d");
      let normalizer = function (val, max) {
        return ((val - 0) / (max - 0)) * 100;
      };

      let tempData = this.data.filter((d) => {
        return formMetadata[d.code];
      })

      tempData.forEach((d) => {
        this.visible[d.code] = true;
        d.date = parseFullDay(d.created);
        d.code = d.code;
        d.value = normalizer(+d.score, +formMetadata[d.code].maxScore);
        d.roundedValue = d.value.toFixed(2);
        d.score = +d.score;
        d.maxScore = formMetadata[d.code].maxScore;
        d.color = formMetadata[d.code].color;
      });
      let data = tempData;
      let grouped = d3
        .nest()
        .key((d) => d.code)
        .entries(data);

      let margin = {
        top: 10,
        right: 50,
        left: 300,
        bottom: 80,
      };

      let svg = d3
        .select(`#multilinechart-${this.id}`)
        .append("svg")
        .attr("width", this.width)
        .attr("height", this.height);

      let color = d3
        .scaleOrdinal()
        .domain(grouped.map((d) => d.key))
        .range(grouped.map((d) => formMetadata[d.key].lineColor));

      let formatMillisecond = d3.timeFormat(".%L"),
        formatSecond = d3.timeFormat(":%S"),
        formatMinute = d3.timeFormat("%I:%M"),
        formatHour = d3.timeFormat("%I %p"),
        formatDay = d3.timeFormat("%a %d"),
        formatWeek = d3.timeFormat("%b %d"),
        formatMonth = d3.timeFormat("%B"),
        formatYear = d3.timeFormat("%Y");

      // Define filter conditions
      function multiFormat(date) {
        return (
          d3.timeSecond(date) < date
            ? formatMillisecond
            : d3.timeMinute(date) < date
            ? formatSecond
            : d3.timeHour(date) < date
            ? formatMinute
            : d3.timeDay(date) < date
            ? formatHour
            : d3.timeMonth(date) < date
            ? d3.timeWeek(date) < date
              ? formatDay
              : formatWeek
            : formatMonth
        )(date);
      }
      function multiFormatYears(date) {
        return formatYear(date);
      }

      let x = d3
        .scaleTime()
        .domain([
          d3.timeDay.offset(d3.extent(data, (d) => d.date)[0], -1),
          d3.timeDay.offset(d3.extent(data, (d) => d.date)[1], 1),
        ])
        .nice()
        // .domain(d3.extent(data, d => d.date))
        .range([margin.left, this.width - margin.right]);

      let years = x.domain().map((d, i) => {
        if (i === 0) return d;

        return d3.timeYear.floor(d);
      });

      let xYears = d3.scaleTime().domain(x.domain()).range(x.range());
      // .nice()

      let y = d3
        .scaleLinear()
        .domain([0, 100])
        .nice()
        .range([this.height - margin.bottom, margin.top]);

      let valueline = d3
        .line()
        .x((d) => {
          return x(d.date);
        })
        .y((d) => {
          return y(d.value);
        })
        .defined((d) => d.value !== null);

      let xAxis = (g) =>
        g
          .attr("transform", `translate(0, ${this.height - margin.bottom})`)
          .call(
            d3.axisBottom(x).tickFormat(multiFormat)
            // .tickFormat(d3.timeFormat('%Y-%m-%d'))
            // .tickSize(10)
            // .ticks(+data.length)
          );
      // .call(g => g.select(".domain").remove())
      // .selectAll("text")
      //         .style("text-anchor", "end")
      //         .attr("dx", "-.3em")
      //         .attr("dy", ".55em")
      //         .attr("transform", "rotate(-25)")

      let xAxisYears = (g) =>
        g
          .attr(
            "transform",
            `translate(0, ${this.height - margin.bottom + 20})`
          )
          .call(
            d3
              .axisBottom(xYears)
              .tickFormat(multiFormatYears)
              // .ticks(years)
              .tickValues(years)
            // .tickFormat(d3.timeFormat('%Y-%m-%d'))
            // .tickSize(10)
            // .ticks(+data.length)
          )
          .call((g) => g.select(".domain").remove());
      // .selectAll("text")
      //     .style('font-size', 11)

      let yAxis = (g) =>
        g.attr("transform", `translate(${margin.left}, 0)`).call(
          d3.axisLeft(y).tickFormat((d) => {
            return d + "%";
          })
        );
      // .call(g => g.select(".domain").remove())

      svg.append("g").attr("class", "multiline-axis").call(xAxis);

      svg.append("g").attr("class", "multiline-axis").call(xAxisYears);

      svg.append("g").attr("class", "multiline-axis").call(yAxis);

      let tooltip = d3.select("#multilinechart-tooltip");

      grouped.forEach((d, i) => {
        let g = svg.append("g").attr("class", "group-" + i);
        g.append("path")
          .attr("class", "line-" + d.key)
          .attr("d", valueline(d.values))
          .attr("fill", "none")
          .attr("stroke-width", 1)
          .style("stroke", () => {
            return (d.color = color(d.key));
          });

        g.selectAll("circle")
          .data(d.values)
          .enter()
          .append("circle")
          .style("stroke", () => {
            return (d.color = d3.color(color(d.key)).darker(2));
          })
          .style("fill", () => {
            return (d.color = color(d.key));
          })
          .attr("class", "line-" + d.key)
          .attr("r", 5)
          .attr("cx", (d) => {
            return x(d.date);
          })
          .attr("cy", (d) => {
            return y(d.value);
          })
          .style("cursor", "pointer")
          .on("mouseover", (v) => {
            tooltip.select("#type").node().innerHTML = d.key;
            tooltip.select("#date").node().innerHTML = dateFormat(v.date);
            tooltip
              .select("#value")
              .node().innerHTML = `${v.score} (av ${v.maxScore} : ${v.roundedValue}%)`;
            tooltip.node().classList.remove("hidden");
          })
          .on("mousemove", () => {
            tooltip.style("top", event.pageY - 10 + "px");
            tooltip.style("left", event.pageX + 10 + "px");
          })
          .on("mouseout", () => {
            tooltip.node().classList.add("hidden");
          });

        svg
          .append("text")
          .attr("x", 5)
          .attr("y", margin.top + 5 + i * 16)
          .attr("class", "multiline-legend")
          .attr("cursor", "pointer")
          .attr("id", "multiline-legend-" + d.key)
          .style("fill", () => {
            return (d.color = color(d.key));
          })
          .text(() => {
            return (
              (formMetadata[d.key].hasOwnProperty("shortCode")
                ? formMetadata[d.key].shortCode
                : d.key) +
              " " +
              formMetadata[d.key].purposeText
            );
          })
          .on("click", () => {
            this.visible[d.key] = !this.visible[d.key];
            d3.select("#multiline-legend-" + d.key).style(
              "text-decoration",
              this.visible[d.key] ? "none" : "line-through"
            );
            d3.selectAll(".line-" + d.key).style(
              "visibility",
              this.visible[d.key] ? "visible" : "hidden"
            );
          });
      });
    },
  },
};
</script>

<style>
.multiline-axis path,
.multiline-axis line {
  fill: none;
  stroke: #333333;
  stroke-width: 1;
  shape-rendering: crispEdges;
}

.multiline-legend {
  font-size: 13px;
  font-weight: bold;
  text-anchor: start;
}
</style>
