<template>
  <div class="flex justify-between">
    <div
      class="border-b border-r flex items-center justify-center w-12 h-8 font-bold bg-grey-lighter"
    >
      {{ form.id }}
    </div>
    <div class="border-b border-r flex items-stretch justify-center flex-1">
      <input
        type="text"
        class="w-full bg-grey-lighter"
        name="form_code"
        :id="'form_code_' + form.id"
        v-model="form.code"
      />
    </div>
    <div class="border-b border-r flex items-stretch justify-center flex-1">
      <input
        type="text"
        class="w-full bg-grey-lighter"
        name="form_title"
        :id="'form_title_' + form.id"
        v-model="form.title"
      />
    </div>
    <div class="border-b flex items-stretch justify-center flex-1">
      <select
        name="form_status"
        class="w-full"
        :class="statusColor()"
        :id="'form_status_' + form.id"
        v-model="form.status"
      >
        <option :value="0">Inaktiverad</option>
        <option :value="1">Publicerad</option>
        <option :value="2">Draft</option>
      </select>
    </div>
    <div class="border-b flex items-center justify-center w-8 h-8" :class="checkBg">
      <font-awesome-icon
        icon="check"
        class="cursor-pointer"
        @click="saveForm()"
      ></font-awesome-icon>
    </div>
    <div class="border-b flex items-center justify-center w-8 h-8">
      <font-awesome-icon
        icon="trash-alt"
        class="cursor-pointer"
        @click="$emit('removeForm', form)"
      ></font-awesome-icon>
    </div>
  </div>
</template>
<script>
export default {
  name: "Forms",
  data() {
    return {
      saved: false,
      changed: false
    };
  },
  props: {
    form: Object,
  },
  watch: {
    form: {
      handler() {
        this.changed = true;
        this.saved = false;
      },
      deep: true,
    },
  },
  computed: {
    checkBg() {
      if (this.saved == true) {
        return "bg-green-lighter";
      }
      if (this.changed == true) {
        return "bg-yellow-lighter";
      }

      return "";
    }
  },
  methods: {
    statusColor() {
      if (this.form.status == 0) {
        return "bg-red-lighter";
      } else if (this.form.status == 1) {
        return "bg-green-lighter";
      } else {
        return "bg-yellow-lighter";
      }
    },
    valid() {
      if (
        this.form.status != null &&
        this.form.code != "" &&
        this.form.title != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    saveForm() {
      if (this.changed && this.valid()) {
        this.$store.dispatch("saveFormAdmin", this.form).then((response) => {
          this.changed = false;
          this.form.id = response;
          this.saved = true;
        }).catch((error) => {
          alert(error.response.data.message);
          this.saved = false;
        });
      }
    },
  },
};
</script>
