const caretakers = [
  { id: 1773944, name: "Psykiatrin i Halland" },
  { id: 8345665, name: "Psykiatrin i Varberg" }
];
const formMetadata = {
  MADRS: {
    maxScore: 54,
    sectionChartMax: 54,
    color: "#1976D2",
    lineColor: "#006400",
    purposeText: ""
  },
  AUDIT: {
    maxScore: 40,
    sectionChartMax: 4,
    color: "#1976D2",
    lineColor: "#000080",
    purposeText: ""
  },
  DUDIT: {
    maxScore: 44,
    sectionChartMax: 4,
    color: "#1976D2",
    lineColor: "#b03060",
    purposeText: ""
  },
  WHODAS36: {
    maxScore: 98,
    sectionChartMax: 100,
    color: "#1976D2",
    lineColor: "#ee3333",
    purposeText: "Funktion"
  },
  WHODAS12: {
    maxScore: 112,
    sectionChartMax: 100,
    color: "#1976D2",
    lineColor: "#ee3333",
    purposeText: "Funktion"
  },
  DSM5: {
    maxScore: 77,
    sectionChartMax: 4,
    color: "#1976D2",
    lineColor: "#00bfff",
    purposeText: "Psykiatriska symtom"
  },
  LPFSBF: {
    shortCode: "LPFS",
    maxScore: 36,
    sectionChartMax: 36,
    color: "#1976D2",
    lineColor: "#ee11ee",
    purposeText: "Personlighetsrelaterad funktion"
  },
  ASRS: {
    maxScore: 80,
    sectionChartMax: 80,
    color: "#1976D2",
    lineColor: "#11dd11",
    purposeText: "ADHD"
  },
  PID5: {
    maxScore: 100,
    sectionChartMax: 100,
    color: "#1976D2",
    lineColor: "#b236ff",
    purposeText: ""
  },
  RAADS14: {
    maxScore: 42,
    sectionChartMax: 42,
    color: "#1976D2",
    lineColor: "#eeaa22",
    purposeText: ""
  },
  RAADS80: {
    maxScore: 240,
    sectionChartMax: 240,
    color: "#1976D1",
    lineColor: "#eeaa21",
    purposeText: ""
  },
  CORE10: {
    maxScore: 40,
    sectionChartMax: 40,
    color: "#1976D2",
    lineColor: "#99ddee",
    purposeText: ""
  },
  COREOM: {
    maxScore: 40,
    sectionChartMax: 40,
    color: "#1976D2",
    lineColor: "#FF00D4",
    purposeText: ""
  },
  PCL5: {
    maxScore: 80,
    sectionChartMax: 80,
    color: "#1976D2",
    lineColor: "#5362D6",
    purposeText: ""
  }
};

export { caretakers, formMetadata };
