<template>
    <div>
        <div id="result-chart"></div>
    </div>
</template>

<script>
import * as d3 from 'd3'

export default {
    name: 'ResultStats',
    props: {
        data: {
            type: Array,
            default () {
                return []
            },
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 200,
        },
        labels: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            chart: {}
        }
    },
    watch: {
        width: {
            immediate: false,
            handler (newval) {
                this.width = newval
                this.updateChart()
            }
        }
    },
    mounted () {
        this.createChart()
    },
    methods: {
        createChart () {
            this.chart.svg = d3.select('#result-chart')
                .append('svg')
                .attr('class', 'result-chart-container')
                .attr('width', this.width)
                .attr('height', this.height)

            this.chart.margin = { top: 5, right: 5, bottom: 20, left: this.labels ? 120 : 20 }
            this.chart.width = +this.width - this.chart.margin.left - this.chart.margin.right
            this.chart.height = +this.height - this.chart.margin.top - this.chart.margin.bottom

            this.chart.g = this.chart.svg.append('g')
                .attr('class', 'result-chart-group')
                .attr('transform', 'translate(' + this.chart.margin.left + ',' + this.chart.margin.top + ')')

            this.chart.x = d3.scalePoint().range([0, this.chart.width])
            this.chart.x.domain([0, 6, 12, 18, 24])

            this.chart.y = d3.scaleLinear().range([this.chart.height, 0])
            this.chart.y.domain([0, d3.max(this.data, d => { return d3.max(d.data, s => { return s.value }) } )])

            this.chart.z = d3.scaleOrdinal(d3.schemeCategory10)
            this.chart.z.domain(this.data.map(d => { return d.label }))

            this.chart.line = d3.line()
                .x(d => { return this.chart.x(d.month) })
                .y(d => { return this.chart.y(d.value) })

            this.chart.g.append('g')
                .attr('class', 'axis axis--x')
                .attr('transform', 'translate(0,' + this.chart.height + ')')
                .call(d3.axisBottom(this.chart.x))

            this.chart.g.append('g')
                .attr("class", "axis axis--y")
                .call(d3.axisLeft(this.chart.y).tickSize(0))

            this.updateChart()
        },
        updateChart () {
            this.chart.width = +this.width - this.chart.margin.left - this.chart.margin.right
            this.chart.height = +this.height - this.chart.margin.top - this.chart.margin.bottom

            d3.select('.result-chart-container')
                .attr('width', this.width)
                .attr('height', this.height)

            d3.select('.result-chart-group')
                .attr('transform', 'translate(' + this.chart.margin.left + ',' + this.chart.margin.top + ')')

            this.chart.x = d3.scalePoint().range([0, this.chart.width])
            this.chart.x.domain([0, 6, 12, 18, 24])

            this.chart.y = d3.scaleLinear().range([this.chart.height, 0])
            this.chart.y.domain([0, d3.max(this.data, d => { return d3.max(d.data, s => { return s.value }) } )])

            this.chart.z = d3.scaleOrdinal(d3.schemeCategory10)
            this.chart.z.domain(this.data.map(d => { return d.label }))

            d3.selectAll('.scalegroup').remove()

            d3.select('g.axis.axis--x')
                .attr('transform', 'translate(0,' + this.chart.height + ')')
                .call(d3.axisBottom(this.chart.x))

            d3.select('g.axis.axis--y')
                .call(d3.axisLeft(this.chart.y).tickSize(0))

            const scaleG = this.chart.g.selectAll('.scalegroup')
                .data(this.data)
                .enter()
                .append('g')
                .attr('class', 'scalegroup')

            scaleG.selectAll('path').remove()
            scaleG.selectAll('text').remove()

            scaleG
                .append('path')
                .attr('class', 'line')
                .attr('d', d => { return this.chart.line(d.data) })
                .style('stroke', d => { return this.chart.z(d.label) })
                .style('stroke-width', 2)
                .attr('fill', 'none')

            if (this.labels == true) {
                scaleG
                    .append("text")
                    .datum(d => { return { id: d.label, value: d.data[0] }; })
                    .attr("transform", d => { return "translate(" + this.chart.x(0) + "," + this.chart.y(d.value.value) + ")"; })
                    .attr("x", -50)
                    .attr("dy", "0.35em")
                    .style("font", ".8rem sans-serif")
                    .text(d => { return d.id; });
            }

        }
    }
}
</script>