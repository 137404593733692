<template>
  <div>
    <StartInfo
      :sInfo="this.id ? this.form.response_data.Info : null"
      :patientId="patientId"
    />
    <A :sAForm="this.id ? this.form.response_data.AForm : null" />
    <HI :sHIForm="this.id ? this.form.response_data.HIForm : null" />
    <D3 :sDForm="this.id ? this.form.response_data.DForm : null" />
    <Bedomning
      :sBedomning="this.id ? this.form.response_data.Bedomning : null"
      :patientId="patientId"
      :id="id"
    />
  </div>
</template>

<script>
import StartInfo from "./StartInfo.vue";
import A from "./A.vue";
import HI from "./H-I.vue";
import D3 from "./D3.vue";
import Bedomning from "./Bedomning.vue";
export default {
  name: "DIVA5",
  components: {
    A,
    StartInfo,
    HI,
    D3,
    Bedomning,
  },
  props: {
    patientId: Number,
    id: Number,
    form: Object,
  },
  data() {
    return {};
  },
};
</script>
