<template>
  <div v-if="selectedPatient" class="flex">
    <div v-if="!loading" class="w-full mb-6" id="#overview-form-scores">
      <div class="bg-white p-2">
        <div class="flex flex-row justify-between items-start">
          <div>
            <span class="text-xl font-semibold"
              >{{ fullName }}
              <span class="text-grey">(Id {{ patient.id }})</span></span
            >
          </div>
          <div class="border-b-2 border-white hover:border-blue cursor-pointer">
            <BaseSimpleButton @click.native="returnToReferrals()">{{
              $t("button.close")
            }}</BaseSimpleButton>
          </div>
        </div>

        <div class="pt-2">
          <PatientOverviewFormHistory
            v-show="scorehistory.length > 0"
            :scorehistory="scorehistory"
            @goto="goto"
          />
        </div>
      </div>

      <div v-if="consents.length > 0" class="bg-white p-2 mt-2">
        <patient-consents :consents="consents" />
      </div>

      <div
        v-if="referralform != null"
        class="bg-white mt-2 p-2 lg:flex lg:items-end"
      >
        <div class="w-full lg:w-3/5">
          <div class="text-grey-dark">
            {{ formatDate(referralform.created) }}
          </div>
          <div class="font-bold mb-2">{{ referralform.title }}</div>
          <div v-if="referralform.sections">
            <div v-for="(section, key) in referralform.sections" :key="key">
              <div
                class="ml-4 mb-4"
                v-for="(question, key) in section.questions"
                :key="key"
              >
                <div class="text-grey-dark">{{ question.title }}</div>
                <div v-if="question.answer">
                  <div class="ml-2">{{ question.answer }}</div>
                </div>
                <div v-if="question.answers">
                  <div
                    class="ml-2"
                    v-for="(answer, key) in question.answers"
                    :key="key"
                  >
                    {{ answer.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-2/5">
          <component
            :is="patientActivity"
            :patientId="selectedPatient"
            @setPatientActivity="setPatientActivity"
            @resetPatientActivity="resetPatientActivity"
            @setPatientRejected="setPatientRejected"
            @setPatientAccepted="setPatientAccepted"
            @downloadPdf="downloadPdf"
            @createJournalText="createJournalText"
          >
          </component>
        </div>
      </div>
      <div v-else>
        <div class="bg-white p-2 mt-2 text-center">
          <span class="text-lg text-red">{{
            $t("info.ownreferral_unanswered")
          }}</span>
          <component
            v-if="patient.form_responses.length > 0"
            :is="'PatientActivities'"
            :patientId="selectedPatient"
            @downloadPdf="downloadPdf"
            @createJournalText="createJournalText"
          >
          </component>
        </div>
      </div>

      <div class="bg-white p-2 mt-2">
        <patient-overview-graphs :groups="graphs" />
      </div>

      <div class="w-full bg-white mt-2 p-2 flex flex-col justify-center">
        <div class="w-full" v-if="userForms.DIVA5">
          <Summary :form="userForms.DIVA5.responseData" />
        </div>
        <div class="w-full bg-white mt-2 p-2 flex justify-center">
          <router-link
            v-if="userForms.DIVA5 && userForms.DIVA5.responses[1]"
            class="
              w-3/4
              max-w-xs
              rounded-lg
              px-4
              py-4
              text-center
              flex
              items-center
              justify-center
              text-lg
              tracking-wide
              bg-green
              text-white
              no-underline
              mr-2
            "
            :to="{
              name: 'user_form',
              params: {
                patientId: selectedPatient,
                form: 'DIVA5',
                id: userForms.DIVA5.responses[1],
              },
            }"
          >
            Öppna DIVA-5
          </router-link>
          <router-link
            v-if="userForms.DIVA5 && userForms.DIVA5.responses[2]"
            class="
              w-3/4
              max-w-xs
              rounded-lg
              px-4
              py-4
              text-center
              flex
              items-center
              justify-center
              text-lg
              tracking-wide
              bg-green
              text-white
              no-underline
              mr-2
            "
            :to="{
              name: 'user_form',
              params: {
                patientId: selectedPatient,
                form: 'DIVA5',
                id: userForms.DIVA5.responses[2],
              },
            }"
          >
            Öppna anhörigintervju DIVA-5
          </router-link>
          <router-link
            v-if="userForms.DIVA5 && userForms.DIVA5.responses[3]"
            class="
              w-3/4
              max-w-xs
              rounded-lg
              px-4
              py-4
              text-center
              flex
              items-center
              justify-center
              text-lg
              tracking-wide
              bg-green
              text-white
              no-underline
              mr-2
            "
            :to="{
              name: 'user_form',
              params: {
                patientId: selectedPatient,
                form: 'DIVA5',
                id: userForms.DIVA5.responses[3],
              },
            }"
          >
            Öppna patient- och anhörigintervju DIVA-5
          </router-link>
          <router-link
            class="
              w-3/4
              max-w-xs
              rounded-lg
              px-4
              py-4
              text-center
              flex
              items-center
              justify-center
              text-lg
              tracking-wide
              bg-green
              text-white
              no-underline
            "
            :to="{
              name: 'user_form',
              params: { patientId: selectedPatient, form: 'DIVA5', id: null },
            }"
          >
            Ny DIVA-5
          </router-link>
        </div>
      </div>

      <div v-if="patient.form_responses != null">
        <div
          class="bg-white mt-2 p-2"
          v-for="(form, key) in patient.form_responses"
          :key="key"
          :href="`#${form.code}`"
          :id="`${form.code}`"
        >
          <div class="flex justify-between">
            <div class="mx-3">
              <div class="text-grey-dark">{{ formatDate(form.created) }}</div>
              <div class="font-bold">
                {{ form.title }} - {{ formShortCode(form.code) }}
              </div>
            </div>
            <div>
              <span
                title="Tillbaka till toppen"
                @click="goto('#overview-form-scores')"
              >
                <font-awesome-icon
                  icon="chevron-up"
                  class="cursor-pointer mx-2 text-blue align-middle"
                ></font-awesome-icon>
              </span>
            </div>
          </div>

          <div class="text-center text-2xl">
            <FormScore :form="form" />
          </div>

          <div class="flex justify-around">
            <button
              v-if="formAnswersLoaded.indexOf(form.response_id) <= -1"
              class="block text-center px-4 py-1 mt-8 mb-2"
              @click="toggleAnswersLoaded(form.response_id)"
            >
              Visa svar
            </button>
          </div>

          <FormView
            v-if="formAnswersLoaded.indexOf(form.response_id) > -1"
            @toggleAnswersLoaded="toggleAnswersLoaded"
            :key="`formAnswers_${form.response_id}`"
            :responseId="form.response_id"
            :includeHeader="false"
          />
        </div>
      </div>
      <portal to="modals">
        <modal
          name="JournalModal"
          width="800px"
          height="auto"
          :scrollable="true"
        >
          <div
            class="
              relative
              p-4
              bg-white
              w-full
              max-w-lg
              m-auto
              rounded-lg
              shadow
            "
          >
            <JournalText
              :key="patient.id"
              @hideJournalText="hideJournalText"
              :patient="patient"
              :userForms="userForms"
            />
          </div>
        </modal>
      </portal>
    </div>

    <div v-else class="w-full mb-6">
      <div class="bg-white p-2 w-full text-center pt-8">
        <font-awesome-icon
          icon="spinner"
          class="mx-2 text-blue align-middle"
          spin
          size="2x"
        ></font-awesome-icon>
      </div>
    </div>
  </div>
</template>

<script>
import PatientOverviewFormScores from "./PatientOverviewFormScores.vue";
import PatientOverviewFormHistory from "./PatientOverviewFormHistory.vue";
import PatientActivities from "./PatientActivities.vue";
import PatientReject from "./PatientReject.vue";
import PatientAccept from "./PatientAccept.vue";
import PatientComplementaryForms from "./PatientComplementaryForms.vue";
import FormView from "./FormView.vue";
import FormScore from "./FormScore.vue";
import JournalText from "./helpers/JournalText.vue";
import { localDate } from "../utils/dateformat";
import { apiFileRequest } from "../store/api.js";
import { formMetadata } from "../utils/metadata.js";
import Summary from "@/components/forms/diva5/Summary.vue";
import PatientConsents from "@/components/PatientConsents.vue";
import PatientOverviewGraphs from "@/components/charts/PatientOverviewGraphs.vue";

export default {
  name: "PatientView",
  props: ["headers"],
  components: {
    PatientOverviewFormScores,
    PatientOverviewFormHistory,
    PatientActivities,
    PatientReject,
    PatientAccept,
    PatientComplementaryForms,
    FormView,
    FormScore,
    JournalText,
    Summary,
    PatientConsents,
    PatientOverviewGraphs
  },
  data() {
    return {
      loading: true,
      extraforms: {},
      reject: false,
      accept: false,
      complementaryforms: false,
      showFormAnswers: [],
      answersLoaded: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadPatientOverview();
  },
  computed: {
    formAnswersLoaded() {
      return this.answersLoaded;
    },
    selectedPatient() {
      return this.$store.getters.selectedPatient;
    },
    patient() {
      if (!this.$store.getters.selectedPatientData) {
        return null;
      }
      return this.$store.getters.selectedPatientData.patient;
    },
    referralform() {
      return this.$store.getters.selectedPatientData.referralform;
    },
    scorehistory() {
      return this.$store.getters.selectedPatientData.scorehistory;
    },
    userForms() {
      return this.$store.getters.selectedPatientData.userForms;
    },
    consents() {
      return this.$store.getters.selectedPatientData.consents;
    },
    graphs() {
      return this.$store.getters.selectedPatientData.graphs;
    },
    patients() {
      return null;
      // return this.$store.getters.currentList
    },
    fullName() {
      return `${this.patient.first_name} ${this.patient.last_name}`;
    },
    patientHeaders() {
      return this.headers.filter((header) => {
        return header.patient && header.patient === true;
      });
    },
    patientActivity() {
      if (this.reject) {
        return "PatientReject";
      }
      if (this.complementaryforms) {
        return "PatientComplementaryForms";
      }
      if (this.accept) {
        return "PatientAccept";
      }

      return "PatientActivities";
    },
  },
  methods: {
    formShortCode(code) {
      if (!formMetadata[code]) return null;

      return formMetadata[code].hasOwnProperty("shortCode")
        ? formMetadata[code].shortCode
        : code;
    },
    toggleAnswersLoaded(responseId) {
      let index = this.answersLoaded.indexOf(responseId);
      if (index > -1) {
        this.answersLoaded.splice(index, 1);
      } else {
        this.answersLoaded.push(responseId);
      }
    },
    changePatient(evt, patientId) {
      this.loading = true;
      this.$store.dispatch("setSelectedPatient", patientId);
      this.loadPatientOverview();
    },
    activePatientRow(patientId) {
      return patientId === this.$store.getters.selectedPatient
        ? "bg-white font-bold"
        : "bg-grey-lighter font-normal";
    },
    returnToReferrals() {
      this.$store.dispatch("setSelectedPatient", null);
    },
    loadPatientOverview() {
      this.$store
        .dispatch("loadPatientOverview", this.selectedPatient)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.$store.dispatch("setSelectedPatient", null);
        });
    },
    prioClass(item) {
      if (item.priority === 1) {
        return "flex items-center justify-center rounded-full w-8 h-8 bg-red text-white text-center font-bold align-middle";
      }
      return "flex items-center justify-center rounded-full border border-indigo w-8 h-8 text-grey-dark text-center font-bold align-middle";
    },
    goto(formId) {
      document.getElementById(`${formId}`).scrollIntoView();
    },
    setPatientActivity(type) {
      this[type] = true;
    },
    resetPatientActivity(type) {
      this[type] = false;
    },
    formatDate(date) {
      return date ? localDate(Date.parse(date)) : null;
    },
    setPatientRejected(patientId) {
      this.$emit("setPatientRejected", patientId);
    },
    setPatientAccepted(patientId) {
      this.$emit("setPatientAccepted", patientId);
    },
    loadFormAnswers(responseId) {
      this.$store.dispatch("loadFormAnswers", responseId).then((data) => {
        this.answersLoded.push(responseId);
        return data;
      });
    },
    downloadPdf() {
      const url = "patients/pdf/" + this.patient.id;

      apiFileRequest(url).then((blob) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        window.open(data, "_blank");
      });
    },
    createJournalText() {
      this.$modal.show("JournalModal");
    },
    hideJournalText() {
      this.$modal.hide("JournalModal");
    },
  },
};
</script>
