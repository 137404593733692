<template>
    <div>
        <input
            min="0"
            type="text"
            @keyup="filterValue"
            v-on:keyup.enter="$emit('enter')"
            :maxlength="max"
            :placeholder="placeholder"
            class="w-64 rounded-lg border border-grey-light px-2 py-1 text-left tracking-normal focus:outline-none"
            :class="validityClass"
            v-model="filteredSsn"
        />
    </div>
</template>

<script>
    import { SwedishSsnValidator } from '../../utils/validators.js'

    export default {
        name: "BaseInputSsn",
        props: ['value'],
        data () {
            return {
                ssn: null,
                filteredSsn: null,
                century: false,
                valid: null
            }
        },
        watch: {
            filteredSsn: {
                immediate: true,
                handler (value) {
                    this.$emit('input', value);
                }
            },
            valid: {
                immediate: true,
                handler (value) {
                    this.$emit('update:validSsn', value)
                }
            }
        },
        methods: {
            filterValue (e) {
                let value = e.target.value.replace('-', '');

                this.filteredSsn = value.replace(/D/g, '')

                if (this.filteredSsn.length > 2) {
                    this.century = ['19', '20'].indexOf(this.filteredSsn.substr(0, 2)) >= 0 ? true : false
                }

                if (this.filteredSsn.length == (this.century ? 8 : 6) && e.keyCode !== 8) {
                    this.filteredSsn = `${this.filteredSsn.substr(0, (this.century ? 8 : 6))}${this.filteredSsn.substr((this.century ? 8 : 6))}`
                }

                if (this.filteredSsn.length > (this.century ? 8 : 6) && this.filteredSsn.indexOf('-') == -1) {
                    this.filteredSsn = `${this.filteredSsn.substr(0, (this.century ? 8 : 6))}${this.filteredSsn.substr((this.century ? 8 : 6))}`
                }
                this.ssn = this.filteredSsn.replace(/D/g, '')

                this.valid = SwedishSsnValidator(this.ssn)


            }
        },
        computed: {
            placeholder () {
                return this.$t('placeholder.input.ssn')
            },
            max () {
                return this.century ? 13 : 11
            },
            validityClass () {
                return {
                    'border-2' : this.valid,
                    'border-green-dark' : this.valid,
                    'bg-green-lightest' : this.valid,
                    'border-red-dark' : this.valid === false,
                    'bg-red-lightest' : this.valid === false,
                }
            }
        }
    }
</script>

