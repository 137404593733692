<template>
    <div :id="id"></div>
</template>

<script>
import * as d3 from 'd3';

export default {
    name: 'FormScoreChart',
    props: ['id', 'width', 'score', 'maxScore', 'code', 'color'],
    watch: {
        width: {
            immediate: false,
            handler (val) {
              if(val !== null)
                  this.createChart()
            }
        }
    },
    computed: {
        chart () {
            return {
                width: this.width,
                margin: {top: 10, right: 10, bottom: 10, left: 10},
                color: d3.scaleOrdinal().range(this.color ? [this.color, '#CACACA'] : ["#42A5F5", "#CACACA", "#64B5F6", "#2196F3", "#1E88E5", "#1976D2"]),
                radius: this.width / 2
            }
        }
    },
    methods: {
        createChart () {
            d3.select(`svgcontainer_${this.id}`).remove()

            let leftover = this.maxScore - this.score
            let percent = Math.round((this.score / this.maxScore) * 100, 0)
            let leftoverPercent = Math.round((leftover / this.maxScore) * 100, 0)
            let data = [percent, leftoverPercent]

            let svg = d3.select(`#${this.id}`)
                .append('svg')
                .attr('class', `svgcontainer_${this.id}`)
                .attr('width', this.chart.width)
                .attr('height', this.chart.width)
                .append('g')
                .attr('transform', 'translate('+this.chart.width / 2+', '+this.chart.width / 2+')')

            let pie = d3.pie()
                .value(function (d) { return d })
                .sort(null)

            let arc = d3.arc()
                .outerRadius(this.chart.radius)
                .innerRadius(this.chart.radius * 0.9)

            svg.append('g').attr('class', 'slices')

            svg.select('.slices')
                .datum(data).selectAll('path')
                .data(pie)
              .enter().append('path')
                .attr('fill', (d, i) => { return this.chart.color(i+1) })
                .attr('d', arc)

            svg.append('text')
                .attr("text-anchor", "middle")
                .attr('dy', 0)
                .attr('font-size', 13)
                .attr('opacity', .8)
                .text(this.code)

            svg.append('text')
                .attr("text-anchor", "middle")
                .attr('dy', 18)
                .attr('font-size', 19)
                .text(this.score)
        }
    }
}
</script>

<style>

</style>