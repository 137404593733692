<template>
    <div class="w-full text-center">
        <!-- <BaseButton v-if="!accepted" class="py-2" @click.native="$emit('setPatientActivity', 'accept')">Skriv in</BaseButton> -->
        <!-- <BaseButton v-if="!accepted" class="py-2" @click.native="$emit('setPatientActivity', 'reject')">Återremittera</BaseButton> -->
        <!-- <BaseButton class="py-2" @click.native="$emit('setPatientActivity', 'complementaryforms')">Ställ ytterligare frågor</BaseButton> -->
        <BaseButton class="py-2" @click.native="$emit('downloadPdf')">Spara som PDF</BaseButton>
        <BaseButton class="py-2" @click.native="$emit('createJournalText')">Skapa journaltext</BaseButton>
    </div>
</template>

<script>
export default {
    name: 'PatientActivities',
    computed: {
        accepted () {
            return this.$store.getters.selectedPatientData.patient.approved_date != null ? true : false
        }
    }

}
</script>