<template>
    <div :id="id" class="mt-4 mr-8"></div>
</template>

<script>
import * as d3 from 'd3'
import { formMetadata } from '../../utils/metadata.js'

export default {
    name: 'FormSectionsChart',
    props: ['width', 'code', 'height', 'id', 'sections'],
    data () {
        return {
            vertical: true,
        }
    },
    watch: {
        width: {
            immediate: true,
            handler (val) {
                if(val !== null)
                    this.createChart()
            }
        }
    },
    computed: {
        chart () {
            return {
                margin: { top: 10, right: 10, bottom: 160, left: 30 },
                width: this.width,
                height: this.height,

            }
        }
    },
    methods: {
        createChart () {
            d3.select(`#${this.id}`).select('svg').remove()

            let x = d3.scaleBand().range([0, +this.chart.width]).padding(.1)
                .domain(this.sections.map(d => d.title ))

            let y = d3.scaleLinear().range([this.chart.height, 0])
                .domain([0, formMetadata[this.code].sectionChartMax])

            let color = d3.scaleOrdinal(d3.schemeSet2).domain(d3.map(this.selections, d => d.title))

            let svg = d3.select(`#${this.id}`)
                .append('svg')
                .attr('width', this.chart.width + this.chart.margin.left + this.chart.margin.right)
                .attr('height', this.chart.height + this.chart.margin.top + this.chart.margin.bottom)
                .append('g')
                .attr('transform', 'translate('+ this.chart.margin.left +', '+ this.chart.margin.top +')')

            svg.selectAll('.bar')
                .data(this.sections)
                .enter().append("rect")
                    .attr("class", 'bar')
                    .attr("x", d => x(d.title))
                    .attr("width", x.bandwidth())
                    .attr("y", d => y(d.score))
                    .attr("height", d => (this.chart.height - y(d.score)))
                    .attr('fill', d => color(d.title) )

            svg.append("g")
                .attr("transform", "translate(0," + this.chart.height + ")")
                .call(d3.axisBottom(x))
                .selectAll("text")
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-55)")

            svg.append("g")
                .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('d')));

        }
    }
}
</script>
