<template>
    <div class="bg-white rounded rounded-lg">
        <div class="bg-grey-light text-grey-darkest rounded-t-lg text-lg text-center py-2">{{ title }}</div>
        <div v-if="data" :id="`linechart-${id}`"></div>
    </div>
</template>

<script>
import * as d3 from 'd3';

export default {
    name: 'LineChart',
    props: ['id', 'data', 'propwidth', 'title'],
    data () {
        return {
        }
    },
    watch: {
        propwidth: {
            immediate: false,
            handler: function(value) {
                if (value) {
                    this.createChart()
                }
            }
        }
    },
    mounted () {
        this.createChart()
    },
    computed: {
        width () {
            return this.propwidth ? this.propwidth : this.$el.offsetWidth
        },
        height () {
            return Math.round((this.width/16) * 9) - 20
        }
    },
    methods: {
        createChart () {
            d3.select(`#linechart-${this.id}`).select("svg").remove()

            let parseMonth = d3.timeParse('%Y-%m')

            let data = this.data.map(d => {
                return {
                    month: parseMonth(d.month),
                    value: +d.value
                }
            })

            let margin = {
                top: 10,
                right: 30,
                left: 30,
                bottom: 20,
            }

            let svg = d3.select(`#linechart-${this.id}`)
                .append('svg')
                .attr('width', this.width)
                .attr('height', this.height)

            let colors = d3.scaleOrdinal(d3.schemePastel1)

            let x = d3.scaleTime()
                .domain(d3.extent(data, d => d.month))
                .range([margin.left, this.width - margin.right])

            let y = d3.scaleLinear()
                .domain([0, d3.max(data, d => d.value)]).nice()
                .range([this.height - margin.bottom, margin.top])


            let valueline = d3.line()
                .x(d => { return x(d.month) })
                .y(d => { return y(d.value) })

            let xAxis = g => g
                .attr("transform", `translate(0, ${this.height - margin.bottom})`)
                .call(d3.axisBottom(x)
                    .tickFormat(d3.timeFormat('%Y-%m'))
                    .tickSize(10)
                    .ticks(data.length)
                )

            let yAxis = g => g
                .attr("transform", `translate(${margin.left}, 0)`)
                .call(d3.axisLeft(y))
                .call(g => g.select(".domain").remove())

            svg.append("g")
                .call(xAxis);

            svg.append("g")
                .call(yAxis);

            svg
                .append('g')
                .append('path')
                .data([data])
                .attr('d', valueline)
                .attr('fill', 'none')
                .attr('stroke-width', 2)
                .attr('stroke', (d, i) => colors(i))

        }
    }
}
</script>
