<template>
    <div class="w-full">
        <div v-if="!editing" class="font-bold pb-2">{{ title }}</div>

        <div @click="selectOption(key)"
            class="border-t-2 border-grey-light py-2 w-full"
            v-for="(option, key) in options"
            :key="key"
        >
            <span :class="selected === option.value ? '-mr-image' : ''">{{ option.label }}</span>
            <img class="float-right -m-1" v-show="selected === option.value" src="/images/checkmark.png" />
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseSelect',
    props: {
        formId: String,
        id: Number,
        title: String,
        options: Array,
        answer: {
            type: String|Number,
            default: null
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            selected: this.answer ? this.answer : null
        }
    },
    methods: {
        selectOption (key) {
            let value = this.options[key].value
            let label = this.options[key].label

            this.selected = value

            this.$store.dispatch('answer', {
                formId: this.formId,
                id: this.id,
                value: value,
                label: label
            })
        }
    }
}
</script>