<template>
  <div class="container mx-auto bg-white">
    <Header title="Administrera användare" />

    <div v-if="!loaded" class="w-full h-64 flex content-center justify-center">
      <div>
        <font-awesome-icon icon="spinner" spin></font-awesome-icon>
        <span class="pl-2">Laddar...</span>
      </div>
    </div>

    <div class="px-4 py-4" v-else>
      <div class="my-2 flex justify-between">
        <button
          class="rounded border-gray bg-green-lighter hover:bg-green-light px-2 py-1"
          @click="{ includeDeleted = !includeDeleted, loadUsers() }"
        >{{ includeDeleted ? 'Göm' : 'Visa' }} inaktiverade</button>
        <div class="flex justify-start content-end">
          <span class="block w-5 h-5 bg-red-lighter"></span>
          <span class="text-sm">= Inaktiverad</span>
        </div>
      </div>

      <table class="w-full">
        <thead>
          <tr>
            <th></th>
            <th class="text-left">Förnamn</th>
            <th class="text-left">Efternamn</th>
            <th class="text-left">Titel</th>
            <th class="text-left">Email</th>
            <th class="text-left">Telefon</th>
            <th class="text-left">HSAID</th>
          </tr>
        </thead>
        <tbody v-if="users">
          <tr
            v-for="item in availableUsers"
            :key="item.id"
            :class="item.deleted ? 'bg-red-lighter' : ''"
          >
            <td class="pb-1" title="Redigera">
              <font-awesome-icon
                @click="editUser(item)"
                icon="pen"
                class="mx-2 text-blue align-middle cursor-pointer hover:text-blue-darker"
              ></font-awesome-icon>
            </td>
            <td class="text-left">{{ item.first_name }}</td>
            <td class="text-left">{{ item.last_name }}</td>
            <td class="text-left">{{ item.title }}</td>
            <td class="text-left">{{ item.email }}</td>
            <td class="text-left">{{ item.phone }}</td>
            <td class="text-left">{{ item.hsaid }}</td>
          </tr>
        </tbody>
      </table>
      <div class="ml-4 mt-6">
        <button
          @click="addUser"
          type="button"
          class="block px-2 py-2 rounded rounded-lg text-lg bg-green border border-white text-white"
        >Lägg till</button>
      </div>
    </div>

    <portal to="modals">
      <modal name="EditUserModal" height="auto" width="800px" :scrollable="true">
        <div class="relative p-4 bg-white w-full rounded rounded-lg shadow">
          <form>
            <div class="mb-2">
              <label class="block text-grey-darker text-sm font-bold mb-2" for="first_name">Förnamn</label>
              <input
                v-model="user.first_name"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="first_name"
                type="text"
                placeholder
              />
            </div>

            <div class="mb-2">
              <label class="block text-grey-darker text-sm font-bold mb-2" for="last_name">Efternamn</label>
              <input
                v-model="user.last_name"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="last_name"
                type="text"
                placeholder
              />
            </div>

            <div class="mb-2">
              <label class="block text-grey-darker text-sm font-bold mb-2" for="email">Email</label>
              <input
                v-model="user.email"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="email"
                type="text"
                placeholder
              />
            </div>

            <div class="mb-2">
              <label class="block text-grey-darker text-sm font-bold mb-2" for="phone">Mobilnummer</label>
              <input
                v-model="user.phone"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="phone"
                type="text"
                placeholder
              />
            </div>

            <div class="mb-2">
              <label class="block text-grey-darker text-sm font-bold mb-2" for="title">Titel</label>
              <input
                v-model="user.title"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="title"
                type="text"
                placeholder
              />
            </div>

            <div class="mb-2">
              <label class="block text-grey-darker text-sm font-bold mb-2" for="hsaid">HSA-ID</label>
              <input
                v-model="user.hsaid"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="hsaid"
                type="text"
                placeholder
              />
            </div>

            <div v-if="hasAdminRole" class="mb-2">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="organisation"
              >Organisation</label>
              <select
                v-model="user.organisation_id"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="organisation"
              >
                <option
                  v-for="org in availableOrganisations"
                  :key="org.id"
                  :value="org.id"
                >{{ org.name }}</option>
              </select>
            </div>

            <div class="mb-2">
              <label class="block text-grey-darker text-sm font-bold mb-2" for="role">Roll</label>
              <select
                v-model="user.role"
                multiple="true"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="role"
              >
                <option
                  v-for="role in availableRoles"
                  :key="role.id"
                  :title="role.description"
                  :value="role.id"
                  :selected="
                    user.role && user.role.indexOf(role.id) > -1 ? true : false
                  "
                >{{ role.name }}</option>
              </select>
            </div>

            <div class="flex justify-end">
              <button
                v-if="user.id && !user.deleted"
                class="bg-red-darker text-white hover:bg-red font-bold py-2 px-4 rounded mr-6"
                type="button"
                @click="deleteUser"
              >Radera</button>
              <button
                v-if="user.id && user.deleted"
                class="bg-red-darker text-white hover:bg-red font-bold py-2 px-4 rounded mr-6"
                type="button"
                @click="reactivateUser"
              >Återaktivera</button>
              <button
                class="bg-grey text-grey-darkest hover:bg-grey-dark font-bold py-2 px-4 rounded mr-6"
                type="button"
                @click="$modal.hide('EditUserModal')"
              >Avbryt</button>
              <button
                class="bg-blue hover:bg-blue-darker text-white font-bold py-2 px-4 rounded"
                type="button"
                @click="saveUser"
              >Spara</button>
            </div>

            <div v-if="errors.length > 0" class="mt-2 border-t-2 border-red">
              <div v-for="(error, key) in errors" :key="key" class="mt-1">{{ error.label }}</div>
            </div>
          </form>
        </div>
      </modal>
    </portal>
  </div>
</template>

<script>
import Header from "../Header.vue";

export default {
  name: "AdminUsers",
  props: [],
  components: {
    Header,
  },
  data() {
    return {
      loaded: false,
      users: null,
      errors: [],
      user: this.getEmptyUser(),
      includeDeleted: false
    };
  },
  mounted() {
    this.loadUsers();
    this.loadRoles();
    this.loadOrganisatios();
  },
  computed: {
    availableRoles() {
      return this.$store.getters.availableRoles;
    },
    availableUsers() {
      return this.users;
    },
    availableOrganisations() {
      return this.$store.getters.availableOrganisations;
    },
    hasDevRole() {
      return this.$store.getters.isDev;
    },
    hasAdminRole() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    addUser() {
      this.errors = [];
      this.user = this.getEmptyUser();
      this.$modal.show("EditUserModal");
    },
    getEmptyUser() {
      return {
        first_name: null,
        last_name: null,
        email: null,
        title: null,
        phone: null,
        hsaid: null,
        organisation_id: null,
        deleted: null,
        role: [],
      };
    },
    loadRoles() {
      this.$store.dispatch("loadRoles");
    },
    loadUsers() {
      this.$store
        .dispatch("loadUsers", { organisationId: this.$store.getters.userData.organisationId, includeDeleted: this.includeDeleted })
        .then((response) => {
          this.loaded = true;
          let data = response.data.map((user) => {
            user.role = user.roles ? user.roles.map((r) => r.id) : [];
            return user;
          });

          this.users = data;
        });
    },
    loadOrganisatios() {
      this.$store.dispatch("loadOrganisations");
    },
    editUser(item) {
      let {
        id,
        first_name,
        last_name,
        email,
        phone,
        title,
        hsaid,
        role,
        organisation_id,
        deleted
      } = item;

      this.user = {
        id,
        first_name,
        last_name,
        email,
        phone,
        title,
        hsaid,
        role,
        organisation_id,
        deleted
      };
      this.$modal.show("EditUserModal");
      this.errors = [];
    },
    saveUser() {
      if (!this.validate()) {
        return false;
      }

      let data = {
        id: this.user.id,
        username: "siths-" + this.user.hsaid,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        title: this.user.title,
        phone: this.user.phone,
        hsaid: this.user.hsaid,
        role_ids: this.user.role,
        organisation_id: this.user.organisation_id
          ? this.user.organisation_id
          : this.$store.getters.userData.organisationId,
      };

      if (!this.user.id) {
        this.$store
          .dispatch("addUser", data)
          .then(() => {
            this.loadUsers();
            this.$modal.hide("EditUserModal");
          })
          .catch((error) => {
            this.errors.push({ label: error.response.data.message });
          });
      } else {
        this.$store.dispatch("updateUser", data).then(() => {
          this.loadUsers();
          this.user = this.getEmptyUser();
          this.$modal.hide("EditUserModal");
        });
      }
    },
    deleteUser() {
      if (!this.user.id) {
        this.$modal.hide("EditUserModal");
        return false;
      }

      this.$store.dispatch("deleteUser", this.user.id).then(() => {
        let idx = this.users.findIndex((u) => {
          return u.id === this.user.id;
        });
        this.users.splice(idx, 1);
        this.user = this.getEmptyUser();
        this.$modal.hide("EditUserModal");
      });
    },
    reactivateUser() {
      if (!this.user.id) {
        this.$modal.hide("EditUserModal");
        return false;
      }

      this.$store.dispatch("reactivateUser", this.user.id).then(() => {
        let idx = this.users.findIndex((u) => {
          return u.id === this.user.id;
        });
        this.users.splice(idx, 1);
        this.user = this.getEmptyUser();
        this.$modal.hide("EditUserModal");
      });
    },
    validate() {
      this.errors.splice(0, this.errors.length);

      if (!this.user.first_name) {
        this.errors.push({
          key: "first_name",
          label: "Förnamn måste fyllas i",
        });
      }
      if (!this.user.last_name) {
        this.errors.push({
          key: "last_name",
          label: "Efternamn måste fyllas i",
        });
      }
      if (!this.user.hsaid) {
        this.errors.push({ key: "hsaid", label: "HSAID måste fyllas i" });
      }
      if (this.user.role.length == 0) {
        this.errors.push({ key: "role", label: "Roll måste anges" });
      }

      return this.errors.length == 0 ? true : false;
    },
    impersonateUser() {
      console.log("not implemented.");
    },
  },
};
</script>
