<template>
  <div class="container mx-auto bg-white">
    <Header title="Exportera" />

    <div class="px-4 py-4">
      <div class="flex-col justify-between text-left">
        <div>
          <label for="ids">
            Ange patient-id nedan. Mellanslag, kommatecken eller ny rad för att
            ange flera patienter.
          </label>
        </div>
        <div>
          <textarea v-model="ids" name="ids" class="w-full border rounded-sm"></textarea>
        </div>
        <div v-if="done" class="py-2">
          <span>Dataexporten är klar och filen finns nu under "hämtade filer".</span>
          <div v-if="missingIds.length > 0" class="text-sm">
            <span>Följande patienter hade inga formulär och finns därför ej med i exportfilen:</span>
            <span class="text-red-darker">{{ missingIds.filter(d => d !== '').join(', ') }}</span>
          </div>
        </div>

        <div>
          <button
            @click="getOutput"
            :disabled="ids.length === 0 || generating"
            class="px-2 py-1 border border-green rounded-sm bg-green-lighter hover:bg-green-light"
          >
            Skapa export
            <font-awesome-icon icon="spinner" spin v-show="generating"></font-awesome-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";

export default {
  name: "AdminExportFormScores",
  components: {
    Header,
  },
  data() {
    return {
      generating: false,
      ids: [],
      done: false,
      missingIds: null
    };
  },
  methods: {
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    getOutput() {
      this.generating = true;
      const ids = this.ids.replace(/\s+/g, ";").replace(/,+/g, ";").split(";");
      const data = {
        ids: ids,
      };

      this.$store.dispatch("exportFormScores", data)
        .then((response) => {
          this.missingIds = response.data.missing.length > 0 ? [response.data.missing].flat() : [];

          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(
            new Blob([this.s2ab(atob(response.data.binary))])
          );

          link.setAttribute("download", "export.xlsx");
          document.body.appendChild(link);
          link.click();

          this.generating = false;
          this.done = true;
        })
        .catch((e) => {
          this.generating = false;
          this.done = false;

          if (e.response.data.status === 400) {
            alert(e.response.data.message)
          }
        });
    },
  },
};
</script>

<style>
</style>