<template>
    <div v-show="rereferrDialog" class="fixed pin z-10 overflow-auto bg-smoke-dark flex">
        <div class="relative p-8 bg-white rounded-lg shadow-lg w-full max-w-lg m-auto mt-16 flex-col flex">
            asd
        </div>
    </div>

</template>

<script>
export default {
    name: 'BaseModal',
    props: [],
    data () {
        return {

        }
    },
    mounted () {

    },
    methods: {

    }
}
</script>