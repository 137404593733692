import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faBed,
    faTv,
    faPills,
    faTshirt,
    faShower,
    faKiss,
    faLaptop,
    faBookOpen,
    faUtensils,
    faHome,
    faPhone,
    faBuilding,
    faShoppingCart,
    faUserMd,
    faDumbbell,
    faCheck,
    faTrashAlt,
    faSearch,
    faPlus,
    faRedo,
    faCoffee,
    faRetweet,
    faSpinner,
    faGlasses,
    faPen,
    faCopy,
    faChevronDown,
    faChevronUp,
    faEye,
    faTimes,
    faChevronLeft,
    faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faBed,
    faTv,
    faPills,
    faTshirt,
    faShower,
    faKiss,
    faLaptop,
    faBookOpen,
    faUtensils,
    faHome,
    faPhone,
    faBuilding,
    faShoppingCart,
    faUserMd,
    faDumbbell,
    faCheck,
    faTrashAlt,
    faSearch,
    faPlus,
    faRedo,
    faCoffee,
    faRetweet,
    faSpinner,
    faGlasses,
    faPen,
    faCopy,
    faChevronDown,
    faChevronUp,
    faEye,
    faTimes,
    faChevronLeft,
    faQuestionCircle
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
