<template>
    <div>
        <div>
            <div class="mb-2">
                <span class="text-grey-dark uppercase">{{ stats.weekly.title }}</span>
                <div v-for="(stat, key) in stats.weekly.data" :key="key">
                    <span class="text-2xl text-bold p-1">{{ stat.value }}</span>
                    <span class="text-lg p-1 mt-1">{{ stat.title }}</span>
                </div>
            </div>
            <div class="flex w-full">

                <div id="monthlyContainer" ref="monthlyContainer" class="w-1/2">
                    <span class="text-grey-dark uppercase">{{ stats.monthly.title }}</span>
                    <div class="flex flex-col lg:flex-row items-center">
                            <div class="w-1/2">
                                <ResizableContainer>
                                    <MonthlyChart :data="stats.monthly.data" slot-scope="{ width }" :size="width" />
                                </ResizableContainer>
                            </div>
                            <div class="w-1/2">
                                <div v-for="(text, key) in stats.monthly.data.text" :key="key" class="flex">
                                    <div class="w-1/5 text-2xl text-right text-bold p-1 mr-2">{{ text.value }}</div>
                                    <div class="w-4/5 text-lg text-left p-1 mt-1">{{ $t(`${text.label}`) }}</div>
                                </div>
                            </div>
                    </div>

                </div>

                <div id="resultsContainer" ref="resultsContainer" class="w-1/2">
                    <span class="text-grey-dark uppercase">{{ stats.results.title }}</span>
                    <div class="w-full">
                        <ResizableContainer>
                            <ResultChart :data="stats.results.data" slot-scope="{ width, height }" :height="height" :width="width" />
                        </ResizableContainer>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MonthlyChart from './charts/MonthlyChart.vue'
import ResultChart from './charts/ResultChart.vue'
import ResizableContainer from './ResizableContainer.vue'

export default {
    name: 'NationalOverview',
    props: [],
    components: {
        MonthlyChart,
        ResultChart,
        ResizableContainer,
    },
    data () {
        return {
            stats: {
                weekly: {
                    title: this.$t('stats.overall.weekly'),
                    data: {
                        new: { title: this.$t('stats.weekly.referrals'), value: 18 },
                        updated: { title: this.$t('stats.weekly.updates'), value: 6 },
                    }
                },
                monthly: {
                    title: this.$t('stats.overall.monthly'),
                    data: {
                        numbers: [{
                            label: 'accepted',
                            value: 700,
                        }, {
                            label: 'backreferred',
                            value: 129,
                        }],
                        text: [
                            { label: 'stats.monthly.days', value: 18 },
                            { label: 'stats.monthly.recurring', value: '12%' },
                            { label: 'stats.monthly.volunteers', value: '58%' },
                        ]
                    }
                },
                results: {
                    title: this.$t('stats.overall.results'),
                    data: [
                        {
                            label: 'whodas',
                            data: [
                                { month: '0', value: 21 },
                                { month: '6', value: 15 },
                                { month: '12', value: 10 },
                                { month: '18', value: 7 },
                                { month: '24', value: 4 },
                            ]
                        },
                        {
                            label: 'madrs',
                            data: [
                                { month: '0', value: 10 },
                                { month: '6', value: 9 },
                                { month: '12', value: 7 },
                                { month: '18', value: 6 },
                                { month: '24', value: 5 },
                            ]
                        },
                        {
                            label: 'audit',
                            data: [
                                { month: '0', value: 15 },
                                { month: '6', value: 13 },
                                { month: '12', value: 11 },
                                { month: '18', value: 9 },
                                { month: '24', value: 7 },
                            ]
                        },
                        {
                            label: 'dudit',
                            data: [
                                { month: '0', value: 8 },
                                { month: '6', value: 6 },
                                { month: '12', value: 5 },
                                { month: '18', value: 3 },
                                { month: '24', value: 2 },
                            ]
                        },
                    ]
                }
            }
        }
    },
    computed: {
    },
    methods: {
    }
}
</script>