<template>
  <div id="pop-cont" is="transition-group" name="popup-fade">
    <div
      :class="'pop-item ' + popup.type"
      v-for="(popup, index) in popups"
      :key="index"
    >
      {{ popup.message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BasePopUp",
  computed: {
    popups() {
      return this.$store.getters.popup;
    },
  },
};
</script>

<style scoped>
#pop-cont {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.pop-item {
  padding: 20px;
  margin: 5px;
  width: 300px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
  text-align: center;
}
.error {
  background: rgb(255, 84, 84);
}
.saved {
  background: #65ad76;
}
.info {
  background: #f6f762;
  color: black;
}
.popup-fade-enter-active {
  transition: all 1s ease;
}
.popup-fade-enter{
  opacity: 0;
}
</style>
