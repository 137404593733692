import {
  apiPostRequest,
  apiGetRequest,
  apiPutRequest,
  apiDeleteRequest,
  apiPatchRequest,
} from './api';

const formRequest = (code) => {
  return apiGetRequest(`forms/${code}`);
};

const organisationsRequest = () => {
  return apiGetRequest('organisations');
};

const addOrganisationsRequest = (data) => {
  return apiPostRequest('organisations', data);
};

const usersRequest = (organisationId, includeDeleted) => {
  return apiGetRequest(
    `users?organisation_id=${organisationId}&include_deleted=${includeDeleted}`
  );
};

const deleteUsersRequest = (id) => {
  return apiDeleteRequest('users', id);
};

const reactivateUsersRequest = (id) => {
  return apiPatchRequest(`users/${id}`, { deleted: null });
};

const updateUsersRequest = (data) => {
  return apiPutRequest('users', data);
};

const patientsRequest = (params = "page=1") => {
  return apiGetRequest("patients/organisation?" + params);
};

const getRolesRequest = () => {
  return apiGetRequest('roles');
};

const exportFormScoresRequest = (data) => {
  return apiPostRequest('export/patient-formscores', data);
};

export default {
  state: {
    formLoaded: false,
    availableRoles: null,
    availableOrganisations: null,
    patientList: [],
    listsort: {
      adminPatients: {
        col: "",
        asc: true,
        page: 1,
        total: 0,
        pages: 1,
        searchTerms: ""
      },
    },
  },
  mutations: {
    setFormLoaded(state) {
      state.formLoaded = true;
    },
    setFormUnloaded(state) {
      state.formLoaded = false;
    },
    setAvailableRoles(state, data) {
      state.availableRoles = data;
    },
    setAvailableOrganisations(state, data) {
      state.availableOrganisations = data;
    },
    setPatientList(state, data) {
      state.patientList = data;
    },
    adminSetSearchTerms(state, data) {
      state.listsort.adminPatients.searchTerms = data;
    },
    adminChangePage(state, data) {
      state.listsort.adminPatients.page = data;
    },
    adminSortPatients(state, key) {
      const oldCol = state.listsort.adminPatients.col;
      state.listsort.adminPatients.col = key;
      state.listsort.adminPatients.asc = oldCol != key ? true : !state.listsort.adminPatients.asc;
    }
  },
  actions: {
    setFormLoaded({ commit }) {
      //eslint-disable-line
      commit('setFormLoaded');
    },
    setFormUnloaded({ commit }) {
      //eslint-disable-line
      commit('setFormUnloaded');
    },
    async loadForm({ commit }, code) {
      //eslint-disable-line
      const form = await formRequest(code);

      return form;
    },
    async loadOrganisations({ commit }) {
      //eslint-disable-line
      const response = await organisationsRequest().then((data) => {
        commit('setAvailableOrganisations', data);
        return true;
      });
      return response;
    },
    async addOrganisation({ commit }, data) {
      //eslint-disable-line
      const response = await addOrganisationsRequest(data);

      return response;
    },
    async loadUsers({ commit }, { organisationId, includeDeleted }) {
      //eslint-disable-line
      const response = await usersRequest(organisationId, includeDeleted);

      return response;
    },
    async addUser({ commit }, data) {
      //eslint-disable-line
      const response = await apiPostRequest('users', data);
      return response;
    },
    updateUser({ commit }, data) {
      //eslint-disable-line
      const response = updateUsersRequest(data);

      return response;
    },
    deleteUser({ commit }, id) {
      //eslint-disable-line
      const response = deleteUsersRequest(id);

      return response;
    },
    reactivateUser({ commit }, id) {
      //eslint-disable-line
      const response = reactivateUsersRequest(id);

      return response;
    },
    async loadPatients({ commit, state }) {
      let string = new URLSearchParams({
        page: state.listsort.adminPatients.page,
        sort: state.listsort.adminPatients.col,
        sortAsc: state.listsort.adminPatients.asc,
        searchTerms: state.listsort.adminPatients.searchTerms
      });

      //eslint-disable-line
      return await patientsRequest(string.toString()).then((data) => {
        commit('setPatientList', data);
      }).catch(() => {
        commit('setPatientList', null);
      });
    },
    async addPatient({ commit }, data) {
      //eslint-disable-line
      const response = await apiPostRequest('patients', data);

      return response;
    },
    loadRoles({ commit }) {
      //eslint-disable-line
      getRolesRequest().then((data) => {
        commit('setAvailableRoles', data);
      });
    },
    async loadPackages({ commit }) {
      //eslint-disable-line
      const response = await apiGetRequest('packages');
      return response;
    },
    async updatePackage({ commit }, data) {
      //eslint-disable-line
      const response = await apiPutRequest('packages', data);
      return response;
    },
    async addPackage({ commit }, data) {
      //eslint-disable-line
      const response = await apiPostRequest('packages', data);
      return response;
    },
    async loadAllForms({ commit }) {
      //eslint-disable-line
      const response = await apiGetRequest('allForms');
      return response;
    },
    async removePackage({ commit }, id) {
      //eslint-disable-line
      const response = await apiDeleteRequest('packages', id);
      return response;
    },
    async searchBySsnAdmin({ commit }, ssn) {
      //eslint-disable-line
      const response = await apiPostRequest('patient/organisation', {
        ssn: ssn,
      });
      return response;
    },
    async saveFormAdmin({ commit }, form) {
      //eslint-disable-line
      const response = await apiPostRequest('saveFormAdmin', form);
      return response;
    },
    async removeFormAdmin({ commit }, id) {
      //eslint-disable-line
      const response = await apiDeleteRequest('removeFormAdmin', id);
      return response;
    },
    async exportFormScores({ commit }, data) {
      //eslint-disable-line
      const response = await exportFormScoresRequest(data);
      return response;
    },
    adminSetSearchTerms({ commit }, searchTerms) {
      commit('adminSetSearchTerms', searchTerms);
    },
    adminChangePage({ commit }, page) {
      commit('adminChangePage', page);
    },
    adminSortPatients({ commit }, key) {
      commit('adminSortPatients', key);
    },
    async searchBySsnMove({ commit }, ssn) {
      const response = await apiPostRequest('patient/move/search', {
        ssn: ssn
      });

      return response;
    },
    async requestMove({ commit }, patientId) {
      const response = await apiPostRequest('patient/move/request', {
        patientId: patientId
      });

      return response;
    },
    async requestIncomingList({ commit }, pageNum) {
      let string = new URLSearchParams({
        page: pageNum,
      });

      const response = await apiGetRequest('patient/move/incoming?' + string.toString());

      return response;
    },
    async requestOutgoingList({ commit }, pageNum) {
      let string = new URLSearchParams({
        page: pageNum,
      });

      const response = await apiGetRequest('patient/move/outgoing?' + string.toString());

      return response;
    },
    async answerMoveRequest({ commit }, request) {
      const response = await apiPostRequest('patient/move/answer', request);

      return response;
    }
  },
  getters: {
    isFormLoaded: (state) => state.formLoaded,
    availableRoles: (state) => state.availableRoles,
    availableOrganisations: (state) => state.availableOrganisations,
    adminPatientList: (state) => {
      if (!state.patientList) return null;

      return state.patientList.data;
    },
    adminPatientListTotal: (state) => {
      return state.patientList ? state.patientList.total : 0;
    },
    adminPatientListCount: (state) => {
      return state.patientList ? state.patientList.count : 0;
    },
    adminPatientPages: (state) => {
      return state.patientList ? state.patientList.pages : 0;
    },
    adminPatientPage: (state) => {
      return state.patientList ? state.patientList.page : 1;
    },
    adminPage: (state) => state.listsort.adminPatients.page,
    adminTotal: (state) => state.listsort.adminPatients.total
  },
};
