<script>
export default {
  name: "LoginVerified",
  mounted() {
    this.$store
      .dispatch("verifySithsLogin", this.$route.query.grandidsession)
      .then(() => {
        this.$router.push({ name: "list_patients" });
      })
      .catch(error => {
        const errorString = error.response.data.message || 'unauthorized';

        this.$router.push({
          name: "home",
          params: { errorMsg: this.$t(`login.error.${errorString}`) },
        });
      });
  },
  render(createElement) {
    return createElement("div");
  },
};
</script>
